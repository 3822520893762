import React, { useMemo, useState } from "react";
import {
  FormControl,
  Divider,
  Button,
  Box,
  Paper,
  TextField,
  DialogContentText,
  Link,
  Stack,
} from "@mui/material";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { pt } from "yup-locale-pt";
import {
  Select,
  DialogoConfirmacao,
  DialogoConfirmacaoSimples,
  FileField,
  FileListField,
  Timestamps,
} from "../../components";
import { useStoreState, useStoreActions } from "easy-peasy";
import api from "../../services/api";
import DadosFinanciamento from "./DadosFinanciamento";
import DadosFinanceiros from "./DadosFinanceiros";
import { DialogoVisualizador } from "../../components";
import { driveBase } from "../../config";
import MaterialTable from "@material-table/core";
import { materialTableLocalization } from "../../config";
import { regioes } from "../../static";
yup.setLocale(pt);

const Financiamento = () => {
  let currProcesso = useStoreState((state) => state.processo.current);
  let selectProcesso = useStoreActions((actions) => actions.processo.select);
  let startLoading = useStoreActions((actions) => actions.nav.startLoading);
  let stopLoading = useStoreActions((actions) => actions.nav.stopLoading);
  const [listaInstFinanceiras, setListaInstFinanceiras] = React.useState([]);
  const [financiamento, setFinanciamento] = React.useState(null);
  const [updateEstagio, setUpdateEstagio] = React.useState(currProcesso.estagio);

  const schema = yup
    .object({
      instFinanceiraId: yup
        .number()
        .positive()
        .nullable()
        .label("Instituição Financeira")
        .required(),
      financiamentoId: yup
        .number()
        .positive()
        .nullable()
        .label("Doc. de financiamento"),
      outrosDocsField: yup
        .array()
        .label("Arraste outros documentos aqui ou clique"),
    })
    .required();

  const defaultValues = {
    instFinanceiraId: null,
    financiamentoId: null,
    outrosDocsField: [],
  };

  const useFormRef = useForm({
    mode: "onTouched",
    resolver: yupResolver(schema),
    defaultValues: defaultValues,
  });

  const {
    handleSubmit,
    watch,
    reset,
    getValues,
    formState: { isDirty, isSubmitting },
  } = useFormRef;

  const instFinanceiraId = watch("instFinanceiraId");

  const [aprovando, setAprovando] = React.useState([]);
  const [confirmacaoMsg, setConfirmacaoMsg] = React.useState([]);

  const verificaPreenchimento = () => {
    let erros = [];
    if (!getValues("financiamentoId"))
      erros.push("Inclusão do doc. de finananciamento");
    if (erros.length > 0)
      return (
        <React.Fragment>
          <DialogContentText>
            Ainda não foram realizadas as seguintes ações:
          </DialogContentText>
          <ul>
            {erros.map((erro, index) => (
              <li key={index}>
                <DialogContentText>{erro}</DialogContentText>
              </li>
            ))}
          </ul>
        </React.Fragment>
      );
    return "";
  };

  const aprova = (data) => {
    setAprovando(true);
    setConfirmacaoMsg(
      <React.Fragment>
        {verificaPreenchimento()}
        <DialogContentText>
          Esta ação não pode ser desfeita. Tem certeza que deseja <b>aprovar</b>{" "}
          o financiamento?
        </DialogContentText>
      </React.Fragment>
    );
    setOpenConfirmacao(true);
  };

  const recusa = () => {
    setAprovando(false);
    setConfirmacaoMsg(
      <React.Fragment>
        Esta ação não pode ser desfeita. Tem certeza que deseja <b>reprovar </b>
        o financiamento?
      </React.Fragment>
    );
    setOpenConfirmacao(true);
  };

  const desfazerRecusa = () => {
    setOpenConfirmaDesfRecusa(true);
  };

  const salva = async (data) => {
    startLoading();
    try {
      let financiamentoData;
      if (data.id)
        financiamentoData = (
          await api.http.put("/financiamentos/" + data.id, data)
        ).data;
      else {
        let post = (
          await api.http.post("/financiamentos", {
            ...data,
            processoId: currProcesso.id,
          })
        ).data;
        financiamentoData = post.financiamento;
        selectProcesso(post.processo);
      }

      stopLoading({ severity: "success" });
      reset(financiamentoData);
      setFinanciamento(financiamentoData);
    } catch (error) {
      stopLoading({
        message: error.toString(),
        severity: "error",
      });
      throw error;
    }
  };

  const cancela = () => {
    reset();
  };

  const [openConfirmacao, setOpenConfirmacao] = React.useState(false);

  const onSimConfirma = async (data) => {
    setOpenConfirmacao(false);
    const confirmaData = {
      ...data,
      estagio: aprovando ? "finAprovado" : "finReprovado",
    };
    startLoading();
    try {
      let post = (
        await api.http.put("/financiamentos/" + financiamento.id, confirmaData)
      ).data;
      setFinanciamento(post.financiamento);
      reset(post.financiamento);
      selectProcesso(post.processo);
      stopLoading({ severity: "success" });
    } catch (error) {
      stopLoading({
        message: error.toString(),
        severity: "error",
      });
      throw error;
    }
  };
  const onNaoConfirma = () => {
    setOpenConfirmacao(false);
  };

  const [openConfirmaDesfRecusa, setOpenConfirmaDesfRecusa] =
    React.useState(false);

  const onConfirmaDesfRecusa = async (data) => {
    setOpenConfirmaDesfRecusa(false);
    const confirmaData = {
      estagio: "finIniciado",
    };
    startLoading();
    try {
      let put = (
        await api.http.put("/financiamentos/" + financiamento.id, confirmaData)
      ).data;
      setFinanciamento(put.financiamento);
      reset(put.financiamento);
      selectProcesso(put.processo);
      stopLoading({ severity: "success" });
    } catch (error) {
      stopLoading({
        message: error.toString(),
        severity: "error",
      });
      throw error;
    }
  };

  const onNaoConfirmaDesfRecusa = () => {
    setOpenConfirmaDesfRecusa(false);
  };

  let user = useStoreState((state) => state.auth.user);
  const disabledAlocacao = () => {
    return (
      (user.roleId != "superAdmin" &&
        !["finIniciado"].includes(currProcesso.estagio)) ||
      (user.roleId == "consultorEE" &&
        (!diagnostico || diagnostico.auditorId != user.id)) ||
      !["superAdmin", "consultorEE"].includes(user.roleId)
    );
  };

  const disabled = () => {
    return (
      (user.roleId != "superAdmin" &&
        !["finIniciado"].includes(currProcesso.estagio)) ||
      !["desenvolveSP", "superAdmin", "gestorGarantidor", "instFin"].includes(
        user.roleId
      )
    );
  };

  const [openDadosFinanciamento, setOpenDadosFinanciamento] =
    React.useState(false);

  const dadosFinanciamento = () => {
    setOpenDadosFinanciamento(true);
  };

  const onSaveDadosFinanciamento = async (data) => {
    startLoading();
    try {
      setFinanciamento(
        (await api.http.put("/financiamentos/" + financiamento.id, data)).data
      );
      stopLoading({ severity: "success" });
      setOpenDadosFinanciamento(false);
    } catch (error) {
      stopLoading({
        message: error.toString(),
        severity: "error",
      });
      throw error;
    }
  };

  const onCancelDadosFinanciamento = () => {
    setOpenDadosFinanciamento(false);
  };

  const [openDadosFinanceiros, setOpenDadosFinanceiros] = React.useState(false);

  const dadosFinanceiros = () => {
    setOpenDadosFinanceiros(true);
  };

  const onCloseDadosFinanceiros = () => {
    setOpenDadosFinanceiros(false);
  };

  React.useEffect(async () => {
    let mount = {
      isMounted: true,
    };
    if (user.roleId == "consultorEE") loadDiagnostico(mount);
    return () => {
      mount.isMounted = false;
    };
  }, []);

  const [diagnostico, setDiagnostico] = React.useState(null);
  const loadDiagnostico = async (mount) => {
    startLoading();
    try {
      let diagnosticoData = (
        await api.http.get("/diagnosticos/" + currProcesso.id)
      ).data;
      if (mount.isMounted) setDiagnostico(diagnosticoData);
      stopLoading();
    } catch (error) {
      stopLoading({
        message: error.toString(),
        severity: "error",
      });
    }
  };

  React.useEffect(async () => {
    startLoading();
    try {
      setListaInstFinanceiras(
        (
          await api.http.get("/inst-financeiras", {
            params: { habilitado: true },
          })
        ).data
      );

      await loadOrcamentos();

      let financiamentoData = (
        await api.http.get("/financiamentos/" + currProcesso.id)
      ).data;
      reset(financiamentoData);
      setFinanciamento(financiamentoData);

      stopLoading();
    } catch (error) {
      stopLoading({
        message: error.toString(),
        severity: "error",
      });
    }

    setColumns([
      {
        field: "fornecedor.nome",
        align: "left",
        title: "Nome",
      },
      {
        field: "fornecedor.responsavel",
        align: "left",
        title: "Responsável",
      },
      {
        field: "fornecedor.email",
        align: "left",
        title: "Email",
      },
      {
        field: "fornecedor.regiaoId",
        align: "left",
        title: "Região",
        lookup: regioes.reduce((map, regiao) => {
          map[regiao.id] = regiao.label;
          return map;
        }, {}),
      },
      {
        field: "docOrcamento",
        align: "left",
        title: "Orçamento enviado",
        render: (rowData) => (
          <Link
            onClick={() => {
              openDoc(rowData.docOrcamento);
            }}
          >
            {rowData.docOrcamento.fileName}
          </Link>
        ),
      },
    ]);
  }, []);

  const updateFinIniciado = async () => {
    startLoading();
  
    try {
      const processoId = currProcesso.id;
  
      const response = await api.http.put(`/financiamentos/${processoId}/update-estagio`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json'
        }
      });

      setUpdateEstagio(response.data.processo.estagio);
  
      stopLoading();
    } catch (error) {
      stopLoading({
        message: error.toString(),
        severity: "error",
      });
    }
  };

  const actionButtons = () => {
    if (
      isDirty ||
      !financiamento ||
      !["finIniciado", "finReprovado"].includes(currProcesso.estagio) ||
      user.roleId == "consultorEE"
    ) {
      return (
        <React.Fragment>
          <Button
            variant="outlined"
            size="large"
            sx={{ mr: 2 }}
            onClick={cancela}
            disabled={!isDirty || isSubmitting}
          >
            CANCELAR
          </Button>
          {updateEstagio === 'finIniciado' && (
            <Button
              variant="contained"
              size="large"
              onClick={handleSubmit(salva)}
              disabled={!isDirty || isSubmitting}
            >
              SALVAR
            </Button>
          )}

          {updateEstagio === 'diagAprovadoIF' && (
            <Button
              variant="contained"
              size="large"
              onClick={updateFinIniciado}
            >
              INICIAR FINANCIAMENTO
            </Button>
          )}
        </React.Fragment>
      );
    } else {
      if (
        user.roleId == "superAdmin" &&
        currProcesso.estagio == "finReprovado"
      ) {
        return (
          <React.Fragment>
            <Button
              variant="outlined"
              size="large"
              sx={{ mr: 2 }}
              onClick={desfazerRecusa}
            >
              DESFAZER PROCESSO RECUSADO
            </Button>
          </React.Fragment>
        );
      } else {
        return (
          <React.Fragment>
            <Button
              variant="outlined"
              size="large"
              sx={{ mr: 2 }}
              onClick={recusa}
            >
              RECUSAR FINANCIAMENTO
            </Button>
            <Button variant="contained" size="large" onClick={aprova}>
              APROVAR FINANCIAMENTO
            </Button>
          </React.Fragment>
        );
      }
    }
  };

  const [file, setFile] = React.useState(null);
  const [openDlgVis, setOpenDlgVis] = React.useState(false);

  const onCloseDlgVis = () => {
    setFile(null);
    setOpenDlgVis(false);
  };

  const [columns, setColumns] = React.useState([]);
  const [rows, setRows] = React.useState([]);

  const loadOrcamentos = async () => {
    startLoading();
    try {
      setRows(
        (
          await api.http.get("/orcamentos", {
            params: {
              processoId: currProcesso.id,
              selecionado: true,
            },
          })
        ).data
      );
      stopLoading();
    } catch (error) {
      stopLoading({
        message: error.toString(),
        severity: "error",
      });
    }
  };

  const openDoc = (arquivo) => {
    if (
      arquivo.mimeType == "application/pdf" ||
      arquivo.mimeType == "text/html" ||
      arquivo.indexOf("image") == 0
    ) {
      setFile(arquivo);
      setOpenDlgVis(true);
    } else {
      window.location.href = driveBase + arquivo.fileRef;
    }
  };

  const exibeCorpo = useMemo(() => {
    return updateEstagio === 'finIniciado' && user.roleId != "consultorEE";
  }, [updateEstagio]);

  return (
    <Paper
      variant="outlined"
      className="center-box"
      sx={{
        textAlign: "center",
        px: 4,
        pt: 2,
        pb: 3,
        maxWidth: "md",
      }}
    >
      {financiamento?.id && (
        <React.Fragment>
          <DadosFinanciamento
            dados={financiamento}
            open={openDadosFinanciamento}
            onSave={onSaveDadosFinanciamento}
            onCancel={onCancelDadosFinanciamento}
            disabled={disabled()}
          />
          <DadosFinanceiros
            processo={currProcesso}
            open={openDadosFinanceiros}
            onClose={onCloseDadosFinanceiros}
            disabled={disabled()}
          />
        </React.Fragment>
      )}

      <DialogoConfirmacao
        title="Atenção"
        open={openConfirmacao}
        onSim={onSimConfirma}
        onNao={onNaoConfirma}
      >
        {confirmacaoMsg}
      </DialogoConfirmacao>
      <DialogoConfirmacaoSimples
        title="Atenção"
        open={openConfirmaDesfRecusa}
        onSim={onConfirmaDesfRecusa}
        onNao={onNaoConfirmaDesfRecusa}
      >
        Tem certeza que deseja desfazer recusa do financiamento?
      </DialogoConfirmacaoSimples>
      <form onSubmit={handleSubmit(aprova)}>
        <Divider>
          <h3>Alocação de insituição financeiras</h3>
        </Divider>
        <FormControl sx={{ my: 2 }} fullWidth>
          <Select
            field="instFinanceiraId"
            useFormRef={useFormRef}
            schema={schema}
            options={listaInstFinanceiras}
            getOptionLabel={(option) => option.nome}
            disabled={disabledAlocacao()}
          />
        </FormControl>
        {exibeCorpo && (
          <React.Fragment>
            <Divider>
              <h3>Dados de Financiamento</h3>
            </Divider>
            <Stack direction="row" spacing={2} justifyContent="center">
              <Button
                variant="outlined"
                onClick={dadosFinanciamento}
                disabled={!financiamento?.id}
              >
                DADOS INICIAIS
              </Button>
              <Button
                variant="outlined"
                onClick={dadosFinanceiros}
                disabled={!financiamento?.id}
              >
                DADOS TRIMESTRAIS
              </Button>
            </Stack>
            <Divider>
              <h3>Upload de documentos</h3>
            </Divider>
            <FormControl sx={{ my: 2 }} fullWidth>
              <FileField
                field="financiamentoId"
                disabled={disabled()}
                fileData={financiamento?.financiamento}
                useFormRef={useFormRef}
                schema={schema}
              />
            </FormControl>
            <FileListField
              field="outrosDocs"
              disabled={disabled()}
              useFormRef={useFormRef}
              schema={schema}
              initialFiles={financiamento?.outrosDocs}
            />
            <Divider>
              <h3>Orçamento(s) aprovado(s)</h3>
            </Divider>
            <DialogoVisualizador
              file={file}
              open={openDlgVis}
              onClose={onCloseDlgVis}
            />
            <MaterialTable
              columns={columns}
              data={rows}
              title="Orçamentos"
              localization={materialTableLocalization}
            />

            {financiamento?.id &&
              currProcesso &&
              currProcesso.estagio != "finIniciado" && (
                <React.Fragment>
                  <Divider>
                    <h3>
                      Observações de{" "}
                      {currProcesso.estagio == "finReprovado"
                        ? "reprovação"
                        : "aprovação"}
                    </h3>
                  </Divider>
                  <FormControl sx={{ my: 2 }} fullWidth>
                    <TextField
                      value={financiamento?.observacoes}
                      multiline
                      rows={4}
                      disabled={true}
                    />
                  </FormControl>
                </React.Fragment>
              )}

            <Timestamps dados={financiamento} />
          </React.Fragment>
        )}

        {(!disabled() || !disabledAlocacao()) && (
          <Box sx={{ mt: 3, textAlign: "center" }}>{actionButtons()}</Box>
        )}
      </form>
    </Paper>
  );
};

export default Financiamento;
