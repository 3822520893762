import MaterialTable from "@material-table/core";
import {
  Button,
  Checkbox,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  Link,
} from "@mui/material";
import { useStoreActions, useStoreState } from "easy-peasy";
import * as React from "react";
import { DialogoVisualizador } from "../../components";
import { driveBase, materialTableLocalization } from "../../config";
import api from "../../services/api";
import { regioes } from "../../static";

const DialogoAcompanhaOrcamentos = ({ open, onClose, disabled }) => {
  let loading = useStoreState((state) => state.nav.loading);
  let startLoading = useStoreActions((actions) => actions.nav.startLoading);
  let stopLoading = useStoreActions((actions) => actions.nav.stopLoading);
  let currProcesso = useStoreState((state) => state.processo.current);
  const [columns, setColumns] = React.useState([]);
  const [rows, setRows] = React.useState([]);

  function calcularPrazo(dataEnvio) {
    const prazoEmDias = 15;
    const hoje = new Date();
    const diffEmDias = Math.floor((hoje - dataEnvio) / (1000 * 60 * 60 * 24));

    if (diffEmDias > prazoEmDias) {
      return 0; // Prazo expirado
    } else {
      return prazoEmDias - diffEmDias;
    }
  }

  const loadOrcamentos = async () => {
    startLoading();
    try {
      const response = await api.http.get(
        "/orcamentos?processoId=" + currProcesso.id
      );

      const data = response.data.map((orcamento) => {
        const dataEnvio = new Date(orcamento.createdAt);

        return {
          ...orcamento,
          createdAt: new Date(orcamento.createdAt).toLocaleDateString(),
          prazo: calcularPrazo(dataEnvio),
        };
      });

      setRows(data);

      stopLoading();
    } catch (error) {
      stopLoading({
        message: error.toString(),
        severity: "error",
      });
    }
  };

  const openDoc = (arquivo) => {
    if (
      arquivo.mimeType == "application/pdf" ||
      arquivo.mimeType == "text/html" ||
      arquivo.mimeType.indexOf("image") == 0
    ) {
      setFile(arquivo);
      setOpenDlgVis(true);
    } else {
      window.location.href = driveBase + arquivo.fileRef;
    }
  };

  const seleciona = async (orcamento, selecionado) => {
    startLoading();
    try {
      setRows(
        (
          await api.http.put("/orcamentos/" + orcamento.id, {
            selecionado: selecionado,
          })
        ).data
      );
      stopLoading();
    } catch (error) {
      stopLoading({
        message: error.toString(),
        severity: "error",
      });
    }
  };

  React.useEffect(() => {
    setColumns([
      {
        field: "fornecedor.nome",
        align: "left",
        title: "Nome",
      },
      {
        field: "fornecedor.responsavel",
        align: "left",
        title: "Responsável",
      },
      {
        field: "fornecedor.email",
        align: "left",
        title: "Email",
      },
      {
        field: "docOrcamento",
        align: "left",
        title: "Orçamento enviado",
        render: (rowData) => {
          if (rowData.docOrcamento)
            return (
              <Link
                onClick={() => {
                  openDoc(rowData.docOrcamento);
                }}
              >
                {rowData.docOrcamento.fileName}
              </Link>
            );
          else return "Aguardando orçamento";
        },
      },
      {
        field: "createdAt",
        align: "left",
        title: "Data de Envio",
      },
      {
        field: "prazo",
        align: "center",
        title: "Prazo",
      },
      {
        field: "selecionado",
        align: "center",
        title: "Selecionado",
        render: (rowData) => (
          <Checkbox
            checked={rowData.selecionado == 1}
            onChange={(e) => {
              seleciona(rowData, e.target.checked);
            }}
            disabled={disabled || loading > 0 || !rowData.docOrcamento}
          />
        ),
      },
    ]);
  }, [disabled, loading]);

  React.useEffect(async () => {
    if (open) await loadOrcamentos();
  }, [open]);

  const [file, setFile] = React.useState(null);
  const [openDlgVis, setOpenDlgVis] = React.useState(false);

  const onCloseDlgVis = () => {
    setFile(null);
    setOpenDlgVis(false);
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="xl" fullWidth={true}>
      <DialogContent>
        <DialogoVisualizador
          file={file}
          open={openDlgVis}
          onClose={onCloseDlgVis}
        />
        <MaterialTable
          columns={columns}
          data={rows}
          title="Orçamentos"
          localization={materialTableLocalization}
        />
      </DialogContent>
      <DialogActions>
        {loading > 0 && (
          <CircularProgress
            size={30}
            sx={{
              position: "absolute",
              left: 25,
              bottom: 12,
            }}
          />
        )}
        <Button
          color="primary"
          onClick={() => {
            onClose();
          }}
        >
          OK
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DialogoAcompanhaOrcamentos;
