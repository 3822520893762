import {
  TextField,
  MenuItem,
  ListSubheader
}  from '@mui/material';
import { Controller } from "react-hook-form";

const SelectCidade = ({
  field,
  useFormRef,
  schema,
  options,
  groupBy,
  getOptionLabel = (option)=>option.label?option.label:option,
  ...props
}) => {
  const {control} = useFormRef;

  const renderItems = (options,groupBy) => {
    let result = [];
    let categorias = [];
    for(let i=0; i<options.length; i++) {
      if(groupBy) {
        let cat = groupBy(options[i]);
        if(!(categorias.includes(cat))) {
          result.push(<ListSubheader key={result.length}>{cat}</ListSubheader>);
          categorias.push(cat);
        }
      }
      result.push(
        <MenuItem key={result.length} value={options[i].nome}>
          {getOptionLabel(options[i])}
        </MenuItem>
      );
    }
    return result;
  };

  return (
    <Controller
      name={field}
      control={control}
      render={
        ({
          field: {onChange, onBlur, value, name, ref},
          fieldState: { invalid, isTouched, isDirty, error }
        }) =>
          <TextField
            id={field}
            label={schema?.fields[field].spec.label}
            required={schema?.fields[field].exclusiveTests['required']}
            error={error!=null}
            helperText={error?.message}
            value={value?value:''}
            onBlur={onBlur}
            onChange={onChange}
            inputRef={ref}
            select
            {...props}
          >
            {renderItems(options,groupBy)}
          </TextField>
      }
    />
  );
};

export default SelectCidade;
