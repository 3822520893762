import * as React from 'react';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Fab,
  IconButton,
  CircularProgress
} from '@mui/material';
import PropTypes from 'prop-types';
import CloseIcon from '@mui/icons-material/Close';
import AddIcon from '@mui/icons-material/Add';
import api from '../../services/api';
import { useStoreState, useStoreActions } from 'easy-peasy';
import MaterialTable from '@material-table/core';
import { materialTableLocalization } from '../../config';
import DialogoTecnologiaProcesso from './DialogoTecnologiaProcesso';
import { opcoesBooleanas } from '../../static';

const BootstrapDialogTitle = (props) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};
BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

const DadosTecnologiaProcesso = ({ processo, open, onClose, disabled, final = false }) => {
  let startLoading = useStoreActions((actions) => actions.nav.startLoading);
  let stopLoading = useStoreActions((actions) => actions.nav.stopLoading);
  let loading = useStoreState((state) => state.nav.loading);
  const [openDlgTecnologiaProcesso, setOpenDlgTecnologiaProcesso] = React.useState(false);
  const [rows, setRows] = React.useState([]);
  const [tecnologiaProcesso, setTecnologiaProcesso] = React.useState(null);
  const [tecnologias, setTecnologias] = React.useState([]);
  const [columns, setColumns] = React.useState([]);

  const loadTecnologiaProcesso = async (mount) => {
    startLoading();
    try {
      let data = (await api.http.get(`/tecnologias-processos/${processo.id}`)).data;
      if (mount.isMounted) {
        setRows(data);
      };
      stopLoading();
    } catch (error) {
      stopLoading({
        message: error.toString(),
        severity: 'error'
      });
    };
  };

  const loadTecnologias = async (mount) => {
    try {
      let data = (await api.http.get('/tecnologias')).data;
      if (mount.isMounted) {
        setTecnologias(data);
        setColumns([
          {
            field: 'tecnologiaId', title: 'Categoria de Tecnologia',
            lookup: data.reduce((map, tecnologia) => {
              map[tecnologia.id] = tecnologia.label;
              return map;
            }, {})
          }, {
            field: 'isolamentoTermico', title: 'Isolamento Térmico',
            lookup: opcoesBooleanas.reduce((map, opcaoBooleana) => {
              map[opcaoBooleana.id] = opcaoBooleana.label;
              return map;
            }, {})
          }, {
            field: 'aproveitamentoDeEnergiaRenovavel', title: 'Aproveitamento de energia renovável',
            lookup: opcoesBooleanas.reduce((map, opcaoBooleana) => {
              map[opcaoBooleana.id] = opcaoBooleana.label;
              return map;
            }, {})
          }, {
            field: 'medidaDeGestaoEnergetica', title: 'Medida de gestão energética',
            lookup: opcoesBooleanas.reduce((map, opcaoBooleana) => {
              map[opcaoBooleana.id] = opcaoBooleana.label;
              return map;
            }, {})
          }, {
            field: 'medidaDeControleOuSupervisorio', title: 'Medida de controle ou supervisório',
            lookup: opcoesBooleanas.reduce((map, opcaoBooleana) => {
              map[opcaoBooleana.id] = opcaoBooleana.label;
              return map;
            }, {})
          }
        ]);

      }
      stopLoading();
    } catch (error) {
      stopLoading({
        message: error.toString(),
        severity: 'error'
      });
    }
  };

  React.useEffect(() => {
    let mount = {
      isMounted: true
    };
    loadTecnologias(mount);
    return () => { mount.isMounted = false; };
  }, []);

  const [reloadTecnologiaProcesso, setReloadTecnologiaProcesso] = React.useState(0);
  React.useEffect(() => {
    let mount = {
      isMounted: true
    };
    loadTecnologiaProcesso(mount);
    return () => { mount.isMounted = false; };
  }, [reloadTecnologiaProcesso]);

  const onCloseDlgTecnologiaProcesso = (reload = false) => {
    setTecnologiaProcesso(null);
    setOpenDlgTecnologiaProcesso(false);
    if (reload) {
      setReloadTecnologiaProcesso(reloadTecnologiaProcesso+1);
    }
  };
  const onRowClick = (event, rowData) => {
    setTecnologiaProcesso(rowData);
    setOpenDlgTecnologiaProcesso(true);
  };
  return (
    <Dialog
      open={open}
      fullWidth={true}
      maxWidth={'xl'}>
      <BootstrapDialogTitle id="customized-dialog-title" onClose={onClose}>
      </BootstrapDialogTitle>
      <DialogContent>
        <DialogoTecnologiaProcesso
          tecnologiaProcesso={tecnologiaProcesso}
          tecnologias={tecnologias}
          processo={processo}
          open={openDlgTecnologiaProcesso}
          onClose={onCloseDlgTecnologiaProcesso} />
        <MaterialTable
          columns={columns}
          data={rows}
          title="Categorias de Tecnologias"
          onRowClick={onRowClick}
          localization={materialTableLocalization}
        />
      </DialogContent>
      <DialogActions>
        {loading > 0 && <CircularProgress
          size={30}
          sx={{
            'position': 'absolute',
            'left': 25,
            'bottom': 12
          }} />}
        {!disabled &&
          <Fab color="primary" aria-label="novo fornecedor"
            onClick={() => {
              setOpenDlgTecnologiaProcesso(true);
            }}>
            <AddIcon />
          </Fab>
        }
      </DialogActions>
    </Dialog>
  );
};

export default DadosTecnologiaProcesso;
