import * as React from 'react';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import HelpIcon from '@mui/icons-material/Help';
import {
  Dialog,
  DialogContent,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow
} from '@mui/material';

const HelpDialog = (props) => {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  function createData(col1,col2,col3,col4,col5,col6,col7,col8,col9,col10,col11,col12) {
    return { col1,col2,col3,col4,col5,col6,col7,col8,col9,col10,col11,col12 };
  }

  const rows = [
    createData('Visualização dos Indicadores gerais do programa',
               <CheckCircleIcon color="success"/>,
               <CheckCircleIcon color="success"/>,
               <CheckCircleIcon color="success"/>,
               <CancelIcon color="error"/>,
               <CancelIcon color="error"/>,
               <CheckCircleIcon color="success"/>,
               <CancelIcon color="error"/>,
               <CancelIcon color="error"/>,
               <CancelIcon color="error"/>,
               <CheckCircleIcon color="success"/>,
               <CancelIcon color="error"/>),
    createData('Visualização dos Dados Técnicos do Projeto',
               <CheckCircleIcon color="success"/>,
               <CheckCircleIcon color="success"/>,
               <CheckCircleIcon color="success"/>,
               <CheckCircleIcon color="success"/>,
               <CheckCircleIcon color="success"/>,
               <CheckCircleIcon color="success"/>,
               <CheckCircleIcon color="success"/>,
               <CheckCircleIcon color="success"/>,
               <CheckCircleIcon color="success"/>,
               <CheckCircleIcon color="success"/>,
               <CheckCircleIcon color="success"/>),
    createData('Acesso aos Dados do Financiamento do Projeto',
               <CheckCircleIcon color="success"/>,
               <CheckCircleIcon color="success"/>,
               <CancelIcon color="error"/>,
               <CheckCircleIcon color="success"/>,
               <CancelIcon color="error"/>,
               <CheckCircleIcon color="success"/>,
               <CancelIcon color="error"/>,
               <CancelIcon color="error"/>,
               <CheckCircleIcon color="success"/>,
               <CancelIcon color="error"/>,
               <CheckCircleIcon color="success"/>),
    createData('Realizar Cadastro de PMEs',
               <CancelIcon color="error"/>,
               <CheckCircleIcon color="success"/>,
               <CancelIcon color="error"/>,
               <CancelIcon color="error"/>,
               <CheckCircleIcon color="success"/>,
               <CancelIcon color="error"/>,
               <CheckCircleIcon color="success"/>,
               <CancelIcon color="error"/>,
               <CancelIcon color="error"/>,
               <CheckCircleIcon color="success"/>,
               <CancelIcon color="error"/>),
    createData('Inserção de Dados Técnicos do Projeto',
               <CancelIcon color="error"/>,
               <CheckCircleIcon color="success"/>,
               <CancelIcon color="error"/>,
               <CancelIcon color="error"/>,
               <CheckCircleIcon color="success"/>,
               <CancelIcon color="error"/>,
               <CheckCircleIcon color="success"/>,
               <CancelIcon color="error"/>,
               <CancelIcon color="error"/>,
               <CheckCircleIcon color="success"/>,
               <CancelIcon color="error"/>),
    createData('Validação de Relatórios de visita',
               <CheckCircleIcon color="success"/>,
               <CancelIcon color="error"/>,
               <CancelIcon color="error"/>,
               <CancelIcon color="error"/>,
               <CancelIcon color="error"/>,
               <CancelIcon color="error"/>,
               <CancelIcon color="error"/>,
               <CancelIcon color="error"/>,
               <CancelIcon color="error"/>,
               <CheckCircleIcon color="success"/>,
               <CheckCircleIcon color="success"/>),
    createData('Validação dos Indicadores gerais do programa',
               <CheckCircleIcon color="success"/>,
               <CheckCircleIcon color="success"/>,
               <CancelIcon color="error"/>,
               <CancelIcon color="error"/>,
               <CancelIcon color="error"/>,
               <CancelIcon color="error"/>,
               <CancelIcon color="error"/>,
               <CancelIcon color="error"/>,
               <CancelIcon color="error"/>,
               <CancelIcon color="error"/>,
               <CancelIcon color="error"/>),
    createData('Validação dos Dados Cadastrais da PME',
               <CheckCircleIcon color="success"/>,
               <CancelIcon color="error"/>,
               <CancelIcon color="error"/>,
               <CancelIcon color="error"/>,
               <CancelIcon color="error"/>,
               <CancelIcon color="error"/>,
               <CancelIcon color="error"/>,
               <CancelIcon color="error"/>,
               <CancelIcon color="error"/>,
               <CheckCircleIcon color="success"/>,
               <CancelIcon color="error"/>),
    createData('Validação dos Dados Técnicos do Projeto das PMEs;',
               <CancelIcon color="error"/>,
               <CancelIcon color="error"/>,
               <CancelIcon color="error"/>,
               <CancelIcon color="error"/>,
               <CancelIcon color="error"/>,
               <CancelIcon color="error"/>,
               <CheckCircleIcon color="success"/>,
               <CheckCircleIcon color="success"/>,
               <CancelIcon color="error"/>,
               <CheckCircleIcon color="success"/>,
               <CancelIcon color="error"/>),
    createData('Validar Relatório de pré - diagnóstio',
               <CheckCircleIcon color="success"/>,
               <CancelIcon color="error"/>,
               <CancelIcon color="error"/>,
               <CancelIcon color="error"/>,
               <CancelIcon color="error"/>,
               <CancelIcon color="error"/>,
               <CancelIcon color="error"/>,
               <CancelIcon color="error"/>,
               <CancelIcon color="error"/>,
               <CheckCircleIcon color="success"/>,
               <CheckCircleIcon color="success"/>),
    createData('Inserção de dados de financiamento do projeto;',
               <CancelIcon color="error"/>,
               <CancelIcon color="error"/>,
               <CancelIcon color="error"/>,
               <CheckCircleIcon color="success"/>,
               <CancelIcon color="error"/>,
               <CancelIcon color="error"/>,
               <CancelIcon color="error"/>,
               <CancelIcon color="error"/>,
               <CheckCircleIcon color="success"/>,
               <CancelIcon color="error"/>,
               <CancelIcon color="error"/>),
    createData('Validação dos dados de financiamento do projeto;',
               <CancelIcon color="error"/>,
               <CancelIcon color="error"/>,
               <CancelIcon color="error"/>,
               <CancelIcon color="error"/>,
               <CancelIcon color="error"/>,
               <CheckCircleIcon color="success"/>,
               <CancelIcon color="error"/>,
               <CancelIcon color="error"/>,
               <CancelIcon color="error"/>,
               <CancelIcon color="error"/>,
               <CancelIcon color="error"/>),
    createData('Gerar gráficos do Status do programa;',
               <CheckCircleIcon color="success"/>,
               <CheckCircleIcon color="success"/>,
               <CheckCircleIcon color="success"/>,
               <CancelIcon color="error"/>,
               <CancelIcon color="error"/>,
               <CheckCircleIcon color="success"/>,
               <CancelIcon color="error"/>,
               <CancelIcon color="error"/>,
               <CancelIcon color="error"/>,
               <CheckCircleIcon color="success"/>,
               <CancelIcon color="error"/>),
    createData('Emitir relatórios dos financiamentos dos projetos;',
               <CheckCircleIcon color="success"/>,
               <CheckCircleIcon color="success"/>,
               <CancelIcon color="error"/>,
               <CheckCircleIcon color="success"/>,
               <CancelIcon color="error"/>,
               <CheckCircleIcon color="success"/>,
               <CancelIcon color="error"/>,
               <CancelIcon color="error"/>,
               <CheckCircleIcon color="success"/>,
               <CancelIcon color="error"/>,
               <CancelIcon color="error"/>),
    createData('Emitir relatórios com dados técnicos dos projetos das PMEs;',
               <CheckCircleIcon color="success"/>,
               <CheckCircleIcon color="success"/>,
               <CancelIcon color="error"/>,
               <CheckCircleIcon color="success"/>,
               <CheckCircleIcon color="success"/>,
               <CheckCircleIcon color="success"/>,
               <CheckCircleIcon color="success"/>,
               <CancelIcon color="error"/>,
               <CheckCircleIcon color="success"/>,
               <CheckCircleIcon color="success"/>,
               <CancelIcon color="error"/>),
    createData('Criação de novos usuários;',
               <CancelIcon color="error"/>,
               <CheckCircleIcon color="success"/>,
               <CancelIcon color="error"/>,
               <CancelIcon color="error"/>,
               <CancelIcon color="error"/>,
               <CancelIcon color="error"/>,
               <CancelIcon color="error"/>,
               <CancelIcon color="error"/>,
               <CancelIcon color="error"/>,
               <CancelIcon color="error"/>,
               <CancelIcon color="error"/>),
    createData('Configurações do sistema;',
               <CancelIcon color="error"/>,
               <CheckCircleIcon color="success"/>,
               <CancelIcon color="error"/>,
               <CancelIcon color="error"/>,
               <CancelIcon color="error"/>,
               <CancelIcon color="error"/>,
               <CancelIcon color="error"/>,
               <CancelIcon color="error"/>,
               <CancelIcon color="error"/>,
               <CancelIcon color="error"/>,
               <CancelIcon color="error"/>),
    createData('Emitir relatório sintético dos status do projeto',
               <CheckCircleIcon color="success"/>,
               <CheckCircleIcon color="success"/>,
               <CheckCircleIcon color="success"/>,
               <CancelIcon color="error"/>,
               <CheckCircleIcon color="success"/>,
               <CancelIcon color="error"/>,
               <CheckCircleIcon color="success"/>,
               <CancelIcon color="error"/>,
               <CancelIcon color="error"/>,
               <CheckCircleIcon color="success"/>,
               <CancelIcon color="error"/>),
    createData('Acesso aos Dados do Fundo Garantidor;',
               <CheckCircleIcon color="success"/>,
               <CheckCircleIcon color="success"/>,
               <CancelIcon color="error"/>,
               <CancelIcon color="error"/>,
               <CancelIcon color="error"/>,
               <CheckCircleIcon color="success"/>,
               <CancelIcon color="error"/>,
               <CancelIcon color="error"/>,
               <CancelIcon color="error"/>,
               <CancelIcon color="error"/>,
               <CancelIcon color="error"/>),
    createData('Inserção de Dados do Fundo Garantidor;',
               <CancelIcon color="error"/>,
               <CancelIcon color="error"/>,
               <CancelIcon color="error"/>,
               <CancelIcon color="error"/>,
               <CancelIcon color="error"/>,
               <CheckCircleIcon color="success"/>,
               <CancelIcon color="error"/>,
               <CancelIcon color="error"/>,
               <CancelIcon color="error"/>,
               <CancelIcon color="error"/>,
               <CancelIcon color="error"/>),
    createData('Visualizar Dados de financiamento do projeto',
               <CheckCircleIcon color="success"/>,
               <CheckCircleIcon color="success"/>,
               <CancelIcon color="error"/>,
               <CheckCircleIcon color="success"/>,
               <CancelIcon color="error"/>,
               <CheckCircleIcon color="success"/>,
               <CancelIcon color="error"/>,
               <CancelIcon color="error"/>,
               <CheckCircleIcon color="success"/>,
               <CancelIcon color="error"/>,
               <CheckCircleIcon color="success"/>),
    createData('Inserção do Relatório de Visita Técnica',
               <CancelIcon color="error"/>,
               <CancelIcon color="error"/>,
               <CancelIcon color="error"/>,
               <CancelIcon color="error"/>,
               <CheckCircleIcon color="success"/>,
               <CancelIcon color="error"/>,
               <CheckCircleIcon color="success"/>,
               <CancelIcon color="error"/>,
               <CancelIcon color="error"/>,
               <CancelIcon color="error"/>,
               <CancelIcon color="error"/>),
    createData('Inserção do Relatório de Pré - diagnóstico',
               <CancelIcon color="error"/>,
               <CancelIcon color="error"/>,
               <CancelIcon color="error"/>,
               <CancelIcon color="error"/>,
               <CheckCircleIcon color="success"/>,
               <CancelIcon color="error"/>,
               <CheckCircleIcon color="success"/>,
               <CancelIcon color="error"/>,
               <CancelIcon color="error"/>,
               <CheckCircleIcon color="success"/>,
               <CancelIcon color="error"/>),
    createData('Cadastro de Fornecedores de Tecnologias e serviços de EE',
               <CancelIcon color="error"/>,
               <CheckCircleIcon color="success"/>,
               <CancelIcon color="error"/>,
               <CancelIcon color="error"/>,
               <CheckCircleIcon color="success"/>,
               <CancelIcon color="error"/>,
               <CancelIcon color="error"/>,
               <CancelIcon color="error"/>,
               <CancelIcon color="error"/>,
               <CheckCircleIcon color="success"/>,
               <CheckCircleIcon color="success"/>),
    createData('Solicitação de propostas de fornecedores por projeto',
               <CancelIcon color="error"/>,
               <CancelIcon color="error"/>,
               <CancelIcon color="error"/>,
               <CancelIcon color="error"/>,
               <CheckCircleIcon color="success"/>,
               <CancelIcon color="error"/>,
               <CancelIcon color="error"/>,
               <CancelIcon color="error"/>,
               <CancelIcon color="error"/>,
               <CancelIcon color="error"/>,
               <CheckCircleIcon color="success"/>),
    createData('Definição dos fornecedores selecionados por projeto',
               <CancelIcon color="error"/>,
               <CancelIcon color="error"/>,
               <CancelIcon color="error"/>,
               <CancelIcon color="error"/>,
               <CheckCircleIcon color="success"/>,
               <CancelIcon color="error"/>,
               <CancelIcon color="error"/>,
               <CancelIcon color="error"/>,
               <CancelIcon color="error"/>,
               <CancelIcon color="error"/>,
               <CheckCircleIcon color="success"/>),
    createData('Inserção da orçamentos de fornecedores',
               <CancelIcon color="error"/>,
               <CancelIcon color="error"/>,
               <CancelIcon color="error"/>,
               <CancelIcon color="error"/>,
               <CancelIcon color="error"/>,
               <CancelIcon color="error"/>,
               <CancelIcon color="error"/>,
               <CancelIcon color="error"/>,
               <CancelIcon color="error"/>,
               <CancelIcon color="error"/>,
               <CancelIcon color="error"/>),
    createData('Seleção dos orçamentos de fornecedores',
               <CancelIcon color="error"/>,
               <CancelIcon color="error"/>,
               <CancelIcon color="error"/>,
               <CancelIcon color="error"/>,
               <CancelIcon color="error"/>,
               <CancelIcon color="error"/>,
               <CancelIcon color="error"/>,
               <CancelIcon color="error"/>,
               <CancelIcon color="error"/>,
               <CancelIcon color="error"/>,
               <CheckCircleIcon color="success"/>),
    createData('Validação dos orçamentos de fornecedores',
               <CancelIcon color="error"/>,
               <CancelIcon color="error"/>,
               <CancelIcon color="error"/>,
               <CancelIcon color="error"/>,
               <CancelIcon color="error"/>,
               <CancelIcon color="error"/>,
               <CheckCircleIcon color="success"/>,
               <CheckCircleIcon color="success"/>,
               <CancelIcon color="error"/>,
               <CheckCircleIcon color="success"/>,
               <CheckCircleIcon color="success"/>),
    createData('Visualição dos orçamentos de fornecedores',
               <CancelIcon color="error"/>,
               <CancelIcon color="error"/>,
               <CancelIcon color="error"/>,
               <CancelIcon color="error"/>,
               <CheckCircleIcon color="success"/>,
               <CancelIcon color="error"/>,
               <CheckCircleIcon color="success"/>,
               <CheckCircleIcon color="success"/>,
               <CheckCircleIcon color="success"/>,
               <CheckCircleIcon color="success"/>,
               <CheckCircleIcon color="success"/>),
    createData('Solicitação de orçamentos de fornecedores',
               <CancelIcon color="error"/>,
               <CancelIcon color="error"/>,
               <CancelIcon color="error"/>,
               <CancelIcon color="error"/>,
               <CheckCircleIcon color="success"/>,
               <CancelIcon color="error"/>,
               <CancelIcon color="error"/>,
               <CancelIcon color="error"/>,
               <CancelIcon color="error"/>,
               <CheckCircleIcon color="success"/>,
               <CancelIcon color="error"/>),
    createData('Inserção do Relatório Final do Projeto',
               <CheckCircleIcon color="success"/>,
               <CheckCircleIcon color="success"/>,
               <CancelIcon color="error"/>,
               <CancelIcon color="error"/>,
               <CheckCircleIcon color="success"/>,
               <CancelIcon color="error"/>,
               <CheckCircleIcon color="success"/>,
               <CheckCircleIcon color="success"/>,
               <CancelIcon color="error"/>,
               <CheckCircleIcon color="success"/>,
               <CancelIcon color="error"/>),
    createData('Visualização do Relatório Final do Projeto',
               <CheckCircleIcon color="success"/>,
               <CheckCircleIcon color="success"/>,
               <CancelIcon color="error"/>,
               <CheckCircleIcon color="success"/>,
               <CheckCircleIcon color="success"/>,
               <CheckCircleIcon color="success"/>,
               <CheckCircleIcon color="success"/>,
               <CheckCircleIcon color="success"/>,
               <CheckCircleIcon color="success"/>,
               <CheckCircleIcon color="success"/>,
               <CheckCircleIcon color="success"/>),
    createData('Validação do Relatório Final do Projeto',
               <CancelIcon color="error"/>,
               <CancelIcon color="error"/>,
               <CancelIcon color="error"/>,
               <CancelIcon color="error"/>,
               <CancelIcon color="error"/>,
               <CancelIcon color="error"/>,
               <CheckCircleIcon color="success"/>,
               <CancelIcon color="error"/>,
               <CancelIcon color="error"/>,
               <CheckCircleIcon color="success"/>,
               <CheckCircleIcon color="success"/>),
  ];

  return (
    <React.Fragment>
      <IconButton aria-label="Ajuda" onClick={handleOpen}>
        <HelpIcon />
      </IconButton>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="child-modal-title"
        aria-describedby="child-modal-description"
        fullWidth
        maxWidth="false"
        scroll="body"
      >
        <DialogContent>
          {/* <img src="tabela_funcoes.png" /> */}
          <TableContainer>
            <Table size="small" aria-label="a dense table">
              <TableHead>
                <TableRow>
                  <TableCell>Requisitos:</TableCell>
                  <TableCell>Gestores da GIZ</TableCell>
                  <TableCell>Super admins</TableCell>
                  <TableCell>Parceiro Estratégicos (MME, EPE, ABESCO, PROCEL, BNDES)</TableCell>
                  <TableCell>DesenvolveSP (Financiadora)</TableCell>
                  <TableCell>Consultor de EE</TableCell>
                  <TableCell>Gestor do Fundo Garantidor</TableCell>
                  <TableCell>Validadores Independentes (SENAI)	</TableCell>
                  <TableCell>Validadores Independentes (Consultor GIZ)	</TableCell>
                  <TableCell>Instituições financeiras	</TableCell >
                  <TableCell>Gestores do SENAI</TableCell >
                  <TableCell>PME industrial</TableCell >
                </TableRow>
              </TableHead>
              <TableBody>
                {rows.map((row,index) => (
                  <TableRow
                    key={index}
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                  >
                    <TableCell component="th" scope="row">{row.col1}</TableCell>
                    <TableCell>{row.col2}</TableCell>
                    <TableCell>{row.col3}</TableCell>
                    <TableCell>{row.col4}</TableCell>
                    <TableCell>{row.col5}</TableCell>
                    <TableCell>{row.col6}</TableCell>
                    <TableCell>{row.col7}</TableCell>
                    <TableCell>{row.col8}</TableCell>
                    <TableCell>{row.col9}</TableCell>
                    <TableCell>{row.col10}</TableCell>
                    <TableCell>{row.col11}</TableCell>
                    <TableCell>{row.col12}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </DialogContent>

      </Dialog>


    </React.Fragment>
  );
}

export default HelpDialog;
