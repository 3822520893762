import * as React from 'react';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Button,
  FormControl,
  CircularProgress,
} from '@mui/material';
import api from "../../services/api";
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";
import { pt } from 'yup-locale-pt';
import {
  FloatField,
  Select,
  DateField
} from '../../components';
import {
  opcoesBooleanas
} from '../../static';
import { useStoreState, useStoreActions } from 'easy-peasy';

const DialogoDadosFinanceiros = ({
  dadosFinanceiros, processo, open, onClose
}) => {
  let loading = useStoreState((state) => state.nav.loading);
  let startLoading = useStoreActions((actions) => actions.nav.startLoading);
  let stopLoading = useStoreActions((actions) => actions.nav.stopLoading);
  const defaultValues = {
    data: null,
    field_6_6: null
  };
  const schema = yup.object({
    data: yup.date().nullable().required()
      .test(
        'is-trimestre',
        (d) => 'A data deve ser trimestral (março, junho, setembro ou dezembro)',
        (value)=>!shouldDisableMonth(value)
      )
      .label('Data'),
    field_6_3: yup.number().positive().float().required()
      .label('6.3 - Valor desembolsado'),
    field_6_4: yup.number().positive().float().required()
      .label('6.4 - Valor reembolsado'),
    field_6_6: yup.number().positive().integer().nullable().required()
      .label('6.6 - Crédito para EE em inadimplemento'),
    field_9_1: yup.number().positive().float().required()
      .label('9.1 - Quantia desembolsada da Comissão de Garantia'),
    field_9_4: yup.date().nullable().required()
      .label('9.4 - Data da transferência da Comissão de Garantia'),
  }).required();
  const useFormRef = useForm({
    mode: 'onTouched',
    resolver: yupResolver(schema),
    defaultValues: defaultValues
  });

  const { handleSubmit, reset, formState: {isDirty} } = useFormRef;


  const onSave = async (data) => {
    startLoading();
    try {
      if(data.id) {
        await api.http.put('/dados-financeiros/'+data.id,data);
      } else {
        await api.http.post('/dados-financeiros',{
          ...data,
          processoId: processo.id
        });
      }
      stopLoading({ severity: 'success'});
      close(true);
    } catch (error) {
      stopLoading({
        message: error.toString(),
        severity: 'error'
      });
      throw error;
    };
  };

  const remove = async () => {
    startLoading();
    try {
      await api.http.delete('/dados-financeiros/'+dadosFinanceiros.id);
      stopLoading({ severity: 'success'});
      close(true);
    } catch (error) {
      stopLoading({
        message: error.toString(),
        severity: 'error'
      });
      throw error;
    };
  };

  const close = (reload=false) => {
    reset(defaultValues);
    onClose(reload);
  };

  React.useEffect(()=> {
    reset(dadosFinanceiros);
  },[dadosFinanceiros]);


  const shouldDisableMonth = (date) => {
    return (((new Date(date)).getMonth()+1) % 3)!==0;
  };

  return (
    <Dialog open={open} onClose={()=>close()}>
      <DialogTitle>Dado Trimestral de Financiamento</DialogTitle>
      <DialogContent dividers>
        <FormControl sx={{ my: 2 }} fullWidth>
          <DateField
            field="data"
            useFormRef={useFormRef}
            schema={schema}
            views={['year','month']}
            inputFormat="MM/yyyy"
            shouldDisableMonth={shouldDisableMonth}
          />
        </FormControl>
        <FormControl sx={{ my: 2 }} fullWidth>
          <FloatField
            field="field_6_3"
            useFormRef={useFormRef}
            schema={schema} />
        </FormControl>
        <FormControl sx={{ my: 2 }} fullWidth>
          <FloatField
            field="field_6_4"
            useFormRef={useFormRef}
            schema={schema} />
        </FormControl>
        <FormControl sx={{ my: 2 }} fullWidth>
          <Select
            field="field_6_6"
            useFormRef={useFormRef}
            schema={schema}
            options={opcoesBooleanas} />
        </FormControl>
        <FormControl sx={{ my: 2 }} fullWidth>
          <FloatField
            field="field_9_1"
            useFormRef={useFormRef}
            schema={schema} />
        </FormControl>
        <FormControl sx={{ my: 2 }} fullWidth>
          <DateField
            field="field_9_4"
            useFormRef={useFormRef}
            schema={schema}
          />
        </FormControl>
      </DialogContent>
      <DialogActions>
        { loading>0 && <CircularProgress
                         size={30}
                         sx={{
                           'position': 'absolute',
                           'left':25,
                           'bottom': 12 }} /> }
        { dadosFinanceiros &&
          <Button color="inherit" onClick={()=>remove()}>
            REMOVER
          </Button>
        }
        <Button color="inherit" onClick={()=>close()}>
          CANCELAR
        </Button>
        <Button color="primary"
                onClick={handleSubmit(onSave)} disabled={!isDirty}>
          SALVAR
        </Button>
      </DialogActions>
      </Dialog>

  );
};


export default DialogoDadosFinanceiros;
