import React from 'react';
import {
  Paper,
  Button,
  Divider,
  FormControl,
  Box,
  Alert,
  Snackbar,
  Grid,
  Item,
  Link,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  ListItemButton,
} from '@mui/material';
import {
  AttachFile,
  Audiotrack,
  Description,
  PictureAsPdf,
  Theaters,
  Send
} from '@mui/icons-material/';
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";
import { pt } from 'yup-locale-pt';
import {
  useParams
} from "react-router-dom";
import {  FileField, DialogoVisualizador } from '../../components';
import api from "../../services/api";
import { useStoreActions } from 'easy-peasy';
import { driveBase } from '../../config';
yup.setLocale(pt);

const EnviaOrcamento = () => {
  let params = useParams();
  let startLoading = useStoreActions((actions) => actions.nav.startLoading);
  let stopLoading = useStoreActions((actions) => actions.nav.stopLoading);
  const [disabled, setDisabled] = React.useState(null);
  const [solicitacao, setSolicitacao] = React.useState(null);

  const schema = yup.object({
    docOrcamentoId: yup.number().positive().integer().label('Orçamento')
  }).required();

  const useFormRef = useForm({
    mode: 'onTouched',
    resolver: yupResolver(schema)
  });

  const { handleSubmit, formState: {isDirty} } = useFormRef;

  const onSubmit = async (data) => {
    startLoading();
    try {
      await api.http.put('/orcamentos-fornecedores/'+params.hash,data);
      stopLoading({
        message: 'Orçamento enviado com sucesso',
        severity: 'success'
      });
      setDisabled(true);
    } catch (error) {
      stopLoading({
        message: error.toString(),
        severity: 'error'
      });
    };
  };

  React.useEffect(async () => {
    startLoading();
    try {
      let sol = (await api.http.get('/orcamentos-fornecedores/'+
                                    params.hash)).data;
      setSolicitacao(sol);
      if(sol.orcamento[0].docOrcamentoId) {
        setDisabled(true);
        stopLoading({
          message: 'Orçamento já enviado',
          severity: 'info'
        });
      } else
        stopLoading();
    } catch (error) {
      setDisabled(true);
      stopLoading({
        message: error.toString(),
        severity: 'error'
      });
    }
  },[]);

  const PreviewIcon = ({arquivo}) => {

    if (arquivo.mimeType.startsWith("video/")) return <Theaters />;
    if (arquivo.mimeType.startsWith("audio/")) return <Audiotrack />;

    switch (arquivo.mimeType) {
    case "application/msword":
    case "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
      return <Description />;
    case "application/pdf":
      return <PictureAsPdf />;
    default:
      return <AttachFile  />;
    }
  };

  const [file, setFile] = React.useState(null);
  const [openDlgVis, setOpenDlgVis] = React.useState(false);

  const onCloseDlgVis = () => {
    setFile(null);
    setOpenDlgVis(false);
  };

  const onIconClick = (arquivo) => {
    if (arquivo.mimeType=='application/pdf' ||
        arquivo.mimeType=='text/html' ||
        arquivo.mimeType.indexOf('image')==0) {
      setFile(arquivo);
      setOpenDlgVis(true);
    } else{
      window.location.href = driveBase+arquivo.fileRef;
    }
  };

  return (
    <Paper variant="elevation" elevation={4} className='center-box' sx={{
      textAlign: 'center',
      px: 4,
      pt: 1,
      pb: 3,
      m: 5,
      maxWidth: 'sm',
    }}>
      <DialogoVisualizador file={file} open={openDlgVis}
                           onClose={onCloseDlgVis}/>
      <Divider>
        <h3>Arquivos da solicitação</h3>
      </Divider>
        <List sx={{width: '100%'}}>
        {solicitacao?.docsSolicitacao.arquivos.map((solArquivo,index)=>(
          <ListItem disablePadding key={index}>
            <ListItemButton onClick={() => onIconClick(solArquivo.arquivo)}>
              <ListItemIcon>
                <PreviewIcon arquivo={solArquivo.arquivo} />
              </ListItemIcon>
              <ListItemText primary={solArquivo.arquivo.fileName} />
            </ListItemButton>
          </ListItem>

        ))}
        </List>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Divider>
          <h3>Envio do orçamento</h3>
        </Divider>
        <FormControl sx={{ my: 2 }} fullWidth>
          <FileField field="docOrcamentoId" disabled={disabled}
                     uploadUrl={'/orcamentos-fornecedores/'+params.hash}
                     useFormRef={useFormRef} schema={schema} />
        </FormControl>
        <Box sx={{ mt: 2 }}>
          <Button type="submit" variant="contained" size="large"
                  endIcon={<Send />} disabled={disabled || !isDirty}>
            Enviar
          </Button>
        </Box>
      </form>
    </Paper>
  );
};

export default EnviaOrcamento;
