import * as React from 'react';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
  FormControl,
  CircularProgress,
} from '@mui/material';
import api from "../../services/api";
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";
import {
  Select
} from '../../components';
import { useStoreState, useStoreActions } from 'easy-peasy';
import { opcoesBooleanas } from '../../static';


const DialogoTecnologiaProcesso = ({
  tecnologias, tecnologiaProcesso, processo, open, onClose
}) => {
  const [categorias, setCategorias] = React.useState();
  let loading = useStoreState((state) => state.nav.loading);
  let startLoading = useStoreActions((actions) => actions.nav.startLoading);
  let stopLoading = useStoreActions((actions) => actions.nav.stopLoading);
  const defaultValues = {
    tecnologiaId: null,
    isolamentoTermico: null,
    aproveitamentoDeEnergiaRenovavel:null,
    medidaDeGestaoEnergetica: null,
    medidaDeControleOuSupervisorio: null,
  };
  const schema = yup.object({
    tecnologiaId: yup.number().positive().integer().required()
      .nullable().label('Categorias de Tecnologia'),
    isolamentoTermico: yup.number().positive().integer().required()
      .nullable().label('Isolamento Térmico'),
    aproveitamentoDeEnergiaRenovavel: yup.number().positive().integer().required()
      .nullable().label('Aproveitamento de Energia Renovável'),
    medidaDeGestaoEnergetica: yup.number().positive().integer().required()
      .nullable().label('Medida de gestão energética'),
    medidaDeControleOuSupervisorio: yup.number().positive().integer().required()
      .nullable().label('Medida de controle ou supervisório'),
  }).required();

  const useFormRef = useForm({
    mode: 'onTouched',
    resolver: yupResolver(schema),
    defaultValues: defaultValues
  });

  const { handleSubmit, reset, formState: { isDirty } } = useFormRef;

  const onSave = async (data) => {
    startLoading();
    try {
      if (data.id){
        await api.http.put('/tecnologias-processos/'+data.id, data);
      } else {
        await api.http.post('/tecnologias-processos', {
          ...data,
          processoId: processo.id,
        });
      }
      stopLoading({ severity: 'success' });
      close(true);
    } catch (error) {
      stopLoading({
        message: error.toString(),
        severity: 'error'
      });
      throw error;
    };
  };

  const remove = async () => {
    startLoading();
    try {
      await api.http.delete('/tecnologias-processos/' + tecnologiaProcesso.id);
      stopLoading({ severity: 'success' });
      close(true);
    } catch (error) {
      stopLoading({
        message: error.toString(),
        severity: 'error'
      });
      throw error;
    };
  };

  const loadCategorias = async (mount) => {
    try {
      let data = (await api.http.get('/categorias')).data;
      if (mount.isMounted) {
        setCategorias(data);
      }
      stopLoading();
    } catch (error) {
      stopLoading({
        message: error.toString(),
        severity: 'error'
      });
    }
  };

  React.useEffect(() => {
    let mount = {
      isMounted: true
    };
    loadCategorias(mount);
    return () => { mount.isMounted = false; };
  }, []);

  const removeIsolamentoTermicoEcategorias = tecnologias.filter((itens) => {
    return itens.id !== 1 && itens.categoria !== 'B';
  });

  const close = (reload = false) => {
    reset(defaultValues);
    onClose(reload);
  };

  React.useEffect(()=> {
    reset(tecnologiaProcesso);
  },[tecnologiaProcesso]);

  return (
    <Dialog open={open} onClose={() => close()} >
      <DialogTitle>Cadastro Categorias de Tecnologias</DialogTitle>
      <DialogContent dividers>
        <FormControl sx={{ my: 2, width:550 }} fullWidth>
          <Select
            field="tecnologiaId"
            useFormRef={useFormRef}
            schema={schema}
            groupBy={(option) => 'Categoria ' +
              option.categoria
              + ': ' +
              categorias.filter((categoria) =>
                categoria.id == option.categoria)
                .map((label) => label.nome)
            }
            options={removeIsolamentoTermicoEcategorias} />
        </FormControl>
        <FormControl sx={{ my: 2, width:550 }} fullWidth>
          <Select
            field="isolamentoTermico"
            useFormRef={useFormRef}
            schema={schema}
            options={opcoesBooleanas} />
        </FormControl>
        <FormControl sx={{ my: 2, width:550 }} fullWidth>
          <Select
            field="aproveitamentoDeEnergiaRenovavel"
            useFormRef={useFormRef}
            schema={schema}
            options={opcoesBooleanas} />
        </FormControl>
        <FormControl sx={{ my: 2, width:550 }} fullWidth>
          <Select
            field="medidaDeGestaoEnergetica"
            useFormRef={useFormRef}
            schema={schema}
            options={opcoesBooleanas} />
        </FormControl>
        <FormControl sx={{ my: 2, width:550 }} fullWidth>
          <Select
            field="medidaDeControleOuSupervisorio"
            useFormRef={useFormRef}
            schema={schema}
            options={opcoesBooleanas} />
        </FormControl>
      </DialogContent>
      <DialogActions>
        {loading > 0 && <CircularProgress
          size={30}
          sx={{
            'position': 'absolute',
            'left': 25,
            'bottom': 12
          }} />}
        {tecnologiaProcesso &&
          <Button color="inherit" onClick={() => remove()}>
            REMOVER
          </Button>
        }
        <Button color="inherit" onClick={() => close()}>
          CANCELAR
        </Button>
        <Button color="primary"
          onClick={handleSubmit(onSave)} disabled={!isDirty}>
          SALVAR
        </Button>
      </DialogActions>
    </Dialog>

  );
};


export default DialogoTecnologiaProcesso;
