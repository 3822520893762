import * as React from 'react';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Button,
  FormControl,
  CircularProgress,
} from '@mui/material';
import api from "../services/api";
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";
import { pt } from 'yup-locale-pt';
import {
  TextField,
  FloatField,
  Select,
} from './';
import {
  listaEnergeticos,
  subsegmentos,
  listaProcessos,
  sistemas
} from '../static';
import { useStoreState, useStoreActions } from 'easy-peasy';

const DialogoBenchmarking = ({
  benchmarking, processo, final, open, onClose
}) => {
  let loading = useStoreState((state) => state.nav.loading);
  let startLoading = useStoreActions((actions) => actions.nav.startLoading);
  let stopLoading = useStoreActions((actions) => actions.nav.stopLoading);
  const defaultValues = {
    subsegmento: null,
    processoBenchmarking: null,
    sistema: null,
    producaoAnual: '',
    energeticoUtilizado: null,
    consumoAnual: '',
    consumoAnualFinal: '',
    investimentoEstimado: '',
    custoEnergetico: '',
  };
  const schema = yup.object({
    subsegmento: yup.number().positive().integer().required().nullable()
      .label('Sub-segmento'),
    processoBenchmarking: yup.number().positive().integer().required()
      .nullable().label('Processo'),
    sistema: yup.number().positive().integer().required()
      .nullable().label('Sistema'),
    producaoAnual: yup.number().positive().integer().required()
      .label('Produção Anual (ton)'),
    energeticoUtilizado: yup.number().positive().integer().required()
      .nullable().label('Energético Utilizado'),
    consumoAnual: yup.number().positive().integer().required()
      .label('Consumo Anual Inicial (MWh)'),
    consumoAnualFinal: yup.number().positive().integer().required()
      .label('Consumo Anual Final (MWh)'),
    investimentoEstimado: yup.number().positive().float().required()
      .label('Investimento Estimado (R$)'),
    custoEnergetico: yup.number().positive().float().required()
      .label('Custo do Energético (R$/MWh)'),
  }).required();
  const useFormRef = useForm({
    mode: 'onTouched',
    resolver: yupResolver(schema),
    defaultValues: defaultValues
  });

  const { handleSubmit, reset, watch, formState: {isDirty} } = useFormRef;

  const processoBenchmarking = watch('processoBenchmarking');

  const onSave = async (data) => {
    startLoading();
    try {
      if(data.id) {
        await api.http.put('/benchmarkings/'+data.id,data);
      } else {
        await api.http.post('/benchmarkings',{
          ...data,
          processoId: processo.id,
          final: final
        });
      }
      stopLoading({ severity: 'success'});
      close(true);
    } catch (error) {
      stopLoading({
        message: error.toString(),
        severity: 'error'
      });
      throw error;
    };
  };

  const remove = async () => {
    startLoading();
    try {
      await api.http.delete('/benchmarkings/'+benchmarking.id);
      stopLoading({ severity: 'success'});
      close(true);
    } catch (error) {
      stopLoading({
        message: error.toString(),
        severity: 'error'
      });
      throw error;
    };
  };

  const close = (reload=false) => {
    reset(defaultValues);
    onClose(reload);
  };

  React.useEffect(()=> {
    reset(benchmarking);
  },[benchmarking]);


  return (
    <Dialog open={open} onClose={()=>close()}>
      <DialogTitle>Dados do benchmarking</DialogTitle>
      <DialogContent dividers>
        <FormControl sx={{ my: 2 }} fullWidth>
          <Select
            field="subsegmento"
            useFormRef={useFormRef}
            schema={schema}
            options={subsegmentos[processo.setorAtuacaoId]} />
        </FormControl>
        <FormControl sx={{ my: 2 }} fullWidth>
          <Select
            field="processoBenchmarking"
            useFormRef={useFormRef}
            schema={schema}
            options={listaProcessos} />
        </FormControl>
        <FormControl sx={{ my: 2 }} fullWidth>
          <Select
            field="sistema"
            useFormRef={useFormRef}
            schema={schema}
            options={processoBenchmarking?sistemas[processoBenchmarking]:[]} />
        </FormControl>
        <FormControl sx={{ my: 2 }} fullWidth>
          <TextField
            field="producaoAnual" type="number"
            useFormRef={useFormRef}
            schema={schema} />
        </FormControl>
        <FormControl sx={{ my: 2 }} fullWidth>
          <Select
            field="energeticoUtilizado"
            useFormRef={useFormRef}
            schema={schema}
            options={listaEnergeticos} />
        </FormControl>
        <FormControl sx={{ my: 2 }} fullWidth>
          <TextField
            field="consumoAnual" type="number"
            useFormRef={useFormRef}
            schema={schema} />
        </FormControl>
        <FormControl sx={{ my: 2 }} fullWidth>
          <TextField
            field="consumoAnualFinal" type="number"
            useFormRef={useFormRef}
            schema={schema} />
        </FormControl>
        <FormControl sx={{ my: 2 }} fullWidth>
          <FloatField
            field="investimentoEstimado"
            useFormRef={useFormRef}
            schema={schema} />
        </FormControl>
        <FormControl sx={{ my: 2 }} fullWidth>
          <FloatField
            field="custoEnergetico"
            useFormRef={useFormRef}
            schema={schema} />
        </FormControl>
      </DialogContent>
      <DialogActions>
        { loading>0 && <CircularProgress
                         size={30}
                         sx={{
                           'position': 'absolute',
                           'left':25,
                           'bottom': 12 }} /> }
        { benchmarking &&
          <Button color="inherit" onClick={()=>remove()}>
            REMOVER
          </Button>
        }
        <Button color="inherit" onClick={()=>close()}>
          CANCELAR
        </Button>
        <Button color="primary"
                onClick={handleSubmit(onSave)} disabled={!isDirty}>
          SALVAR
        </Button>
      </DialogActions>
      </Dialog>

  );
};


export default DialogoBenchmarking;
