import React from "react"
import { Grid } from "@mui/material"

const GestaoSenai = () => {
    return (
        <Grid
            container direction="row"
            justifyContent="center"
            alignItems="center"
        >
            <iframe
                width={1100}
                height={600}
                src="https://app.powerbi.com/view?r=eyJrIjoiYmI2ZDZmZjEtZWU3YS00ZDljLTljNjQtNzY0MTA0NGYxZTBjIiwidCI6IjQxNDhhNmRlLTBkZDEtNGQwNC1hNGM1LTc4ZTM3NGU0ZjZkNiIsImMiOjR9"
            >
            </iframe>
        </Grid>
    )
}
export default GestaoSenai
