import React, {useState} from 'react';
import api from "../../services/api";
import {
  Paper,
  Divider,
  Grid,
  Box,
  Container,
  FormControl,
  Checkbox,
  Button,
  Select,
  Stack,
  MenuItem
} from '@mui/material';
import {
  Autocomplete,
  DateField
} from '../../components';
import { useStoreActions } from 'easy-peasy';
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";
import { pt } from 'yup-locale-pt';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import { Chart } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  TimeScale,
  LinearScale,
  PointElement,
  LineElement,
  BarElement,
  Title,
  Tooltip,
  Legend,
  LineController,
  BarController
} from 'chart.js';
import 'chartjs-adapter-date-fns';
import ptBRLocale from 'date-fns/locale/pt-BR';
import autocolors from 'chartjs-plugin-autocolors';
import zoomPlugin from 'chartjs-plugin-zoom';
import { CSVLink, CSVDownload } from "react-csv";


yup.setLocale(pt);
const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;


const IndicadoresFinanceiros = () => {
  let startLoading = useStoreActions((actions) => actions.nav.startLoading);
  let stopLoading = useStoreActions((actions) => actions.nav.stopLoading);
  const [regioes, setRegioes] = React.useState([]);
  const [segmentosIndustriais , setSegmentosIndustriais] = useState([]);
  const [minDate, setMinDate] = React.useState(null);
  const [maxDate, setMaxDate] = React.useState(null);
  const [datesDisabled, setDatesDisabled] = React.useState(true);
  const [datasets, setDatasets] = React.useState([]);
  const [chartType, setChartType] = React.useState('line');
  const [chartTitle, setChartTitle] = React.useState('Indicador');
  const [exporta,setExporta] = React.useState([]);

  const serieOptions = [{
    id: 'regiao',
    label: 'Regiões'
  },{
    id: 'segmento industrial',
    label: 'Segmentos'
  },{
    id: 'total',
    label: 'Total'
  }];
  const agrupamentos = [{
    id: 'mes',
    label: 'Mes'
  },{
    id: 'trimestre',
    label: 'Trimestre'
  },{
    id: 'ano',
    label: 'Ano'
  }];
  const indicadores = [{
    id:'field_1_3',
    label:'Valor agregado assinado (R$)'
  },{
    id: 'field_1_4',
    label:'Valor agregado desembolsado (R$)'
  },{
    id: 'field_1_4_2',
    label:'Custo Total do investimento em EE (R$)'
  }, {
    id:'field_1_5',
    label:'Valor agregado alocado/desembolsado (%)'
  },{
    id:'field_1_7',
    label:'Valor de garantia comprometida (R$)'
  },{
    id:'field_1_8',
    label:'Valor de garantia solicitada (R$)'
  },{
    id:'field_1_11',
    label:'Porcentagem de Créditos Cobertos para EE em Inadimplemento na carteira (%)'
  },{
    id:'field_1_11_1',
    label:'Porcentagem de Créditos Cobertos para EE em Inadimplemento na carteira (R$)'
  },{
    id:'field_1_13',
    label:'Valor cumulativo das recuperações (R$)'
  },{
    id:'field_1_18',
    label:'Valor de investimento agregado de fontes privadas (R$)'
  },{
    id:'field_1_18_1',
    label:'Valor total do projeto'
  },{
    id:'field_1_18_2',
    label:'Valor de investimento agregado de fontes privada (%)'
  },{
    id: 'field_1_19',
    label:'Exigências de garantia agregada reduzida (R$)'
  },{
    id:'field_1_20',
    label:'Comissão de garantia agregada de empréstimos DSP (R$)'
  },{
    id:'field_1_21',
    label:'Comissão de garantia agregada de empréstimos de instituições financeiras participantes (Acesso Direto) (R$)'
  }];
  const schema = yup.object({
    serie: yup.string()
      .nullable()
      .oneOf(serieOptions.map((serie)=>serie.id))
      .label('Série')
      .required(),
    agrupamento: yup.string()
      .nullable()
      .oneOf(agrupamentos.map((agrupamento)=>agrupamento.id))
      .label('Agrupamento')
      .required(),
    regiao: yup.array()
      .of(yup.number().positive())
      .label('Regiões'),
    segmentoIndustrial: yup.array()
      .of(yup.number().positive())
      .label('Segmentos industriais'),
    indicador: yup.string()
      .nullable()
      .oneOf(indicadores.map((indicador)=>indicador.id))
      .label('Indicador')
      .required(),
    de: yup.date().label('De').nullable().required(),
    ate: yup.date().label('Até').nullable().required(),
  }).required();

  const useFormRef = useForm({
    mode: 'onTouched',
    resolver: yupResolver(schema),
    defaultValues: {
      serie: 'regiao',
      agrupamento: 'mes',
      de: null,
      ate: null
    }
  });

  const { handleSubmit, setValue,
          watch, formState: {isDirty,isSubmitting}
        } = useFormRef;

  //load datas
  const deField = watch('de');
  const ateField = watch('ate');
  const selectedRegioes = watch('regiao');
  const selectedSetoresAtuacao = watch('segmentoIndustrial');
  const agrupamento = watch('agrupamento');
  const indicador = watch('indicador');
  const serie = watch('serie');

  React.useEffect(()=>{
    if(deField && ateField && deField>ateField) {
      setValue('ate',deField);
    }
  },[deField]);

  React.useEffect(()=>{
    if(indicador==null)
      return;
    let mount = {
      isMounted: true
    };
    loadDatas(mount);
  },[selectedRegioes,selectedSetoresAtuacao,indicador]);

  const loadDatas = async(mount)=>{
   startLoading();
   try {
    if(mount.isMounted){
      let datas = (await api.http.post('/indicadores-financeiros/datas',{
        regiao: selectedRegioes?selectedRegioes:null,
        segmentoIndustrial: selectedSetoresAtuacao?selectedSetoresAtuacao:null,
        indicador:indicador?indicador:null
      })).data;
      if(datas.minDate && datas.maxDate) {
        let minDateArr = datas.minDate.split('-');
        let maxDateArr = datas.maxDate.split('-');
        let minDate = new Date(minDateArr[0],minDateArr[1]-1,1);
        let maxDate = new Date(maxDateArr[0],maxDateArr[1]-1,1);
        setMinDate(minDate);
        setMaxDate(maxDate);
        if(!deField || deField<minDate) {
          setValue('de',minDate);
        } else if(deField>maxDate) {
          setValue('de',maxDate);
        }
        if(!ateField || ateField>maxDate) {
          setValue('ate',maxDate);
        } else if(ateField<minDate) {
          setValue('ate',minDate);
        }
        setDatesDisabled(false);
    }else{
      setDatesDisabled(true);
        setValue('de',null);
        setValue('ate',null);
    }
  };
   stopLoading();
  }catch (error) {
    stopLoading({
      message: error.toString(),
      severity: 'error'
    });
  };
};

  const loadSetoresAtuacao = async (mount) => {
    startLoading();
    try {
      let data = (await api.http.get('/setores-atuacao')).data;
      if(mount.isMounted)
      setSegmentosIndustriais(data);
      stopLoading();
    } catch(error) {
      stopLoading({
        message: error.toString(),
        severity: 'error'
      });
      throw error;
    }
  };
  const loadRegioes = async (mount) => {
    startLoading();
    try {
      let data = ((await api.http.get('/regioes')).data);
      if(mount.isMounted)
      setRegioes(data);
      stopLoading();
    } catch(error) {
      stopLoading({
        message: error.toString(),
        severity: 'error'
      });
      throw error;
    }
  };
  React.useEffect(() => {
    let mount = {
      isMounted: true
    };
    loadSetoresAtuacao(mount);
    loadRegioes(mount);
  },[]);

  const submit = async (data) => {
    startLoading();
    try {
      setChartTitle(indicadores.find((ind)=>ind.id==indicador).label);
      let newDatasets = (await api.http.post('/indicadores-financeiros/datasets',{
        ...data,
      })).data;
      setDatasets(newDatasets);
      if(newDatasets.length==0) {
        stopLoading({
          message: 'Nenhum registro encontrado.',
          severity: 'error'
        });
      } else {
        stopLoading();
      }
    } catch (error) {
      stopLoading({
        message: error.toString(),
        severity: 'error'
      });
    };
  };

  const exportar = async (data) => {
    startLoading();
    try {
      let newDatasets = (await api.http.post('/indicadores-financeiros/datasets',{
        ...data,
      })).data.map((item)=>{
        return{
          Legenda: item.label,
          Valor: item.data[0].y,
          Data: item.data[0].x
        };
      });
      setExporta(newDatasets);
      setExporta([]);
      stopLoading({ severity: 'success' });
    } catch(error) {
      stopLoading({
        message: error.toString(),
        severity: 'error'
      });
    }
  };



  ChartJS.register(
    TimeScale,
    LinearScale,
    PointElement,
    LineElement,
    BarElement,
    LineController,
    BarController,
    Title,
    Tooltip,
    Legend,
    autocolors,
    zoomPlugin
  );


  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: 'top',
      },
      title: {
        display: true,
        text: chartTitle,
      },
      zoom: {
        pan: {
          enabled: true,
          mode: 'x',
          modifierKey: 'ctrl',
        },
        zoom: {
          drag: {
            enabled: true,
          },
          mode: 'x',
        }
      }
    },
    scales: {
      x: {
        type: 'time',
        time: {
          unit: ['mes','trimestre'].includes(agrupamento)?'month':'year'
        },
        adapters: {
          date: {
            locale: ptBRLocale
          }
        }
      },
      y: {
        min: 0
      }
    }

  };

  const chartRef = React.useRef(null);
  const resetZoom = () => {
    chartRef.current.resetZoom();
  };

  const onChangeType = (event) => {
    setChartType(event.target.value);
  };

  React.useEffect(async ()=> {
    if(chartRef && chartRef.current) {
      chartRef.current.update();
    }
  },[chartType]);


  return (
    <Container maxWidth="false">
      <Paper variant="outlined" className='center-box' sx={{
        textAlign: 'center',
        px: 4,
        pt: 2,
        pb: 3,
        m: 5,
      }}>
        <Box
          component="form"
          noValidate
          autoComplete="off"
          onSubmit={handleSubmit(submit)}
        >
          <Stack direction="row" spacing={2} justifyContent="center">
            <FormControl sx={{minWidth:180}} >
              <Autocomplete
                field="serie"
                useFormRef={useFormRef}
                options={serieOptions}
                schema={schema}  />
            </FormControl>
            <FormControl sx={{minWidth:180}} >
              <Autocomplete
                field="agrupamento"
                useFormRef={useFormRef}
                options={agrupamentos}
                schema={schema}  />
            </FormControl>
          </Stack>
          <Grid container spacing={2} sx={{mt:2,mb:4}}>
            <Grid item md={4}>
              <FormControl fullWidth >
                <Autocomplete
                  multiple
                  disableCloseOnSelect
                  field="regiao"
                  useFormRef={useFormRef}
                  options={regioes}
                  getOptionLabel={(regiao) => regiao.label}
                  renderOption={(props, option, { selected }) => (
                    <li {...props}>
                      <Checkbox
                        icon={icon}
                        checkedIcon={checkedIcon}
                        style={{ marginRight: 8 }}
                        checked={selected}
                      />
                      {props.key}
                    </li>
                  )}
                  schema={schema}  />
              </FormControl>
            </Grid>
            <Grid item md={4}>
              <FormControl fullWidth >
                <Autocomplete
                  multiple
                  disableCloseOnSelect
                  field="segmentoIndustrial"
                  useFormRef={useFormRef}
                  options={segmentosIndustriais}
                  getOptionLabel={(segmentoInd) => segmentoInd.nome}
                  renderOption={(props, option, { selected }) => (
                    <li {...props}>
                      <Checkbox
                        icon={icon}
                        checkedIcon={checkedIcon}
                        style={{ marginRight: 8 }}
                        checked={selected}
                      />
                      {props.key}
                    </li>
                  )}
                  schema={schema}  />
              </FormControl>
            </Grid>
            <Grid item md={4} >
              <FormControl fullWidth>
                <Autocomplete
                  field="indicador"
                  useFormRef={useFormRef}
                  options={indicadores}
                  getOptionLabel={(ind) => ind.label}
                  schema={schema} />
              </FormControl>
            </Grid>
          </Grid>
          <Stack direction="row" spacing={2} justifyContent="center">
            <FormControl >
              <DateField
                field="de"
                views={agrupamento=='mes'?['year','month']:['year']}
                inputFormat={['mes','trimestre'].includes(agrupamento)?
                             "MM/yyyy":"yyyy"}
                minDate={minDate}
                maxDate={maxDate}
                disabled={datesDisabled}
                useFormRef={useFormRef}
                schema={schema}/>
            </FormControl>
            <FormControl  >
              <DateField
                field="ate"
                views={['mes','trimestre'].includes(agrupamento)?
                       ['year','month']:['year']}
                inputFormat={['mes','trimestre'].includes(agrupamento)?
                             "MM/yyyy":"yyyy"}
                minDate={deField?deField:minDate}
                maxDate={maxDate}
                disabled={datesDisabled}
                useFormRef={useFormRef}
                schema={schema} />
            </FormControl>
            <Button type="submit"
                    variant="contained"
                    disabled={isSubmitting}>
              Plotar
            </Button >
            <Button
              variant="contained"
              onClick={handleSubmit(exportar)}
            >
              exportar
            </Button>
            { exporta && exporta.length>0 &&
              <CSVDownload
                data={exporta}
                target="_blank" />
            }


          </Stack>
        </Box>
      </Paper>
      {datasets.length>0 &&

       <Paper variant="outlined" className='center-box' sx={{
         textAlign: 'center',
         px: 4,
         pt: 2,
         pb: 3,
         m: 5,
       }}>
         <Chart
           type={chartType}
           options={options}
           data={{
             datasets: datasets.map((data)=>({
               label:data.label,
               data:data.data,
               type: chartType
             }))
           }}
           ref={chartRef}
         />
         <Stack direction="row" spacing={2} sx={{mt:5}} justifyContent="center">
           <Button onClick={()=>{resetZoom();}}>Reset Zoom</Button>
           <Select
             label="Tipo de gráfico"
             value={chartType}
             onChange={onChangeType}
           >
             <MenuItem value="line">Linha</MenuItem>
             <MenuItem value="bar">Barra</MenuItem>
           </Select>
         </Stack>
       </Paper>
      }
    </Container>
  );
};

export default IndicadoresFinanceiros;
