import { yupResolver } from "@hookform/resolvers/yup";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  Checkbox as MUICheckbox,
} from "@mui/material";
import { useStoreActions, useStoreState } from "easy-peasy";
import * as React from "react";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { Autocomplete, FileListField, Select } from "../../components";
import api from "../../services/api";
const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const DialogoSolicitacaoOrcamentos = ({ open, onClose, disabled }) => {
  let loading = useStoreState((state) => state.nav.loading);
  let startLoading = useStoreActions((actions) => actions.nav.startLoading);
  let stopLoading = useStoreActions((actions) => actions.nav.stopLoading);
  let currProcesso = useStoreState((state) => state.processo.current);
  const [tecnologias, setTecnologias] = React.useState([]);
  const [regioes, setRegioes] = React.useState([]);
  const [categorias, setCategorias] = React.useState([]);
  const [solicitacaoOrcamentos, setSolicitacaoOrcamentos] =
    React.useState(null);

  const defaultValues = {
    tecnologiaId: [],
    regiaoId: "",
    docsSolicitacaoField: [],
  };
  const schema = yup
    .object({
      tecnologiaId: yup
        .array()
        .min(1)
        .label("Tecnologia")
        .required("Selecione pelo menos uma tecnologia"),
      regiaoId: yup.number().positive().integer().label("Região"),
      docsSolicitacaoField: yup
        .array()
        .min(1)
        .label(
          "Arraste aqui a especificação técnica e outros documentos " +
            "relavantes"
        ),
    })
    .required();
  const useFormRef = useForm({
    mode: "onTouched",
    resolver: yupResolver(schema),
    defaultValues: defaultValues,
  });

  const {
    handleSubmit,
    reset,
    formState: { isDirty },
  } = useFormRef;

  // load categorias
  React.useEffect(async () => {
    startLoading();
    try {
      setCategorias((await api.http.get("/categorias")).data);
      stopLoading();
    } catch (error) {
      stopLoading({
        message: error.toString(),
        severity: "error",
      });
    }
  }, []);

  // load tecnologias
  React.useEffect(async () => {
    startLoading();
    try {
      setTecnologias((await api.http.get("/tecnologias")).data);
      stopLoading();
    } catch (error) {
      stopLoading({
        message: error.toString(),
        severity: "error",
      });
    }
  }, []);

  // load regiões
  React.useEffect(async () => {
    startLoading();
    try {
      const regioes = (await api.http.get("/regioes")).data;
      setRegioes(regioes);

      reset({
        regiaoId: currProcesso?.regiaoId,
      });

      stopLoading();
    } catch (error) {
      stopLoading({
        message: error.toString(),
        severity: "error",
      });
    }
  }, []);

  const onSave = async (data) => {
    startLoading();
    try {
      await api.http.post("/solicitacoes-orcamentos", {
        ...data,
        processoId: currProcesso.id,
      });
      stopLoading({ severity: "success" });
      close();
    } catch (error) {
      stopLoading({
        message: error.toString(),
        severity: "error",
      });
      throw error;
    }
  };

  const close = () => {
    reset(defaultValues);
    onClose();
  };

  React.useEffect(async () => {
    if (open) {
      startLoading();
      try {
        let data = (
          await api.http.get("/solicitacoes-orcamentos/" + currProcesso.id)
        ).data;
        reset(data);
        setSolicitacaoOrcamentos(data);
        stopLoading();
      } catch (error) {
        if (!error.response || error.response.status != 404) {
          stopLoading({
            message: error.toString(),
            severity: "error",
          });
        } else stopLoading();
      }
    }
  }, [open]);

  const isDisabled = () => {
    return disabled || solicitacaoOrcamentos != null;
  };

  return (
    <Dialog open={open} onClose={() => close()}>
      <DialogTitle>Dados do fornecedor</DialogTitle>
      <DialogContent dividers>
        <FormControl sx={{ my: 2 }} fullWidth>
          <Select
            field="regiaoId"
            useFormRef={useFormRef}
            schema={schema}
            options={regioes}
            getOptionLabel={(option) => option.label}
            disabled={true}
          />
        </FormControl>
        <FormControl sx={{ my: 2 }} fullWidth>
          <Autocomplete
            multiple
            disableCloseOnSelect
            field="tecnologiaId"
            useFormRef={useFormRef}
            schema={schema}
            options={tecnologias}
            renderOption={(props, option, { selected }) => (
              <li {...props}>
                <MUICheckbox
                  icon={icon}
                  checkedIcon={checkedIcon}
                  style={{ marginRight: 8 }}
                  checked={selected}
                />
                {props.key}
              </li>
            )}
            groupBy={(option) =>
              "Categoria " +
              option.categoria +
              ": " +
              categorias
                .filter((categoria) => categoria.id == option.categoria)
                .map((label) => label.nome)
            }
          />
        </FormControl>

        <FileListField
          field="docsSolicitacao"
          disabled={isDisabled()}
          useFormRef={useFormRef}
          schema={schema}
          initialFiles={solicitacaoOrcamentos?.docsSolicitacao}
        />
      </DialogContent>
      <DialogActions>
        {loading > 0 && (
          <CircularProgress
            size={30}
            sx={{
              position: "absolute",
              left: 25,
              bottom: 12,
            }}
          />
        )}
        <Button color="inherit" onClick={() => close()}>
          CANCELAR
        </Button>
        <Button
          color="primary"
          onClick={handleSubmit(onSave)}
          disabled={isDisabled() || !isDirty}
        >
          SOLICITAR
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DialogoSolicitacaoOrcamentos;
