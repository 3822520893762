import React, { useState } from 'react';
import {
  Grid,
  FormControl,
  Divider,
  Button,
  Box,
  Paper,
  Tabs,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  DialogContentText,
  TextField as MUITextField
} from '@mui/material';
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";
import { pt } from 'yup-locale-pt';
import {
  DialogoConfirmacao,
  DialogoConfirmacaoSimples,
  DialogoMensagem,
  FileField,
  FileListField,
  Checkbox,
  TextField,
  FloatField,
  Timestamps,
  DadosBenchmarking
} from '../../components';
import PropTypes from 'prop-types';
import { useStoreState, useStoreActions } from 'easy-peasy';
import api from "../../services/api";
import { DialogoVisualizador } from '../../components';
import { driveBase } from '../../config';
import DadosTecnologiaProcesso from './DadosTecnologiaProcesso';
import { render, fireEvent, getByLabelText } from '@testing-library/react';
//import { useNavigate } from "react-router-dom";
yup.setLocale(pt);


const TabPanel = (props) => {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`impl-tabpanel-${index}`}
      aria-labelledby={`impl-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          {children}
        </Box>
      )}
    </div>
  );
};

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

const createDataResult = (ref, type, title) => {
  let field = ref.replace(/\./g, "_");
  let schema;
  switch (type) {
    case 'integer':
    default:
      schema = yup.number().positive().integer().nullable();
      break;
    case 'float':
      schema = yup.number().positive().float().nullable();
      break;
  }
  return {
    ref,
    field: field,
    schema: schema,
    type,
    title
  };
};

const results = [
  createDataResult('3.4', 'integer', 'Valor de economia com EE (KWh cumulativo-vida útil)'),
  createDataResult('3.5', 'float', 'Porcentagem de economia com EE (%)'),
  createDataResult('3.6', 'integer', 'Valor de redução de emissões de gases ' +
    'de efeituo estufa (T de CO2 cumulativo-vida útil)'),
  createDataResult('3.7', 'float', 'Porcentagem de redução de emissões de ' +
    'gases de efeito estufa (%)'),
  // createDataResult('3.8', 'float', 'VPL da economia de EE em relação à ' +
  //   'vida útil do investimento em EE (R$)'),
  createDataResult('2.6', 'float', 'Custo total do investimento em EE (R$)'),
  createDataResult('4.12', 'integer', 'Número de funcionários do sexo feminino'),
  createDataResult('4.13', 'integer', 'Número de funcionários do sexo masculino')
];

const Implementacao = () => {

  let currProcesso = useStoreState((state) => state.processo.current);
  let selectProcesso = useStoreActions((actions) => actions.processo.select);
  let startLoading = useStoreActions((actions) => actions.nav.startLoading);
  let stopLoading = useStoreActions((actions) => actions.nav.stopLoading);
  const [implementacao, setImplementacao] = React.useState(null);
  const [diagnostico, setDiagnostico] = React.useState(null);

  const [openMensagem, setOpenMensagem] = React.useState(false);

  // let navigate = useNavigate();

  let yupObj = {};
  for (let i = 0; i < results.length; i++) {
    yupObj['verificado_' + results[i].field] = results[i].schema;
  }

  const schema = yup.object({
    outrosDocsField: yup.array()
      .label('Arraste outros documentos aqui ou clique'),
    acoes: yup.boolean().label("Ações de divulgações realizadas"),
    pmeInvestiu: yup.boolean()
      .label("PME investiu pelo menos 50% do que é recomendado no " +
        "diagnóstico energético?"),
    planoId: yup.number().positive().nullable().label("Plano M&V"),
    relatorioId: yup.number().positive().nullable().label("Relatório M&V"),
    ...yupObj,
    benefsEco: yup.string().nullable()
      .label('Liste os benefícios econômicos (um por linha)'),
    benefsSoc: yup.string().nullable()
      .label('Liste os benefícios sociais (um por linha)'),
    benefsAmb: yup.string().nullable()
      .label('Liste os benefícios ambientais (um por linha)'),
    obsImpl: yup.string().nullable()
      .label('Liste suas observações (uma por linha)')
  }).required();

  const defaultValues = {
    outrosDocsField: [],
    planoId: null,
    relatorioId: null,
    verificado_3_4: null,
    verificado_3_5: null,
    verificado_3_6: null,
    verificado_3_7: null,
    verificado_2_6: null,
    verificado_4_12: null,
    verificado_4_13: null,
  };

  const useFormRef = useForm({
    mode: 'onTouched',
    resolver: yupResolver(schema),
    defaultValues: defaultValues
  });

  const { watch, setValue, resetField, getFieldState } = useFormRef;

  const watchVerificado34 = watch("verificado_3_4");
  const watchVerificado35 = watch("verificado_3_5");
  const watchVerificado36 = watch("verificado_3_6");
  const watchVerificado37 = watch("verificado_3_7");
  const watchVerificado26 = watch("verificado_2_6");
  const watchVerificado412 = watch("verificado_4_12");
  const watchVerificado413 = watch("verificado_4_13");


  const {
    handleSubmit,
    reset,
    getValues,
    formState: { isDirty, isSubmitting }
  } = useFormRef;

  const campoResult = (result, type) => {

    let field = type + '_' + result.field;

    if (type === "field") {
      return <MUITextField
        defaultValue={diagnostico[field]}
        disabled={true} />;
    }

    switch (result.type) {
      case 'float':
        return <FloatField field={field} disabled={disabled()}
          useFormRef={useFormRef} />;

      default:
      case 'integer':
        return <TextField field={field} disabled={disabled()}
          useFormRef={useFormRef} type="number" />;
    }
    return null;
  };

  const [aprovando, setAprovando] = React.useState([]);
  const [confirmacaoMsg, setConfirmacaoMsg] = React.useState([]);

  const verificaPreenchimento = () => {
    let erros = [];
    if (!getValues('acoes'))
      erros.push('Ações de divulgação');
    if (!getValues('pmeInvestiu'))
      erros.push('PME investiu pelo menos 50% do que é recomendado ' +
        'no diagnósitoco energético');
    if (!getValues('planoId'))
      erros.push('Inclusão do plano M&V');
    if (!getValues('resultadoId'))
      erros.push('Inclusão do resultado M&V');
    if (!getValues('verificado_3_4') ||
      !getValues('verificado_3_5') ||
      !getValues('verificado_3_6') ||
      !getValues('verificado_3_7') ||
      !getValues('verificado_2_6'))
      erros.push('Inclusão dos resultados');
    if (erros.length > 0)
      return <React.Fragment>
        <DialogContentText>
          Ainda não foram realizadas as seguintes ações:
        </DialogContentText>
        <ul>
          {erros.map((erro, index) => <li key={index}>
            <DialogContentText>
              {erro}
            </DialogContentText>
          </li>)}
        </ul>
      </React.Fragment>;
    return '';
  };

  const validavaloreconomia = () => {
    // console.log("validar valor economia-3-4 ", getValues('verificado_3_4'));
    // console.log("mui - 3-4 ", diagnostico.field_3_4);
    const esperadoco2 = parseInt(diagnostico.field_3_4);
    const esperadokwh = parseInt(getValues('verificado_3_4'));
    const result = esperadoco2 - esperadokwh;
    // console.log("resultado-3-4: ", result);
    return result > 0 ? false : true;
  };
  const validavalorco2 = () => {
    // console.log("validar co2 ", getValues('verificado_3_6'));
    // console.log("co2 ", diagnostico.field_3_6);
    const esperadoco2 = parseInt(diagnostico.field_3_6);
    const esperadokwh = parseInt(getValues('verificado_3_6'));
    const result = esperadoco2 - esperadokwh;
    // console.log("resultado-3-6: ", result);
    return result > 0 ? false : true;
  };
  const validadadospreenchidos = () => {
    let err = [];
    if (!getValues('acoes'))
      err.push('Ações de divulgação');
    if (!getValues('pmeInvestiu'))
      err.push('PME investiu pelo menos 50% do que é recomendado ' +
        'no diagnósitoco energético');
    if (!getValues('planoId'))
      err.push('Inclusão do plano M&V');
    if (!getValues('resultadoId'))
      err.push('Inclusão do resultado M&V');
    if (!getValues('verificado_3_4') ||
      !getValues('verificado_3_5') ||
      !getValues('verificado_3_6') ||
      !getValues('verificado_3_7') ||
      !getValues('verificado_2_6') ||
      !getValues('verificado_4_12') ||
      !getValues('verificado_4_13'))
      err.push('Inclusão dos resultados');
    //if (err.length > 0)
    return err.length;
    //return '';
  };

  const finalizaprocesso = (data) => {
    // const faltoupreenchimento = validadadospreenchidos();
    const valoreconomia = validavaloreconomia();
    const valorco2 = validavalorco2();


    // if(faltoupreenchimento !== 0)  {         
    //    console.log("faltou preenchimento processo: ", faltoupreenchimento);
    //   //verificado_3_4
    //   setOpenMensagem(true);

    // }
    // else{
    if (valoreconomia === true && valorco2 === true) {
      ////encerrar com sucesso
      aprova();
      //console.log("Finaliza com sucesso ");
    }
    else {
      // //encerrar sem sucesso
      recusa();
      //console.log("Finaliza sem sucesso ");
    }
    // }
  };

  const aprova = (data) => {
    setAprovando(true);
    setConfirmacaoMsg(
      <React.Fragment>
        {verificaPreenchimento()}
        <DialogContentText>
          Esta ação não pode ser desfeita.
          Ao finalizar você confirma que todos os dados inseridos no sistema estão corretos e de acordo com o que foi verificado no M&V do projeto. Deseja confirmar a finalização?
        </DialogContentText>
      </React.Fragment>
    );
    setOpenConfirmacao(true);
  };

  const recusa = () => {
    setAprovando(false);
    setConfirmacaoMsg(
      <React.Fragment>
        {verificaPreenchimento()}
        <DialogContentText>
          Esta ação não pode ser desfeita.
          Ao finalizar você confirma que todos os dados inseridos no sistema estão corretos e de acordo com o que foi verificado no M&V do projeto. Deseja confirmar a finalização?
        </DialogContentText>
      </React.Fragment>
    );
    setOpenConfirmacao(true);
  };

  const desfazerFim = () => {
    setOpenConfirmaDesfFim(true);
  };

  const salva = async (data) => {
    startLoading();
    try {
      let implementacaoData;
      if (data.id)
        implementacaoData = (await api.http.put('/implementacoes/' +
          data.id, data)).data;
      else {
        let post = (await api.http.post('/implementacoes', {
          ...data,
          processoId: currProcesso.id
        })).data;
        implementacaoData = post.implementacao;
        selectProcesso(post.processo);
      }

      stopLoading({ severity: 'success' });
      setImplementacao(implementacaoData);
      reset(implementacaoData);
    } catch (error) {
      stopLoading({
        message: error.toString(),
        severity: 'error'
      });
      throw error;
    };
  };

  const cancela = () => {
    reset();
  };

  const [openConfirmacao, setOpenConfirmacao] = React.useState(false);

  const onSimConfirma = async (data) => {
    setOpenConfirmacao(false);

    let estagio;
    if (diagnostico.modalidadeFinanciamento === 'F') {
      if (aprovando) {/* testar se o co2 e kwh est */
        estagio = 'confPrevIf';
      } else {
        estagio = 'abaiPrevIf';
      }
    } else if (diagnostico.modalidadeFinanciamento === 'R') {
      if (aprovando) {
        estagio = 'confPrevRP';
      } else {
        estagio = 'abaiPrevRP';
      }
    } if (diagnostico.modalidadeFinanciamento === 'B') {
      if (aprovando) {
        estagio = 'confPrevBOT';
      } else {
        estagio = 'abaiPrevBOT';
      }
    }

    const confirmaData = {
      ...data,
      estagio: estagio
    };
    startLoading();
    try {
      //console.log("confirmado", confirmaData);
      let post = (await api.http.put('/implementacoes/' + implementacao.id,
        confirmaData)).data;
      setImplementacao(post.implementacao);
      reset(post.implementacao);
      selectProcesso(post.processo);
      stopLoading({ severity: 'success' });
    } catch (error) {
      stopLoading({
        message: error.toString(),
        severity: 'error'
      });
      throw error;
    };
  };
  const onNaoConfirma = () => {
    setOpenConfirmacao(false);
  };

  const [openConfirmaDesfFim,
    setOpenConfirmaDesfFim] = React.useState(false);

  const onConfirmaDesfFim = async (data) => {
    setOpenConfirmaDesfFim(false);
    const confirmaData = {
      estagio: 'emImplementacao'
    };
    startLoading();
    try {
      let put = (await api.http.put('/implementacoes/' + implementacao.id,
        confirmaData)).data;
      setImplementacao(put.implementacao);
      reset(put.implementacao);
      selectProcesso(put.processo);
      stopLoading({ severity: 'success' });
    } catch (error) {
      stopLoading({
        message: error.toString(),
        severity: 'error'
      });
      throw error;
    };
  };

  const onNaoConfirmaDesfFim = () => {
    setOpenConfirmaDesfFim(false);
  };

  let user = useStoreState((state) => state.auth.user);
  const disabled = () => {
    return (user.roleId != 'superAdmin' &&
      !['emImplementacao',
        'projetoCadastrado'].includes(currProcesso.estagio) ||
      !['gestorGIZ',
        'superAdmin',
        'consultorEE',
        'validadorIndSENAI',
        'validadorIndGIZ',
        'gestorSENAI'].includes(user.roleId));
  };


  React.useEffect(async () => {
    startLoading();
    try {
      setDiagnostico((await api.http.get('/diagnosticos/' +
        currProcesso.id)).data);
    } catch (error) {
      if (!error.response || error.response.status != 404)
        throw error;
    }

    try {

      if (currProcesso.estagio != 'projetoCadastrado') {
        let implementacaoData = (await api.http.get('/implementacoes/' +
          currProcesso.id)).data;
        reset(implementacaoData);
        setImplementacao(implementacaoData);
      }
      stopLoading();
    } catch (error) {
      stopLoading({
        message: error.toString(),
        severity: 'error'
      });
    }
  }, []);

  const actionButtons = () => {
    if (isDirty || !implementacao ||
      !['emImplementacao',
        "confPrevBOT",
        "confPrevIf",
        "confPrevRP",
        "abaiPrevBOT",
        "abaiPrevIf",
        "abaiPrevRP"].includes(currProcesso.estagio)) {
      return (
        <React.Fragment>
          <Button variant="outlined" size="large" sx={{ mr: 2 }}
            onClick={cancela} disabled={!isDirty || isSubmitting}>
            CANCELAR
          </Button>
          <Button variant="contained" size="large"
            onClick={handleSubmit(salva)}
            disabled={!isDirty || isSubmitting}>
            SALVAR
          </Button>
        </React.Fragment>
      );
    } else {
      if (user.roleId == 'superAdmin' &&
        [
          "confPrevBOT",
          "confPrevIf",
          "confPrevRP",
          "abaiPrevBOT",
          "abaiPrevIf",
          "abaiPrevRP"].includes(currProcesso.estagio)) {
        return (
          <React.Fragment>
            <Button variant="outlined" size="large" sx={{ mr: 2 }}
              onClick={desfazerFim}>
              DESFAZER FINALIZAR {
                currProcesso.estagio === 'confPrevBOT' || 'confPrevIf' || 'confPrevRP' ?
                  'COM SUCESSO' : 'SEM SUCESSO'
              }
            </Button>
          </React.Fragment>
        );
      } else {
        return (
          <React.Fragment>
            <Button variant="contained" size="large"
              onClick={finalizaprocesso}
              disabled={!watchVerificado34
                || !watchVerificado35
                || !watchVerificado36
                || !watchVerificado37
                || !watchVerificado26
                || !watchVerificado412
                || !watchVerificado413
                || !diagnostico.field_3_4
                || !diagnostico.field_3_5
                || !diagnostico.field_3_6
                || !diagnostico.field_3_7
                || !diagnostico.field_2_6
                || !diagnostico.field_4_12
                || !diagnostico.field_4_13
              }
            >
              FINALIZAR PROJETO
            </Button>
          </React.Fragment>
        );
      }
    }
  };

  const [file, setFile] = React.useState(null);
  const [openDlgVis, setOpenDlgVis] = React.useState(false);

  const onCloseDlgVis = () => {
    setFile(null);
    setOpenDlgVis(false);
  };

  const openDoc = (arquivo) => {
    if (arquivo.mimeType == 'application/pdf' ||
      arquivo.mimeType == 'text/html' ||
      arquivo.indexOf('image') == 0) {
      setFile(arquivo);
      setOpenDlgVis(true);
    } else {
      window.location.href = driveBase + arquivo.fileRef;
    }
  };

  const [abaImpl, setAbaImpl] = React.useState(0);
  const handleTabChange = (event, newValue) => {
    setAbaImpl(newValue);
  };

  function a11yProps(index) {
    return {
      id: `impl-tab-${index}`,
      'aria-controls': `impl-tabpanel-${index}`,
    };
  }

  const [openDadosBenchmarking,
    setOpenDadosBenchmarking] = React.useState(false);

  const dadosBenchmarking = () => {
    setOpenDadosBenchmarking(true);
  };

  const onCloseDadosBenchmarking = () => {
    setOpenDadosBenchmarking(false);
  };

  const [openDadosTecnologiaProcesso,
    setOpenDadosTecnologiaProcesso] = React.useState(false);
  const tecnologiaProcesso = () => {
    setOpenDadosTecnologiaProcesso(true);
  };

  const onOkMensagem = () => {
    // navigate("/", { replace: false });
    setOpenMensagem(false);
  };

  const onCloseTecnologiaProcesso = () => {
    setOpenDadosTecnologiaProcesso(false);
  };
  return (
    <Paper variant="outlined" className='center-box' sx={{
      textAlign: 'center',
      px: 4,
      pt: 2,
      pb: 3,
      maxWidth: 'md',
    }}>
      <DialogoMensagem title="Sucesso" open={openMensagem} onOk={onOkMensagem}>
        Para "Finalizar Projeto" todos os resultados "verificados" precisam ser informados.
      </DialogoMensagem>
      <DialogoConfirmacao
        title="Atenção"
        open={openConfirmacao} onSim={onSimConfirma} onNao={onNaoConfirma}>
        {confirmacaoMsg}
      </DialogoConfirmacao>
      <DialogoConfirmacaoSimples
        title="Atenção"
        open={openConfirmaDesfFim}
        onSim={onConfirmaDesfFim}
        onNao={onNaoConfirmaDesfFim} >
        Tem certeza que deseja desfazer finalização {
          currProcesso.estagio === 'confPrevBOT' || 'confPrevIf' || 'confPrevRP' ?
            'com sucesso' : 'sem sucesso'
        }?
      </DialogoConfirmacaoSimples>
      <DadosBenchmarking
        processo={currProcesso}
        final={true}
        open={openDadosBenchmarking}
        onClose={onCloseDadosBenchmarking}
        disabled={disabled()}
      />
      <DadosTecnologiaProcesso
        processo={currProcesso}
        open={openDadosTecnologiaProcesso}
        onClose={onCloseTecnologiaProcesso}
        disabled={disabled()}
      />

      <Tabs
        value={abaImpl}
        onChange={handleTabChange}
        sx={{ borderRight: 1, borderColor: 'divider' }} >
        <Tab label="Documentos" {...a11yProps(0)} />
        <Tab label="Resultados" {...a11yProps(1)} />
        <Tab label="Benefícios Indiretos" {...a11yProps(2)} />
        <Tab label="Observações" {...a11yProps(3)} />
      </Tabs>

      {/* Documentos */}
      <TabPanel value={abaImpl} index={0} className="tabpanel">
        <FileListField
          sx={{ mb: 2, border: '2px dashed grey' }}
          field="outrosDocs" disabled={disabled()}
          useFormRef={useFormRef} schema={schema}
          initialFiles={implementacao?.outrosDocs} />
      </TabPanel>

      {/* Resultados */}
      <TabPanel value={abaImpl} index={1} className="tabpanel">
        <Box>
          <Button variant="outlined" onClick={dadosBenchmarking} sx={{ marginRight: 2 }}>
            DADOS DE BENCHMARKING
          </Button>
          <Button variant="outlined" onClick={tecnologiaProcesso}>
            CATEGORIAS DE TECNOLOGIAS
          </Button>
        </Box>
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell align="center"><h3>Esperado</h3></TableCell>
                <TableCell align="center"></TableCell>
                <TableCell align="center"><h3>Verificado</h3></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell align="center">
                  <FileField field="planoId" disabled={disabled()}
                    fileData={implementacao?.plano}
                    useFormRef={useFormRef} schema={schema} />
                </TableCell>
                <TableCell></TableCell>
                <TableCell>
                  <FileField field="relatorioId" disabled={disabled()}
                    fileData={implementacao?.relatorio}
                    useFormRef={useFormRef} schema={schema} />
                </TableCell>
              </TableRow>
              {results.map((result) => (
                <TableRow
                  key={result.field}
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                >
                  <TableCell align="center">
                    {diagnostico && campoResult(result, 'field')}
                  </TableCell>
                  <TableCell align="center">
                    {result.title}
                  </TableCell>
                  <TableCell align="center">
                    {campoResult(result, 'verificado')}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </TabPanel>

      {/* Benefícios Indiretos */}
      <TabPanel value={abaImpl} index={2} className="tabpanel">
        <FormControl sx={{ my: 2 }} fullWidth>
          <TextField field="benefsEco" multiline rows={12}
            useFormRef={useFormRef}
            schema={schema} disabled={disabled()} />
        </FormControl>
        <FormControl sx={{ my: 2 }} fullWidth>
          <TextField field="benefsSoc" multiline rows={12}
            useFormRef={useFormRef}
            schema={schema} disabled={disabled()} />
        </FormControl>
        <FormControl sx={{ my: 2 }} fullWidth>
          <TextField field="benefsAmb" multiline rows={12}
            useFormRef={useFormRef}
            schema={schema} disabled={disabled()} />
        </FormControl>
      </TabPanel>

      {/* Observações */}
      <TabPanel value={abaImpl} index={3} className="tabpanel">
        <FormControl sx={{ my: 2 }} fullWidth>
          <TextField field="obsImpl" multiline rows={12}
            useFormRef={useFormRef}
            schema={schema} disabled={disabled()} />
        </FormControl>
        {implementacao?.id && currProcesso &&
          !['emImplementacao',
            'projetoIniciado'].includes(currProcesso.estagio) &&
          <React.Fragment>
            <FormControl sx={{ my: 2 }} fullWidth>
              <MUITextField
                label={"Observações de finalização " +
                  (currProcesso.estagio === 'confPrevBOT' || 'confPrevIf' || 'confPrevRP' ?
                    'com sucesso' : 'sem sucesso')}
                value={implementacao?.observacoes} multiline rows={4}
                disabled={true} />
            </FormControl>
          </React.Fragment>
        }
      </TabPanel>

      <Box sx={{ mt: 2 }}>
        <Checkbox field="acoes" disabled={disabled()}
          schema={schema} useFormRef={useFormRef} />
        <Checkbox field="pmeInvestiu" disabled={disabled()}
          schema={schema} useFormRef={useFormRef} />
      </Box>

      <Timestamps dados={implementacao} />

      {!disabled() &&
        <Box sx={{ mt: 3, textAlign: 'center' }}>
          {actionButtons()}
        </Box>
      }
    </Paper>
  );
};

export default Implementacao;
