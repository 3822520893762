import * as React from "react";
import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Button,
  CircularProgress,
  Checkbox,
  TextField as MUITextField,
} from "@mui/material";
import { Select, Autocomplete, TextField, FloatField, DateField } from "./";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { pt } from "yup-locale-pt";
import { useStoreState } from "easy-peasy";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;
yup.setLocale(pt);

const DialogoDados = ({
  title,
  rows,
  open,
  onSave,
  onCancel,
  defaultValues,
  disabled,
}) => {
  let loading = useStoreState((state) => state.nav.loading);

  let yupObj = {};
  for (let i = 0; i < rows.length; i++) {
    rows[i].field = "field_" + rows[i].ref.replace(/\./g, "_");
    if (rows[i].schema) yupObj[rows[i].field] = rows[i].schema;
  }
  const schema = yup.object(yupObj).required();
  const useFormRef = useForm({
    mode: "onTouched",
    resolver: yupResolver(schema),
    defaultValues: defaultValues,
  });
  const {
    handleSubmit,
    reset,
    formState: { isDirty, isSubmitting },
  } = useFormRef;

  const campo = (row) => {
    switch (row.type) {
      case "text":
      default:
        return (
          <TextField
            field={row.field}
            useFormRef={useFormRef}
            disabled={disabled}
            fullWidth
          />
        );
      case "integer":
        if (row.extra && row.extra.readOnly) {
          return (
            <MUITextField
              value={defaultValues[row.extra.field]}
              disabled={true}
              type="number"
              fullWidth
            />
          );
        }
        return (
          <TextField
            field={row.field}
            useFormRef={useFormRef}
            disabled={disabled}
            type="number"
            fullWidth
          />
        );
      case "float":
        return (
          <FloatField
            field={row.field}
            useFormRef={useFormRef}
            disabled={disabled}
            fullWidth
          />
        );
      case "select":
        return (
          <Select
            field={row.field}
            useFormRef={useFormRef}
            options={row.extra.options}
            groupBy={row.extra.groupBy}
            disabled={disabled}
            fullWidth
          />
        );
      case "selectMultiple":
        return (
          <Autocomplete
            multiple
            disableCloseOnSelect
            field={row.field}
            useFormRef={useFormRef}
            options={row.extra.options}
            disabled={disabled}
            renderOption={(props, option, { selected }) => (
              <li {...props}>
                <Checkbox
                  icon={icon}
                  checkedIcon={checkedIcon}
                  style={{ marginRight: 8 }}
                  checked={selected}
                />
                {props.key}
              </li>
            )}
          />
        );
      case "date":
        return (
          <DateField
            field={row.field}
            useFormRef={useFormRef}
            disabled={disabled}
            fullWidth
          />
        );
    }
  };

  const handleCancel = () => {
    reset(defaultValues);
    onCancel();
  };

  return (
    <Dialog open={open} maxWidth={"lg"}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent dividers>
        <form onSubmit={handleSubmit(onSave)}>
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell align="left">Referência</TableCell>
                  <TableCell align="left">Título</TableCell>
                  <TableCell align="left">Valor</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {rows.map((row) => (
                  <TableRow
                    key={row.field}
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell component="th" scope="row">
                      {row.ref}
                    </TableCell>
                    <TableCell align="left">{row.titulo}</TableCell>
                    <TableCell sx={{ maxWidth: 480 }}>{campo(row)}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </form>
      </DialogContent>
      <DialogActions>
        {loading > 0 && (
          <CircularProgress
            size={30}
            sx={{
              position: "absolute",
              left: 25,
              bottom: 12,
            }}
          />
        )}
        <Button onClick={handleCancel} color="inherit" disabled={isSubmitting}>
          CANCELAR
        </Button>
        <Button
          onClick={handleSubmit(onSave)}
          disabled={disabled || !isDirty || isSubmitting}
        >
          SALVAR
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DialogoDados;
