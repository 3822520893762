import React from "react";
import api from "../../services/api";
import { saveAs } from "file-saver";
import { Button } from "@mui/material";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import { useStoreActions } from "easy-peasy";
import * as Excel from "exceljs";

function createFormattedCell(cell, text) {
  cell.value = text;
  cell.border = {
    top: { style: "thin" },
    left: { style: "thin" },
    bottom: { style: "thin" },
    right: { style: "thin" },
  };
}

function generateSheetRow(worksheet, rowData, index) {
  createFormattedCell(worksheet.getRow(index).getCell(1), rowData.razao_social);
  createFormattedCell(worksheet.getRow(index).getCell(2), rowData.porte);
  createFormattedCell(
    worksheet.getRow(index).getCell(3),
    rowData.categorias_tecnologicas_a
  );
  createFormattedCell(
    worksheet.getRow(index).getCell(4),
    rowData.categorias_tecnologicas_b
  );
  createFormattedCell(
    worksheet.getRow(index).getCell(5),
    rowData.categorias_tecnologicas_c
  );
  createFormattedCell(worksheet.getRow(index).getCell(6), rowData.estagio);
  createFormattedCell(
    worksheet.getRow(index).getCell(7),
    rowData.motivo_nao_implementacao
  );
  createFormattedCell(worksheet.getRow(index).getCell(8), rowData.especialista);

  createFormattedCell(worksheet.getRow(index).getCell(9), rowData.d_n_fem);
  createFormattedCell(worksheet.getRow(index).getCell(10), rowData.d_n_masc);
  createFormattedCell(
    worksheet.getRow(index).getCell(11),
    rowData.d_econ_energia
  );
  createFormattedCell(worksheet.getRow(index).getCell(12), rowData.d_co2_cum);
  createFormattedCell(
    worksheet.getRow(index).getCell(13),
    rowData.d_tot_invest
  );
  createFormattedCell(worksheet.getRow(index).getCell(14), rowData.d_vpl);
  createFormattedCell(
    worksheet.getRow(index).getCell(15),
    rowData.mv_econ_energia
  );
  createFormattedCell(worksheet.getRow(index).getCell(16), rowData.mv_co2_cum);
  createFormattedCell(worksheet.getRow(index).getCell(17), rowData.mv_vpl);
  createFormattedCell(worksheet.getRow(index).getCell(18), rowData.mv_n_fem);
  createFormattedCell(worksheet.getRow(index).getCell(19), rowData.mv_n_masc);
  createFormattedCell(
    worksheet.getRow(index).getCell(20),
    rowData.energia_diferenca
  );
  createFormattedCell(
    worksheet.getRow(index).getCell(21),
    rowData.energia_porcentagem
  );
  createFormattedCell(
    worksheet.getRow(index).getCell(22),
    rowData.energia_cenario
  );
  createFormattedCell(
    worksheet.getRow(index).getCell(23),
    rowData.co2_diferenca
  );
  createFormattedCell(
    worksheet.getRow(index).getCell(24),
    rowData.co2_porcentagem
  );
  createFormattedCell(worksheet.getRow(index).getCell(25), rowData.co2_cenario);
  createFormattedCell(worksheet.getRow(index).getCell(26), rowData.cnpj);
}

function generateSummary(worksheet, summaryData, lastRowIndex) {
  lastRowIndex += 3;
  worksheet.mergeCells(`A${lastRowIndex}:I${lastRowIndex}`);
  let summaryTitle = worksheet.getRow(lastRowIndex);
  createFormattedCell(summaryTitle.getCell(1), "Estatísticas");
  summaryTitle.getCell(1).alignment = {
    vertical: "middle",
    horizontal: "center",
  };

  let summaryHeader1 = worksheet.getRow(++lastRowIndex);
  worksheet.mergeCells(`B${lastRowIndex}:E${lastRowIndex}`);
  worksheet.mergeCells(`F${lastRowIndex}:I${lastRowIndex}`);
  createFormattedCell(summaryHeader1.getCell(1), "");
  createFormattedCell(summaryHeader1.getCell(2), "Diagnóstico");
  summaryHeader1.getCell(2).alignment = {
    vertical: "middle",
    horizontal: "center",
  };
  createFormattedCell(summaryHeader1.getCell(6), "M&V");
  summaryHeader1.getCell(6).alignment = {
    vertical: "middle",
    horizontal: "center",
  };

  let summaryHeader2 = worksheet.getRow(++lastRowIndex);
  createFormattedCell(summaryHeader1.getCell(1), "");
  createFormattedCell(summaryHeader2.getCell(2), "Min");
  summaryHeader2.getCell(2).alignment = {
    vertical: "middle",
    horizontal: "center",
  };
  createFormattedCell(summaryHeader2.getCell(3), "Max");
  summaryHeader2.getCell(3).alignment = {
    vertical: "middle",
    horizontal: "center",
  };
  createFormattedCell(summaryHeader2.getCell(4), "Média");
  summaryHeader2.getCell(4).alignment = {
    vertical: "middle",
    horizontal: "center",
  };
  createFormattedCell(summaryHeader2.getCell(5), "Total");
  summaryHeader2.getCell(5).alignment = {
    vertical: "middle",
    horizontal: "center",
  };
  createFormattedCell(summaryHeader2.getCell(6), "Min");
  summaryHeader2.getCell(6).alignment = {
    vertical: "middle",
    horizontal: "center",
  };
  createFormattedCell(summaryHeader2.getCell(7), "Max");
  summaryHeader2.getCell(7).alignment = {
    vertical: "middle",
    horizontal: "center",
  };
  createFormattedCell(summaryHeader2.getCell(8), "Média");
  summaryHeader2.getCell(8).alignment = {
    vertical: "middle",
    horizontal: "center",
  };
  createFormattedCell(summaryHeader2.getCell(9), "Total");
  summaryHeader2.getCell(9).alignment = {
    vertical: "middle",
    horizontal: "center",
  };

  let econEnergiaRow = worksheet.getRow(++lastRowIndex);
  createFormattedCell(
    econEnergiaRow.getCell(1),
    "Economia com EE (KWh cumulativo-vida útil)"
  );
  createFormattedCell(econEnergiaRow.getCell(2), summaryData.minEcon);
  createFormattedCell(econEnergiaRow.getCell(3), summaryData.maxEcon);
  createFormattedCell(
    econEnergiaRow.getCell(4),
    summaryData.econQtd > 0
      ? summaryData.econTotal / summaryData.econQtd
      : "N/A"
  );
  econEnergiaRow.getCell(4).numFmt = "#,##0.00";
  createFormattedCell(econEnergiaRow.getCell(5), summaryData.econTotal);
  createFormattedCell(econEnergiaRow.getCell(6), summaryData.minEconMV);
  createFormattedCell(econEnergiaRow.getCell(7), summaryData.maxEconMV);
  createFormattedCell(
    econEnergiaRow.getCell(8),
    summaryData.econMVQtd > 0
      ? summaryData.econMVTotal / summaryData.econMVQtd
      : "N/A"
  );
  econEnergiaRow.getCell(8).numFmt = "#,##0.00";
  createFormattedCell(econEnergiaRow.getCell(9), summaryData.econMVTotal);

  let co2Row = worksheet.getRow(++lastRowIndex);
  createFormattedCell(
    co2Row.getCell(1),
    "Redução de GEE (t de CO2 cumulativo-vida útil)"
  );
  createFormattedCell(co2Row.getCell(2), summaryData.minCO2);
  createFormattedCell(co2Row.getCell(3), summaryData.maxCO2);
  createFormattedCell(
    co2Row.getCell(4),
    summaryData.CO2Qtd > 0 ? summaryData.CO2Total / summaryData.CO2Qtd : "N/A"
  );
  co2Row.getCell(4).numFmt = "#,##0.00";
  createFormattedCell(co2Row.getCell(5), summaryData.CO2Total);
  createFormattedCell(co2Row.getCell(6), summaryData.minCO2MV);
  createFormattedCell(co2Row.getCell(7), summaryData.maxCO2MV);
  createFormattedCell(
    co2Row.getCell(8),
    summaryData.CO2MVQtd > 0
      ? summaryData.CO2MVTotal / summaryData.CO2MVQtd
      : "N/A"
  );
  co2Row.getCell(8).numFmt = "#,##0.00";

  createFormattedCell(co2Row.getCell(9), summaryData.CO2MVTotal);

  let investRow = worksheet.getRow(++lastRowIndex);
  createFormattedCell(
    investRow.getCell(1),
    "Custo Total do Investimento em EE"
  );
  createFormattedCell(investRow.getCell(2), summaryData.minInvest);
  investRow.getCell(2).numFmt = '"R$ "#,##0.00;[Red]-"R$ "#,##0.00';
  createFormattedCell(investRow.getCell(3), summaryData.maxInvest);
  investRow.getCell(3).numFmt = '"R$ "#,##0.00;[Red]-"R$ "#,##0.00';
  createFormattedCell(
    investRow.getCell(4),
    summaryData.InvestQtd > 0
      ? summaryData.InvestTotal / summaryData.InvestQtd
      : "N/A"
  );
  investRow.getCell(4).numFmt = '"R$ "#,##0.00;[Red]-"R$ "#,##0.00';
  createFormattedCell(investRow.getCell(5), summaryData.InvestTotal);
  investRow.getCell(5).numFmt = '"R$ "#,##0.00;[Red]-"R$ "#,##0.00';
  createFormattedCell(investRow.getCell(6), "N/A");
  createFormattedCell(investRow.getCell(7), "N/A");
  createFormattedCell(investRow.getCell(8), "N/A");
  createFormattedCell(investRow.getCell(9), "N/A");
}

const ExportacaoDadosRelatorioEnergetico = () => {
  let startLoading = useStoreActions((actions) => actions.nav.startLoading);
  let stopLoading = useStoreActions((actions) => actions.nav.stopLoading);

  const generateDocument = async () => {
    startLoading();
    try {
      let dados = (
        await api.http.get("/relatorios/exportacao-dados-diagnostico")
      ).data;

      if (!dados || dados.length === 0) {
        stopLoading({
          message: "Nenhum registro encontrado.",
          severity: "error",
        });
        return;
      }

      const workbook = new Excel.Workbook();
      workbook.created = new Date();
      workbook.modified = new Date();

      const worksheet = workbook.addWorksheet("principal");

      worksheet.mergeCells("A1:A3");
      worksheet.mergeCells("B1:B3");
      worksheet.mergeCells("C1:E2");
      worksheet.mergeCells("F1:F3");
      worksheet.mergeCells("G1:G3");
      worksheet.mergeCells("H1:H3");

      worksheet.mergeCells("I1:N2");

      worksheet.mergeCells("O1:S2");

      worksheet.mergeCells("T1:Y1");
      worksheet.mergeCells("T2:V2");
      worksheet.mergeCells("W2:Y2");

      worksheet.mergeCells("Z1:Z3");

      const headerRow = worksheet.getRow(1);
      headerRow.alignment = { vertical: "middle", horizontal: "center" };
      createFormattedCell(headerRow.getCell(1), "PME");
      worksheet.getColumn("A").width = 30;
      createFormattedCell(headerRow.getCell(2), "Porte");
      worksheet.getColumn("B").width = 12;
      createFormattedCell(headerRow.getCell(3), "Categorias Tecnológicas 2.4");
      worksheet.getColumn("C").width = 30;

      createFormattedCell(headerRow.getCell(6), "Estágio");
      worksheet.getColumn("D").width = 18;
      createFormattedCell(headerRow.getCell(7), "Motivo Não Implementação");
      worksheet.getColumn("E").width = 12;
      createFormattedCell(headerRow.getCell(8), "Especialista");
      worksheet.getColumn("F").width = 18;

      createFormattedCell(headerRow.getCell(9), "Ex-ante (Diagnóstico)");
      createFormattedCell(headerRow.getCell(16), "Ex-post (M&V)");
      createFormattedCell(headerRow.getCell(21), "Comparação");

      const headerRow2 = worksheet.getRow(2);
      headerRow2.alignment = { vertical: "middle", horizontal: "center" };
      createFormattedCell(headerRow2.getCell(21), "Energia");
      createFormattedCell(headerRow2.getCell(24), "CO2");

      const headerRow3 = worksheet.getRow(3);
      headerRow3.alignment = { vertical: "middle", horizontal: "center" };

      createFormattedCell(headerRow3.getCell(3), "A: Intervenções Térmicas");
      worksheet.getColumn("C").width = 18;

      createFormattedCell(
        headerRow3.getCell(4),
        "B: Controles e Intervenções de Geren. Ener."
      );
      worksheet.getColumn("D").width = 18;

      createFormattedCell(headerRow3.getCell(5), "C: Intervenções Elétricas");
      worksheet.getColumn("E").width = 18;

      createFormattedCell(headerRow3.getCell(9), "N de func. Fem 4.12");
      worksheet.getColumn("G").width = 18;

      createFormattedCell(headerRow3.getCell(10), "N de func. Masc 4.13");
      worksheet.getColumn("H").width = 18;

      createFormattedCell(
        headerRow3.getCell(11),
        "Economia de energia kWh cumulativo 3.4"
      );
      worksheet.getColumn("I").width = 18;

      createFormattedCell(headerRow3.getCell(12), "CO2 cumulativo 3.6");
      worksheet.getColumn("J").width = 18;

      createFormattedCell(
        headerRow3.getCell(13),
        "Valor total de investimento 2.6"
      );
      worksheet.getColumn("M").numFmt = '"R$ "#,##0.00;[Red]-"R$ "#,##0.00';
      worksheet.getColumn("K").width = 18;

      createFormattedCell(headerRow3.getCell(14), "VPL 3.8");
      worksheet.getColumn("L").width = 18;

      createFormattedCell(
        headerRow3.getCell(15),
        "Economia de energia kWh cumulativo (M&V)"
      );
      worksheet.getColumn("M").width = 18;

      createFormattedCell(headerRow3.getCell(16), "CO2 cumulativo (M&V)");
      worksheet.getColumn("N").width = 18;

      createFormattedCell(headerRow3.getCell(17), "VPL (M&V)");
      worksheet.getColumn("O").width = 18;

      createFormattedCell(headerRow3.getCell(18), "N de func. Fem M&V");
      worksheet.getColumn("P").width = 18;

      createFormattedCell(headerRow3.getCell(19), "N de func. Masc M&V");
      worksheet.getColumn("Q").width = 18;

      createFormattedCell(headerRow3.getCell(20), "Diferença");
      worksheet.getColumn("R").width = 18;

      createFormattedCell(headerRow3.getCell(21), "%");
      worksheet.getColumn("S").width = 18;

      createFormattedCell(headerRow3.getCell(22), "Resultado");
      worksheet.getColumn("T").width = 18;

      createFormattedCell(headerRow3.getCell(23), "Diferença");
      worksheet.getColumn("U").width = 18;

      createFormattedCell(headerRow3.getCell(24), "%");
      worksheet.getColumn("V").width = 18;

      createFormattedCell(headerRow3.getCell(25), "Resultado");
      worksheet.getColumn("W").width = 18;

      createFormattedCell(headerRow3.getCell(26), "CNPJ");
      worksheet.getColumn("Z").width = 18;

      let summaryData = {
        minEcon: null,
        maxEcon: null,
        econTotal: 0,
        econQtd: 0,
        minCO2: null,
        maxCO2: null,
        CO2Total: 0,
        CO2Qtd: 0,
        minInvest: null,
        maxInvest: null,
        InvestTotal: 0,
        InvestQtd: 0,
        minEconMV: null,
        maxEconMV: null,
        econMVTotal: 0,
        econMVQtd: 0,
        minCO2MV: null,
        maxCO2MV: null,
        CO2MVTotal: 0,
        CO2MVQtd: 0,
      };
      let lastRowIndex = 0;
      for (; lastRowIndex < dados.length; lastRowIndex++) {
        generateSheetRow(worksheet, dados[lastRowIndex], lastRowIndex + 4);
        if (
          summaryData.minEcon == null ||
          (dados[lastRowIndex].d_econ_energia !== null &&
            dados[lastRowIndex].d_econ_energia < summaryData.minEcon)
        ) {
          summaryData.minEcon = dados[lastRowIndex].d_econ_energia;
        }

        if (
          summaryData.maxEcon == null ||
          dados[lastRowIndex].d_econ_energia > summaryData.maxEcon
        ) {
          summaryData.maxEcon = dados[lastRowIndex].d_econ_energia;
        }
        if (dados[lastRowIndex].d_econ_energia != null) {
          summaryData.econQtd++;
          summaryData.econTotal += dados[lastRowIndex].d_econ_energia;
        }

        if (
          summaryData.minCO2 == null ||
          (dados[lastRowIndex].d_co2_cum !== null &&
            dados[lastRowIndex].d_co2_cum < summaryData.minCO2)
        ) {
          summaryData.minCO2 = dados[lastRowIndex].d_co2_cum;
        }

        if (
          summaryData.maxCO2 == null ||
          dados[lastRowIndex].d_co2_cum > summaryData.maxCO2
        ) {
          summaryData.maxCO2 = dados[lastRowIndex].d_co2_cum;
        }

        if (dados[lastRowIndex].d_co2_cum != null) {
          summaryData.CO2Qtd++;
          summaryData.CO2Total += dados[lastRowIndex].d_co2_cum;
        }

        if (
          summaryData.minInvest == null ||
          (dados[lastRowIndex].d_tot_invest !== null &&
            dados[lastRowIndex].d_tot_invest < summaryData.minInvest)
        ) {
          summaryData.minInvest = dados[lastRowIndex].d_tot_invest;
        }

        if (
          summaryData.maxInvest == null ||
          dados[lastRowIndex].d_tot_invest > summaryData.maxInvest
        ) {
          summaryData.maxInvest = dados[lastRowIndex].d_tot_invest;
        }

        if (dados[lastRowIndex].d_tot_invest != null) {
          summaryData.InvestQtd++;
          summaryData.InvestTotal += dados[lastRowIndex].d_tot_invest;
        }

        if (
          summaryData.minEconMV == null ||
          (dados[lastRowIndex].mv_econ_energia !== null &&
            dados[lastRowIndex].mv_econ_energia < summaryData.minEconMV)
        ) {
          summaryData.minEconMV = dados[lastRowIndex].mv_econ_energia;
        }

        if (
          summaryData.maxEconMV == null ||
          dados[lastRowIndex].mv_econ_energia > summaryData.maxEconMV
        ) {
          summaryData.maxEconMV = dados[lastRowIndex].mv_econ_energia;
        }

        if (dados[lastRowIndex].mv_econ_energia != null) {
          summaryData.econMVQtd++;
          summaryData.econMVTotal += dados[lastRowIndex].mv_econ_energia;
        }

        if (
          summaryData.minCO2MV == null ||
          (dados[lastRowIndex].mv_co2_cum !== null &&
            dados[lastRowIndex].mv_co2_cum < summaryData.minCO2MV)
        ) {
          summaryData.minCO2MV = dados[lastRowIndex].mv_co2_cum;
        }

        if (
          summaryData.maxCO2MV == null ||
          dados[lastRowIndex].mv_co2_cum > summaryData.maxCO2MV
        ) {
          summaryData.maxCO2MV = dados[lastRowIndex].mv_co2_cum;
        }

        if (dados[lastRowIndex].mv_co2_cum) {
          summaryData.CO2MVQtd++;
          summaryData.CO2MVTotal += dados[lastRowIndex].mv_co2_cum;
        }
       
      }

      generateSummary(worksheet, summaryData, lastRowIndex + 4);

      const buffer = await workbook.xlsx.writeBuffer();
      saveAs(
        new Blob([buffer], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        }),
        "export-dados-diag-energetico-" + new Date().toISOString() + ".xlsx"
      );
      stopLoading();
    } catch (error) {
      stopLoading({
        message: error.toString(),
        severity: "error",
      });
    }
  };

  return (
    <>
      <Button
        variant="contained"
        onClick={generateDocument}
        sx={{ height: 45 }}
      >
        <FileDownloadIcon /> EXPORTAÇÃO DOS DADOS DE DIAGNÓSTICO ENERGÉTICO
        (XLSX)
      </Button>
    </>
  );
};

export default ExportacaoDadosRelatorioEnergetico;
