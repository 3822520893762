import * as React from 'react';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  CircularProgress,
  Button,
  Tooltip,
  TextField
} from '@mui/material';
import ptBRLocale from 'date-fns/locale/pt-BR';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import PropTypes from 'prop-types';
import CloseIcon from '@mui/icons-material/Close';
import api from "../../services/api";
import {
  listaEnergeticos,
  subsegmentos,
  listaProcessos,
  sistemas,
  textoExAnte,
  textoExPost
} from '../../static';
import { useStoreState, useStoreActions } from 'easy-peasy';
import MaterialTable from '@material-table/core';
import {
  materialTableLocalization,
  materialTableCurrencySettings
} from '../../config';

const BootstrapDialogTitle = (props) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};
BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

const DialogoTargets = ({open, onClose}) => {
  let loading = useStoreState((state) => state.nav.loading);
  let startLoading = useStoreActions((actions) => actions.nav.startLoading);
  let stopLoading = useStoreActions((actions) => actions.nav.stopLoading);

  const close = () => {
    onClose();
  };

  const [columns, setColumns] = React.useState([]);
  const [rows, setRows] = React.useState([]);
  const [newRows, setNewRows] = React.useState([]);

  React.useEffect(() => {
    if(newRows && newRows.length>0) {
      setRows([...newRows]);
    }
  },[newRows]);

  React.useEffect(() => {
    let mount = {
      isMounted: true
    };
    loadDados(mount);
    return ()=>{mount.isMounted = false;};
  },[]);

  const loadDados = async (mount) => {
    await Promise.all([
      loadColumns(mount),
      loadCalculos(),
      loadTargets()
    ]);
    loadRows(mount);
  };

  const allSistemas = Object.keys(sistemas).reduce((map,processo)=>{
    map = sistemas[processo].reduce((map2,sistema)=>{
      map2[sistema.id] = sistema.label;
      return map2;
    },map);
    return map;
  },{
    0: 'Planta'
  });

  let setoresAtuacao = null;
  let calculosRefs = null;
  let targetsRefs = null;

  const loadCalculos = async (mount) => {
    startLoading();
    try {
      let calculos = (await api.http.get('/calculos-benchmarkings')).data;
      calculosRefs = calculos.reduce((map,calculo)=>{
        if(!map[calculo.subsegmento]) {
          map[calculo.subsegmento] = {};
        }
        if(!map[calculo.subsegmento][calculo.sistema]) {
          map[calculo.subsegmento][calculo.sistema] = {};
        }
        if(!map[calculo.subsegmento][calculo.sistema][calculo.final]) {
          map[calculo.subsegmento][calculo.sistema][calculo.final] = calculo.benchmarking;
        }
        return map;
      },{});
      stopLoading();
    } catch(error) {
      stopLoading({
        message: error.toString(),
        severity: 'error'
      });
      throw error;
    }
  };

  const loadTargets = async (mount) => {
    startLoading();
    try {
      let targets = (await api.http.get('/targets-benchmarkings')).data;
      targetsRefs = targets.reduce((map,target)=>{
        if(!map[target.subsegmento]) {
          map[target.subsegmento] = {};
        }
        if(!map[target.subsegmento][target.sistema]) {
          map[target.subsegmento][target.sistema] = {
            id: target.id,
            target: target.target,
            prazo: target.prazo
          };
        }
        return map;
      },{});
      stopLoading();
    } catch(error) {
      stopLoading({
        message: error.toString(),
        severity: 'error'
      });
      throw error;
    }
  };

  const loadRows = (mount) => {
    let rows = [];
    for(let i in setoresAtuacao) {
      for(let j in subsegmentos[setoresAtuacao[i].id]) {
        let subsegmento = subsegmentos[setoresAtuacao[i].id][j];
        for(let k in allSistemas) {
          //processos
          let sistema = allSistemas[k];
          let row = {};
          row['id'] = setoresAtuacao[i].id+'-'+subsegmento.id+'-'+k;
          row['setorAtuacao'] = setoresAtuacao[i].id;
          row['subsegmento'] = subsegmento.id;
          row['sistema'] = k;
          if(subsegmento.id in calculosRefs &&
             k in calculosRefs[subsegmento.id]) {
            if(0 in calculosRefs[subsegmento.id][k])
              row['benchmarkingExAnte'] = calculosRefs[subsegmento.id][k][0];
            if(1 in calculosRefs[subsegmento.id][k])
              row['benchmarkingExPost'] = calculosRefs[subsegmento.id][k][1];
          }
          if(subsegmento.id in targetsRefs &&
             k in targetsRefs[subsegmento.id]) {
            row['targetId'] = targetsRefs[subsegmento.id][k].id;
            row['target'] = targetsRefs[subsegmento.id][k].target;
            row['prazo'] = targetsRefs[subsegmento.id][k].prazo;
          }
          rows.push(row);
        }
      }
    }

    if(mount.isMounted)
      setRows(rows);
  };


  const loadColumns = async(mount) =>{
    startLoading();
    try {
      setoresAtuacao = (await api.http.get('/setores-atuacao')).data;
      if(mount.isMounted) {
        setColumns([{
          field: 'setorAtuacao', title: 'Segmento',
          lookup: setoresAtuacao.reduce((map,setorAtuacao)=>{
            map[setorAtuacao.id] = setorAtuacao.nome;
            return map;
          },{}),
          editable: 'never'
        },{
          field: 'subsegmento', title: 'Sub-segmento',
          lookup: Object.keys(subsegmentos).reduce((map,setorAtuacao)=>{
            map = subsegmentos[setorAtuacao].reduce((map,subsegmento)=>{
              map[subsegmento.id] = subsegmento.label;
              return map;
            },map);
            return map;
          },{}),
          editable: 'never'
        },{
          field: 'sistema', title: 'Sistema',
          lookup: allSistemas,
          editable: 'never'
        },{
          field: 'benchmarkingExAnte',
          title: <Tooltip title={textoExAnte}>
                   <span>Benchmarking Ex-Ante (MWh/t)</span>
                 </Tooltip>,
          filtering: false,  editable: 'never',
          render: rowData => rowData.benchmarkingExAnte?
            (rowData.benchmarkingExAnte/1000).toLocaleString(undefined,{
              minimumFractionDigits: 8, maximumFractionDigits: 8
            }):''
        },{
          field: 'benchmarkingExPost',
          title: <Tooltip title={textoExPost}>
                   <span>Benchmarking Ex-Post (MWh/t)</span>
                 </Tooltip>,
          filtering: false, editable: 'never',
          render: rowData => rowData.benchmarkingExPost?
            (rowData.benchmarkingExPost/1000).toLocaleString(undefined,{
              minimumFractionDigits: 8, maximumFractionDigits: 8
            }):''
        },{
          field: 'relacaoAntePost', title: 'Relação Ex-Ante/Ex-Post',
          filtering: false, editable: 'never',
          render: rowData => (
            rowData.benchmarkingExAnte && rowData.benchmarkingExPost ?
              (rowData.benchmarkingExAnte*100/rowData.benchmarkingExPost)
              .toLocaleString(undefined,{
                minimumFractionDigits: 2, maximumFractionDigits: 2
              })+'%':''
          )
        },{
          field: 'target', title: 'Benchmarking Target (MWh/t)',
          filtering: false,
          emptyValue: '(não definido)'
        },{
          field: 'prazo', title: 'Prazo Atendimento Target',
          filtering: false, type: 'date',
          emptyValue: '(não definido)',
          cellStyle: {
            minWidth: 182,
          },
          editComponent: props => (
            <LocalizationProvider
              dateAdapter={AdapterDateFns}
              adapterLocale={ptBRLocale}>
              <DatePicker
                value={props.value || null}
                onChange={props.onChange}
                clearable
                renderInput={(params) => <TextField {...params} />}
                InputProp={{
                  style: {
                    fontSize: 13
                  }
                }}
              />
            </LocalizationProvider>
          )
        },{
          field: 'atendimentoExPost', title: 'Atendimento Target',
          filtering: false, editable: 'never',
          render: rowData => (
            rowData.benchmarkingExPost && rowData.target ?
              (rowData.benchmarkingExPost*100/rowData.target)
              .toLocaleString(undefined,{
                minimumFractionDigits: 2, maximumFractionDigits: 2
              })+'%':''
          )
        }]);
      }
      stopLoading();
    } catch(error) {
      stopLoading({
        message: error.toString(),
        severity: 'error'
      });
      throw error;
    }
  };

  const save = async(newData, oldData) => {
    const dataUpdate = [...rows];
    const index = oldData.tableData.index;
    dataUpdate[index] = newData;

    startLoading();
    try {
      if(newData['targetId']) {
        await api.http.put('/targets-benchmarkings/'+newData['targetId'],newData);
      } else {
        const target = await api.http.post('/targets-benchmarkings',newData);
        dataUpdate[index]['targetId'] = target.data.id;
      }
      setRows([...dataUpdate]);
      stopLoading({ severity: 'success'});
    } catch (error) {
      stopLoading({
        message: error.toString(),
        severity: 'error'
      });
      throw error;
    };
  };


  return (
    <Dialog
      open={open}
      onClose={()=>close()}
      fullWidth={true}
      maxWidth={'xl'} >
      <BootstrapDialogTitle id="customized-dialog-title" onClose={onClose}>
      </BootstrapDialogTitle>
      <DialogContent>
        <MaterialTable
          options={{
            filtering: true
          }}
          columns={columns}
          data={rows}
          title="Resultados Benchmarking"
          localization={materialTableLocalization}
          editable={{ onRowUpdate: save  }}
        />
      </DialogContent>
      <DialogActions>
        { loading>0 && <CircularProgress
                         size={30}
                         sx={{
                           'position': 'absolute',
                           'left':25,
                           'bottom': 12 }} /> }
      </DialogActions>
    </Dialog>
  );
};

export default DialogoTargets;
