import * as React from 'react';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Fab,
  IconButton,
  CircularProgress
} from '@mui/material';
import PropTypes from 'prop-types';
import CloseIcon from '@mui/icons-material/Close';
import AddIcon from '@mui/icons-material/Add';
import api from "../../services/api";
import { useStoreState, useStoreActions } from 'easy-peasy';
import MaterialTable from '@material-table/core';
import {
  materialTableLocalization,
  materialTableCurrencySettings
} from '../../config';
import DialogoDadosFinanceiros from './DialogoDadosFinanceiros';
import {
  opcoesBooleanas
} from '../../static';
import { format } from 'date-fns';

const BootstrapDialogTitle = (props) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};
BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

const DadosFinanceiros = ({processo, open, onClose, disabled}) => {
  let startLoading = useStoreActions((actions) => actions.nav.startLoading);
  let stopLoading = useStoreActions((actions) => actions.nav.stopLoading);
  let loading = useStoreState((state) => state.nav.loading);
  const [openDlgDadosFinanceiros, setOpenDlgDadosFinanceiros] = React.useState(false);
  const [rows, setRows] = React.useState([]);
  const [dadosFinanceiros, setDadosFinanceiros] = React.useState(null);

  const loadDadosFinanceiros = async () => {
    startLoading();
    try {
      setRows(
        (await api.http.get('/dados-financeiros?processoId='+processo.id)).data
      );
      stopLoading();
    } catch (error) {
      stopLoading({
        message: error.toString(),
        severity: 'error'
      });
    };
  };

  React.useEffect(async () => {
    loadDadosFinanceiros();
  },[]);

  const onCloseDlgDadosFinanceiros = (reload=false) => {
    setDadosFinanceiros(null);
    setOpenDlgDadosFinanceiros(false);
    if(reload) {
      loadDadosFinanceiros();
    }
  };

  const onRowClick = (event,rowData) => {
    setDadosFinanceiros(rowData);
    setOpenDlgDadosFinanceiros(true);
  };

  return (
    <Dialog open={open}
            fullWidth={true}
            maxWidth={'lg'}>
      <BootstrapDialogTitle id="customized-dialog-title" onClose={onClose}>
      </BootstrapDialogTitle>
      <DialogContent>
        <DialogoDadosFinanceiros
          dadosFinanceiros={dadosFinanceiros}
          processo={processo}
          open={openDlgDadosFinanceiros}
          onClose={onCloseDlgDadosFinanceiros}  />
        <MaterialTable
          columns={[
            {
              field: 'data', title: 'Data',
              render: rowData => format(new Date(rowData.data),
                                        'MM/yyyy')
            },{
              field: 'field_6_3', title: 'Valor desembolsado',
              type: 'currency',
              currencySetting: materialTableCurrencySettings
            },{
              field: 'field_6_4', title: 'Valor reembolsado',
              type: 'currency',
              currencySetting: materialTableCurrencySettings
            },{
              field: 'field_6_6', title: 'Crédito para EE em inadimplemento',
              lookup: opcoesBooleanas.reduce((map,opcao)=>{
                map[opcao.id]=opcao.label;
                return map;
              },{})
            },{
              field: 'field_9_1', title: 'Quantia desembolsada da CG',
              type: 'currency',
              currencySetting: materialTableCurrencySettings
            },{
              field: 'field_9_4', title: 'Data da transferência da CG',
              type: 'date'
            }
          ]}
          data={rows}
          title="Dados Trimestrais de Financiamento"
          onRowClick={onRowClick}
          localization={materialTableLocalization}
        />
      </DialogContent>
      <DialogActions>
        { loading>0 && <CircularProgress
                         size={30}
                         sx={{
                           'position': 'absolute',
                           'left':25,
                           'bottom': 12 }} /> }
        <Fab color="primary" aria-label="novo fornecedor"
             onClick={()=>{
               setOpenDlgDadosFinanceiros(true);
             }}>
          <AddIcon />
        </Fab>
      </DialogActions>
    </Dialog>
  );
};

export default DadosFinanceiros;
