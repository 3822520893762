import * as React from 'react';
import ptBRLocale from 'date-fns/locale/pt-BR';
import TextField from '@mui/material/TextField';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { Controller } from "react-hook-form";

const DateField = ({field,useFormRef,schema,...props}) => {
  const {register, control, formState: {errors}} = useFormRef;

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={ptBRLocale}>
      <Controller
        name={field}
        control={control}
        render={({
          field: {onChange, onBlur, value, name, ref},
          fieldState: { invalid, isTouched, isDirty, error }
        }) => (
          <DatePicker
            label={schema?.fields[field].spec.label}
            value={value?value:null}
            onChange={(value)=>onChange(value)}
            name={name}
            inputRef={ref}
            renderInput={(params) => (
              <TextField
                {...params}
                required={props.required ||
                          schema?.fields[field].exclusiveTests['required']}
                error={error!=null}
                onBlur={onBlur}
                helperText={error?.message}
              />
            )}
            {...props}
          />)}
      />
    </LocalizationProvider>
  );
};

export default DateField;
