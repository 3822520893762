import React, { useEffect, useState } from "react";
import api from "../../services/api";
import Docxtemplater from "docxtemplater";
import PizZip from "pizzip";
import PizZipUtils from "pizzip/utils/index.js";
import { saveAs } from "file-saver";
import { Button } from "@mui/material";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import { useStoreActions } from "easy-peasy";
import { format } from 'date-fns';

function loadFile(url, callback) {
    PizZipUtils.getBinaryContent(url, callback);
  }

const templatePath = `${process.env.PUBLIC_URL}/relatorio-m&a.docx`;

const RelatorioWord = () => {
let startLoading = useStoreActions((actions) => actions.nav.startLoading);
let stopLoading = useStoreActions((actions) => actions.nav.stopLoading);

const generateDocument = async() => {
startLoading();
try {
  let dados = (await api.http.get('/relatorios/dados-agregado')).data;
  loadFile(templatePath, function (error, content) {
    if (error) {
      throw error;
    }
    const zip = new PizZip(content);
    const doc = new Docxtemplater(zip, {
      paragraphLoop: true,
      linebreaks: true,
    });
    const valores = {
      valor_1_1: dados?.ref_1_1 != undefined ? dados?.ref_1_1.toLocaleString(undefined,{
        minimumFractionDigits: 0, maximumFractionDigits: 0
      }) : 'indefinido',
      valor_1_2: dados?.ref_1_2 != undefined ? dados?.ref_1_2.toLocaleString(undefined,{
        minimumFractionDigits: 0, maximumFractionDigits: 0
      }) : 'indefinido',
      valor_1_4: dados?.ref_1_4 != undefined ? dados?.ref_1_4.toLocaleString(undefined,{
        minimumFractionDigits: 0, maximumFractionDigits: 0
      }) : 'indefinido',
      valor_1_5: dados?.ref_1_5 != undefined ? dados?.ref_1_5.toLocaleString(undefined,{
        minimumFractionDigits: 0, maximumFractionDigits: 0
      }) : 'indefinido',
      valor_1_7: dados?.ref_1_7 != undefined ? dados?.ref_1_7.toLocaleString(undefined,{
        minimumFractionDigits: 0, maximumFractionDigits: 0
      }) : 'indefinido',
      valor_1_12: dados?.ref_1_12 != undefined ? dados?.ref_1_12.toLocaleString(undefined,{
        minimumFractionDigits: 2, maximumFractionDigits: 2
      }) : 'indefinido',
      valor_1_13: dados?.ref_1_13 != undefined ? dados?.ref_1_13.toLocaleString(undefined,{
        minimumFractionDigits: 2, maximumFractionDigits: 2
      }) : 'indefinido',
      valor_1_14: dados?.ref_1_14 != undefined ? dados?.ref_1_14.toLocaleString(undefined,{
        minimumFractionDigits: 0, maximumFractionDigits: 0
      }) : 'indefinido',
      valor_1_15: dados?.ref_1_15 != undefined ? dados?.ref_1_15.toLocaleString(undefined,{
        minimumFractionDigits: 0, maximumFractionDigits: 0
      }) : 'indefinido',
      valor_1_16: dados?.ref_1_16 != undefined ? dados?.ref_1_16.toLocaleString(undefined,{
        minimumFractionDigits: 0, maximumFractionDigits: 0
      }) : 'indefinido',
      valor_1_17: dados?.ref_1_17 != undefined ? dados?.ref_1_17.toLocaleString(undefined,{
        minimumFractionDigits: 0, maximumFractionDigits: 0
      }) : 'indefinido',
      valor_1_18: dados?.ref_1_18 != undefined ? dados?.ref_1_18.toLocaleString(undefined,{
        minimumFractionDigits: 0, maximumFractionDigits: 0
      }) : 'indefinido',
      valor_1_19: dados?.ref_1_19 != undefined ? dados?.ref_1_19.toLocaleString(undefined,{
        minimumFractionDigits: 0, maximumFractionDigits: 0
      }) : 'indefinido',
      data: format(new Date(),'dd/MM/yyyy')
    };
    doc.render(valores);
    const blob = doc.getZip().generate({
      type: "blob",
      mimeType:
        "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
    });
    saveAs(blob, "relatorio.docx");
  });
  if(!dados){
    stopLoading({
      message: 'Nenhum registro encontrado.',
      severity: 'error'
    });
  }else {
    stopLoading();
  };
} catch (error) {
  stopLoading({
    message: error.toString(),
    severity: 'error'
  });
};

};

  return (
    <>
      <Button variant="contained" onClick={generateDocument} sx={{ height: 45 }}>
        <FileDownloadIcon /> EXPORTAR RELATÓRIO (WORD)
      </Button>
    </>
  );
};

export default RelatorioWord;
