import React from 'react';
import TextField  from '@mui/material/TextField';
import { Controller } from "react-hook-form";
import NumberFormat from 'react-number-format';

import * as yup from "yup";

yup.addMethod(yup.number,'float',function floatTransform() {
  return this.transform((o,v)=>{
    if(typeof v == 'string') {
      return parseFloat(v
                        .replace(/\./g,'')
                        .replace(/,/, '.'));
    }
    return v;
  });
});

const FloatField = ({field,useFormRef,schema,disabled,...props}) => {
  const {control} = useFormRef;
  return (
    <Controller
      name={field}
      control={control}
      render={
        ({
          field: {onChange, onBlur, value, name, ref},
          fieldState: { invalid, isTouched, isDirty, error }
        }) =>
          <NumberFormat
            customInput={TextField}
            label={schema?.fields[field].spec.label}
            required={schema?.fields[field].exclusiveTests['required']}
            error={error!=null}
            helperText={error?.message}
            decimalScale={2}
            fixedDecimalScale
            thousandSeparator="."
            decimalSeparator=","
            onChange={onChange}
            onBlur={onBlur}
            value={value}
            name={name}
            inputRef={ref}
            disabled={disabled}
            {...props}
          />
      }
    />
  );

};

export default FloatField;
