import { action } from 'easy-peasy';

const interesseStore = {
  cnpjValidado: null,
  setCnpjValidado: action((state,payload) => {
    state.cnpjValidado = payload;
  })
};

export default interesseStore;
