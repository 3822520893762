import React from 'react';
import {
  Paper,
  Button,
  Divider,
  FormControl,
  Box,
  Link
} from '@mui/material';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";
import { pt } from 'yup-locale-pt';
import {
  useNavigate,
  useLocation,
} from "react-router-dom";
import { TextField } from '../../components';
import api from "../../services/api";
import { useStoreActions } from 'easy-peasy';
yup.setLocale(pt);

const Login = () => {
  let startLoading = useStoreActions((actions) => actions.nav.startLoading);
  let stopLoading = useStoreActions((actions) => actions.nav.stopLoading);
  let setUser = useStoreActions((actions) => actions.auth.setUser);

  let navigate = useNavigate();
  let location = useLocation();

  let from = location.state?.from?.pathname || "/";

  const schema = yup.object({
    email: yup.string().email().label('Email').required(),
    password: yup.string().label('Senha').required(),
  }).required();

  const useFormRef = useForm({
    mode: 'onTouched',
    resolver: yupResolver(schema)
  });

  const { handleSubmit,
          formState: {isDirty,isSubmitting} } = useFormRef;

  const onSubmit = async (data) => {
    startLoading();
    try {
      await api.signin(data);
      setUser((await api.http.get('auth-user')).data);
      stopLoading({ severity: 'success' });
      navigate(from=='/'?'/processos':from, { replace: true });
    } catch (error) {
      stopLoading({
        message: error.toString(),
        severity: 'error'
      });
    };
  };

  return (
    <Paper variant="outlined" className='center-box' sx={{
      textAlign: 'center',
      px: 4,
      pt: 2,
      pb: 3,
      m: 5,
      maxWidth: 'sm',
    }}>

      <form onSubmit={handleSubmit(onSubmit)}>
        <Divider>
          <h3>Login</h3>
        </Divider>
        <FormControl sx={{ my: 2 }} fullWidth>
          <TextField field="email" type="email"
                     useFormRef={useFormRef} schema={schema} />
        </FormControl>
        <FormControl sx={{ my: 2 }} fullWidth>
          <TextField field="password" type="password"
                     useFormRef={useFormRef} schema={schema} />
        </FormControl>
        <Link href="/esqueci-minha-senha">Esqueci minha senha</Link>
        <Box sx={{ mt: 2 }}>
          <Button type="submit" variant="contained" size="large"
                  endIcon={<ArrowForwardIcon />}
                  disabled={!isDirty || isSubmitting}>
            Acessar o sistema
          </Button>
        </Box>
      </form>
    </Paper>
  );
};

export default Login;
