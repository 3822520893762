import * as React from 'react';
import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Button,
  Link
} from '@mui/material';


const DialogoConfirmacaoSimples = ({
  title, children, open, onClose, onSim, onNao
}) => {

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        {children}
      </DialogContent>
      <DialogActions>
        <Button onClick={onNao} color="inherit">
          NÃO
        </Button>
        <Button onClick={onSim}>
          SIM
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DialogoConfirmacaoSimples;
