import React, { useEffect } from 'react';
import { formatCurrency, formatInteger, formatDecimal } from '../../services/utils';
import {
  Grid,
  Box,
  Typography,
} from "@mui/material";
import './ModalRelResumo.css';

const RelatorioResultadoResumoFontesEnergeticas = ({ lstQuestionario, lstRespostas }) => {
  const [resumoFontes, setResumoFontes] = React.useState([]);
  const [resumoFontesMwh, setResumoFontesMwh] = React.useState(0);
  const [resumoFontesRs, setResumoFontesRs] = React.useState(0);
  const [resumoFontesEeco2, setResumoFontesEeCo2] = React.useState(0);

  useEffect(() => {
    getEnergiaEletrica();

    if (lstRespostas.prediagnostico["general-data_3_1"] === true) { montaResumoFonte('fuel-data-1', 'Bagaço de cana', 2475.53, 0.3672) };
    if (lstRespostas.prediagnostico["general-data_3_2"] === true) { montaResumoFonte('fuel-data-2', 'Bio diesel (B100)', 9.20, 0.0009) };
    if (lstRespostas.prediagnostico["general-data_3_3"] === true) { montaResumoFonte('fuel-data-3', 'Bio gás', 6.97, 0.196875) };
    if (lstRespostas.prediagnostico["general-data_3_4"] === true) { montaResumoFonte('fuel-data-4', 'Carvão', 3428.55, 0.3668) };
    if (lstRespostas.prediagnostico["general-data_3_5"] === true) { montaResumoFonteLenha('fuel-data-5', 'Carvão vegetal', 7507.952502, 0.024, 0.408096) };
    // if(lstRespostas.prediagnostico["general-data_3_6"] === true){montaResumoFonte('fuel-data-6', 'Eletricidade', 0.0860)};
    if (lstRespostas.prediagnostico["general-data_3_7"] === true) { montaResumoFonte('fuel-data-7', 'Gás natural', 10.23, 0.2022) };
    if (lstRespostas.prediagnostico["general-data_3_8"] === true) { montaResumoFonte('fuel-data-8', 'Gasolina', 12.09, 0.2504) };
    if (lstRespostas.prediagnostico["general-data_3_9"] === true) { montaResumoFonte('fuel-data-9', 'GLP', 12.90, 0.2272) };
    //lenha
    if (lstRespostas.prediagnostico["general-data_3_10"] === true) { montaResumoFonteLenha('fuel-data-10', 'Lenha (m³)-umidade normal(43%)', 2522.021197, 0.0068, 0.3685212) };
    if (lstRespostas.prediagnostico["general-data_3_11"] === true) { montaResumoFonteLenha('fuel-data-11', 'Lenha (m³)-baixa umidade(36%)', 2562.698958, 0.0068, 0.3685212) };
    if (lstRespostas.prediagnostico["general-data_3_12"] === true) { montaResumoFonteLenha('fuel-data-12', 'Lenha (t)-umidade normal(43%)', 3602.89, 0.0068, 0.3685212) };
    if (lstRespostas.prediagnostico["general-data_3_13"] === true) { montaResumoFonteLenha('fuel-data-13', 'Lenha (t)-baixa umidade(36%)', 3661.00, 0.0068, 0.3685212) };
    // .end-> lenha
    if (lstRespostas.prediagnostico["general-data_3_14"] === true) { montaResumoFonte('fuel-data-14', 'Óleo combustível', 11145.71, 0.2794) };
    if (lstRespostas.prediagnostico["general-data_3_15"] === true) { montaResumoFonte('fuel-data-15', 'Óleo diesel', 11.74, 0.2675) };
    if (lstRespostas.prediagnostico["general-data_3_16"] === true) { montaResumoFonte('fuel-data-16', 'Querosene', 12.09, 0.2596) };
    if (lstRespostas.prediagnostico["general-data_3_17"] === true) { montaResumoFonte('fuel-data-17', 'Sol', 1, 1) };

  }, []);

  //método que monta a lista de fontes energéticas
  const addFonteResumo = (fonte, un_ano, mwh_ano, rs_ano, eeco2) => {
    // Crie o novo item
    const newFonte = {
      fonte,
      un_ano,
      mwh_ano,
      rs_ano,
      eeco2,
    };

    const fonteExiste = resumoFontes.some((item) => item.fonte === fonte);
    if (!fonteExiste) {
      setResumoFontes((prevState) => [...prevState, newFonte]);
      setResumoFontesMwh((atual) => parseFloat(atual) + parseFloat(mwh_ano));
      setResumoFontesRs((atual) => parseFloat(atual) + parseFloat(rs_ano));
      setResumoFontesEeCo2((atual) => atual + eeco2);

    };
  }

  const getEnergiaEletrica = () => {
    const titulo = "Eletricidade";
    const un_ano = lstRespostas.prediagnostico["general-data_2_7"];
    const mwh_ano = lstRespostas.prediagnostico["general-data_2_8"];
    const FatorEmissao = 1;
    const vidasUteis = 0.0860;

    addFonteResumo(titulo, un_ano, Math.ceil(un_ano * FatorEmissao / 1000), mwh_ano, (vidasUteis === 0 ? 0 : ((un_ano * FatorEmissao / 1000) * vidasUteis)));
  };

  // Função para obter a lista de perguntas e respostas de um grupo específico
  const getQuestionsAndAnswers = (groupName) => {
    const questionsAndAnswers = [];

    lstQuestionario.steps.forEach((step) => {
      step.groups?.forEach((group) => {
        if (group.name === groupName) {
          group.questions?.forEach((question) => {
            questionsAndAnswers.push({
              questionName: question.name,
              questionDescription: question.description,
              questionAnswer: question.answer,
              questionValidation: question.validation.type
            });
          });
        }
      });
    });

    return questionsAndAnswers;
  };

  const montaResumoFonteLenha = ((grupo, titulo, FatorEmissao, FatorEmissaoCo2Cetificado, FatorEmissaoCo2SemCertificado) => {
    let index = 0;
    let un_ano = 0;
    let mwh_ano = 0;
    let rs_ano = 0;
    let combustivel = 0;
    const questionAndAnswers = getQuestionsAndAnswers(grupo);

    // console.log("lenha ", titulo);
    // console.log("Questions ", questionAndAnswers);

    questionAndAnswers.forEach((item) => {
      if (index === 1) {
        // console.log("resumo fontes energeticas: ", lstRespostas.prediagnostico[item.questionName]);
        // console.log(item.questionName);
        combustivel = lstRespostas.prediagnostico[item.questionName];// ? lstRespostas.prediagnostico[item.questionName] : 0
      }
      else
        if (index === 2) { un_ano = lstRespostas.prediagnostico[item.questionName] ? lstRespostas.prediagnostico[item.questionName] : 0; }
        else {
          if (index === 3) { mwh_ano = lstRespostas.prediagnostico[item.questionName] ? lstRespostas.prediagnostico[item.questionName] : 0; }
          else {
            if (index === 4) { rs_ano = lstRespostas.prediagnostico[item.questionName] ? lstRespostas.prediagnostico[item.questionName] : 0; }
          }
        };

      index += 1;
    });
    // console.log("combustivel: ", combustivel);
    if (combustivel === "0") {

      // console.log("=================================================================================");
      // console.log("Titulo: ", titulo);
      // console.log("un ano: ", un_ano);
      // console.log("fator Emissão: ", FatorEmissao);
      // console.log(Math.ceil(un_ano * FatorEmissao / 1000));
      // console.log("mwh ano: ", mwh_ano);
      // console.log("vidas uteis: ", FatorEmissaoCo2SemCertificado)
      // console.log(((un_ano * FatorEmissao / 1000) * FatorEmissaoCo2SemCertificado));

      addFonteResumo(titulo, un_ano, Math.ceil(un_ano * FatorEmissao / 1000), mwh_ano, (FatorEmissaoCo2SemCertificado === 0 ? 0 : ((un_ano * FatorEmissao / 1000) * FatorEmissaoCo2SemCertificado)));
    }
    else {
      // console.log("Titulo: ", titulo);
      // console.log("un ano: ", un_ano);
      // console.log("fator Emissão: ", FatorEmissao);
      // console.log(Math.ceil(un_ano * FatorEmissao / 1000));
      // console.log("mwh ano: ", mwh_ano);
      // console.log("vidas uteis: ", FatorEmissaoCo2Cetificado)
      // console.log(((un_ano * FatorEmissao / 1000) * FatorEmissaoCo2Cetificado));

      addFonteResumo(titulo, un_ano, Math.ceil(un_ano * FatorEmissao / 1000), mwh_ano, (FatorEmissaoCo2Cetificado === 0 ? 0 : ((un_ano * FatorEmissao / 1000) * FatorEmissaoCo2Cetificado)));
    }

  });

  const montaResumoFonte = ((grupo, titulo, FatorEmissao, vidasUteis) => {
    let index = 0;
    let un_ano = 0;
    let mwh_ano = 0;
    let rs_ano = 0;
    const questionAndAnswers = getQuestionsAndAnswers(grupo);

    questionAndAnswers.forEach((item) => {
      if (index === 1) { un_ano = lstRespostas.prediagnostico[item.questionName] ? lstRespostas.prediagnostico[item.questionName] : 0; }
      else {
        if (index === 2) { mwh_ano = lstRespostas.prediagnostico[item.questionName] ? lstRespostas.prediagnostico[item.questionName] : 0; }
        else {
          if (index === 3) { rs_ano = lstRespostas.prediagnostico[item.questionName] ? lstRespostas.prediagnostico[item.questionName] : 0; }
        }
      };
      index += 1;
    });

    addFonteResumo(titulo, un_ano, Math.ceil(un_ano * FatorEmissao / 1000), mwh_ano, (vidasUteis === 0 ? 0 : ((un_ano * FatorEmissao / 1000) * vidasUteis)));

  });

  // console.log("resumo fontes ", resumoFontes);

  return (
    <>
      <Box className='title' sx={{ pageBreakBefore: 'always' }}>
        <Typography variant="h4" gutterBottom>
          RESUMO DAS FONTES ENERGÉTICAS
        </Typography>
      </Box>
      <Grid container spacing={0} className='grid-container'>
        <Grid item xs={4} className='item-response'>
          <Typography variant="subtitle1" gutterBottom>Fonte</Typography>
        </Grid>
        <Grid item xs={2} className='item-response-center'>
          <Typography variant="subtitle1" gutterBottom>Consumo médio anual (un/ano)</Typography>
        </Grid>
        <Grid item xs={2} className='item-response-center'>
          <Typography variant="subtitle1" gutterBottom>Consumo médio anual (MWh/ano)</Typography>
        </Grid>
        <Grid item xs={2} className='item-response-center'>
          <Typography variant="subtitle1" gutterBottom>Custo médio anual (R$/ano)</Typography>
        </Grid>
        <Grid item xs={2} className='item-response-center'>
          <Typography variant="subtitle1" gutterBottom>Emissões equivalentes de CO2 (tCO2eq)</Typography>
        </Grid>

        {resumoFontes.map((item, index) => (
          <>
            <Grid item xs={4} className='item-response'>
              <Typography variant="subtitle2" gutterBottom>{item.fonte}</Typography>
            </Grid>
            <Grid item xs={2} className='item-response-center'>
              <Typography variant="subtitle2" gutterBottom>{formatInteger(item.un_ano)}</Typography>
            </Grid>
            <Grid item xs={2} className='item-response-center'>
              <Typography variant="subtitle2" gutterBottom>{formatInteger(item.mwh_ano)}</Typography>
            </Grid>
            <Grid item xs={2} className='item-response-center'>
              <Typography variant="subtitle2" gutterBottom>{formatCurrency(item.rs_ano)}</Typography>
            </Grid>
            <Grid item xs={2} className='item-response-center'>
              <Typography variant="subtitle2" gutterBottom>{formatDecimal(item.eeco2, 1)}</Typography>
            </Grid>
          </>
        ))}


        <Grid item xs={6} className='item-response-center-color'>
          <Typography variant="subtitle1" gutterBottom>Total</Typography>
        </Grid>
        <Grid item xs={2} className='item-response-center-color'>
          <Typography variant="subtitle1" gutterBottom>{formatInteger(resumoFontesMwh)}</Typography>
        </Grid>
        <Grid item xs={2} className='item-response-center-color'>
          <Typography variant="subtitle1" gutterBottom>{formatCurrency(resumoFontesRs)}</Typography>
        </Grid>
        <Grid item xs={2} className='item-response-center-color'>
          <Typography variant="subtitle1" gutterBottom>{formatDecimal(resumoFontesEeco2)}</Typography>
        </Grid>
      </Grid>
    </>
  );
};

export default RelatorioResultadoResumoFontesEnergeticas;