import { action } from 'easy-peasy';

const authStore = {
  user: null,
  setUser: action((state,payload) => {
    state.user = payload;
  })
};

export default authStore;
