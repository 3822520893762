import TextField  from '@mui/material/TextField';

const CEPField = ({field,useFormRef,schema,...props}) => {
  const {register, formState: {errors}} = useFormRef;
  const cepMask = event => {
    let v = event.target.value.replace(/\D/g,"");
    v = v.replace(/(\d{5})(\d)/,"$1-$2");
    v = v.replace(/(-\d{3})\d+?$/,"$1");
    event.target.value = v;
  };

  return (
    <TextField
      label={schema.fields[field].spec.label}
      required={schema.fields[field].exclusiveTests['required']}
      error={errors[field]!=null}
      helperText={errors[field]?.message}
      {...register(field,{
        onChange: cepMask
      })}
      {...props} />
  );
};

export default CEPField;
