import React from 'react';
import {
  Box,
  Divider,
  Typography,
  FormControl,
  TextField as MUITextField,
  Button,
  Paper
} from '@mui/material';
import api from "../../services/api";
import {
  TextField,
} from '../../components';
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";
import { pt } from 'yup-locale-pt';
import { useStoreState, useStoreActions } from 'easy-peasy';
import PersonIcon from '@mui/icons-material/Person';
yup.setLocale(pt);

const Perfil = () => {
  let startLoading = useStoreActions((actions) => actions.nav.startLoading);
  let stopLoading = useStoreActions((actions) => actions.nav.stopLoading);
  let setUser = useStoreActions((actions) => actions.auth.setUser);
  let user = useStoreState((state) => state.auth.user);
  const schema = yup.object({
    nome: yup.string().label('Nome').required(),
    password: yup.string()
      .label('Senha atual'),
    newPassword: yup.string()
      .label('Nova senha'),
    confirmPassword: yup.string()
      .label('Confirmar nova senha')
      .oneOf([yup.ref('newPassword')],
             'A confirmação da senha deve ser igual a nova senha'),
  }).required();

  const useFormRef = useForm({
    mode: 'onTouched',
    resolver: yupResolver(schema),
    defaultValues: {
      nome: user?.nome
    }
  });

  const { handleSubmit, reset,
          formState: {isDirty, isSubmitting} } = useFormRef;

  const submit = async (data) => {
    startLoading();
    try {
      setUser((await api.http.put('auth-user',data)).data);
      stopLoading({ severity: 'success' });
      reset({ keepValues: true});
    } catch(error) {
      stopLoading({
        message: error.toString(),
        severity: 'error'
      });
    };

  };

  return (
    <Paper variant="outlined" className='center-box' sx={{
      textAlign: 'center',
      px: 4,
      pt: 2,
      pb: 3,
      m: 5,
      maxWidth: 'md',
    }}>
      <Divider>
        <Typography variant="h4" sx={{ display: 'flex', alignItems: 'center' }}>
          <PersonIcon sx={{'mr':1}} /> Meu perfil
        </Typography>
      </Divider>
      <form onSubmit={handleSubmit(submit)} >
         <FormControl sx={{ my: 2 }} fullWidth>
          <MUITextField label="Empresa" defaultValue={user?.empresa}
                        disabled={true}/>
        </FormControl>
        <FormControl sx={{ my: 2 }} fullWidth>
          <TextField field="nome"
                     useFormRef={useFormRef} schema={schema} />
        </FormControl>
        <FormControl sx={{ my: 2 }} fullWidth>
          <MUITextField type="email" label="Email" defaultValue={user?.email}
                        disabled={true}/>
        </FormControl>
         <FormControl sx={{ my: 2 }} fullWidth>
          <MUITextField label="Função" defaultValue={user?.role.name}
                        disabled={true}/>
        </FormControl>
        <FormControl sx={{ my: 2 }} fullWidth>
          <TextField field="password" type="password"
                     useFormRef={useFormRef} schema={schema} />
        </FormControl>
        <FormControl sx={{ my: 2 }} fullWidth>
          <TextField field="newPassword" type="password"
                     useFormRef={useFormRef} schema={schema} />
        </FormControl>
        <FormControl sx={{ my: 2 }} fullWidth>
          <TextField field="confirmPassword" type="password"
                     useFormRef={useFormRef} schema={schema} />
        </FormControl>
        <Box sx={{ mt: 2 }}>
          <Button type="submit" variant="contained" size="large"
                  disabled={!isDirty || isSubmitting}>ALTERAR</Button>
        </Box>
      </form>
    </Paper>
  );
};

export default Perfil;
