export const driveBase = '';

export const errorMessages = {
  E_INVALID_AUTH_PASSWORD: 'Senha inválida',
  E_INVALID_AUTH_UID: 'Usuário não encontrado',
  E_INVALID_CREDENTIAL: 'Senha inválida',
  ER_DUP_ENTRY: 'Cadastro duplicado',
  E_ROW_NOT_FOUND: 'Cadastro não encontrado',
  E_UNAUTHORIZED_ACCESS: 'Acesso não autorizado'
};

export const materialTableLocalization = {
  body: {
    emptyDataSourceMessage: 'Nenhum registro para exibir'
  },
  toolbar: {
    searchTooltip: 'Pesquisar'
  },
  header: {
    actions: 'Ações'
  },
  pagination: {
    labelRowsSelect: 'linhas',
    labelDisplayedRows: '{count} de {from}-{to}',
    labelRowsPerPage: 'Linhas por página:',
    firstTooltip: 'Primeira página',
    previousTooltip: 'Página anterior',
    nextTooltip: 'Próxima página',
    lastTooltip: 'Última página'
  }
};

export const materialTableCurrencySettings = {
  locale: 'pt-BR',
  currencyCode:'BRL',
  minimumFractionDigits:2,
  maximumFractionDigits:2
};

export const indGeraisCfg = {
  investEur: 80,
  numImpl: 425,
  numDiags: 1000,
  econEnergia: 7.267,
  reducaoCO2: 1.1
};
