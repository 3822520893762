import React from "react";
import api from "../../services/api";

import { SemiCirculo } from "../../components";
import { indGeraisCfg } from "../../config";
import { Grid } from "@mui/material";

const PainelGeral = () => {
  React.useEffect(() => {
    let mount = {
      isMounted: true,
    };
    loadIndGerais(mount);
  }, []);

  const [indGerais, setIndGerais] = React.useState(null);

  const loadIndGerais = async (mount) => {
    let getIndGerais = await api.http.get("/indicadores-gerais");
    if (mount.isMounted) {
      setIndGerais(getIndGerais.data);
    }
  };

  return (
    <Grid container spacing={2}>
      <Grid item sm={12}>
        <SemiCirculo
          min={0}
          max={indGeraisCfg.investEur}
          valor={indGerais?.investEur}
          unidade={"MM EUR"}
        >
          Total de Investimentos Realizados
        </SemiCirculo>
      </Grid>
      <Grid item sm={6}>
        <SemiCirculo
          min={0}
          max={indGeraisCfg.numImpl}
          valor={indGerais?.numImpl}
          unidade={"Num. Projetos"}
        >
          Projetos Implementados
        </SemiCirculo>
      </Grid>
      <Grid item sm={6}>
        <SemiCirculo
          min={0}
          max={indGeraisCfg.numDiags}
          valor={indGerais?.numDiags}
          unidade={"Num. Diagnósticos"}
        >
          Diagnósticos Realizados
        </SemiCirculo>
      </Grid>
      <Grid item sm={6}>
        <SemiCirculo
          min={0}
          max={indGeraisCfg.econEnergia}
          valor={indGerais?.econEnergia}
          unidade={"GWh"}
        >
          Economia de Energia
        </SemiCirculo>
      </Grid>
      <Grid item sm={6}>
        <SemiCirculo
          min={0}
          max={indGeraisCfg.reducaoCO2}
          valor={indGerais?.reducaoCO2}
          unidade={"MM Ton."}
        >
          Redução de Emissão de CO2
        </SemiCirculo>
      </Grid>
    </Grid>
  );
};

export default PainelGeral;
