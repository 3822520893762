import * as React from 'react';
import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Button,
  Link
} from '@mui/material';
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";
import { pt } from 'yup-locale-pt';
import {
  TextField
} from './';
yup.setLocale(pt);


const DialogoConfirmacao = ({
  title, children, open, onClose, onSim, onNao, criteriosUrl, disabledSim=false
}) => {
  const schema = yup.object({
    observacoes: yup.string().required().label('Observações')
  }).required();
  const useFormRef = useForm({
    mode: 'onTouched',
    resolver: yupResolver(schema)
  });
  const { handleSubmit } = useFormRef;

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        {children}
        { criteriosUrl &&
          <React.Fragment>
            <DialogContentText>&nbsp;</DialogContentText>
            <DialogContentText>
              Veja <Link
                     href={criteriosUrl} target="_blank"
                     rel="noreferrer">aqui</Link> os critérios para aprovação
            </DialogContentText>
            <DialogContentText>&nbsp;</DialogContentText>
          </React.Fragment>
        }
        <TextField
          autoFocus
          margin="dense"
          fullWidth
          field="observacoes"
          multiline rows={4}
          useFormRef={useFormRef} schema={schema} />
      </DialogContent>
      <DialogActions>
        <Button onClick={onNao} color="inherit">
          NÃO
        </Button>
        <Button onClick={handleSubmit(onSim)} disabled={disabledSim}>
          SIM
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DialogoConfirmacao;
