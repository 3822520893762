import * as React from "react";
import * as yup from "yup";
import { pt } from "yup-locale-pt";
import HelpIcon from "@mui/icons-material/Help";
import { IconButton, Tooltip, Link } from "@mui/material";
import { DialogoDados } from "../../components";
import { categoriasTecnologia, opcoesBooleanas } from "../../static";

const createData = (ref, titulo, type, schema, extra = null) => {
  return {
    ref,
    titulo,
    type,
    schema,
    extra,
  };
};

const rows = [
  createData(
    "2.3",
    "Descrição do investimento em EE",
    "text",
    yup.string().nullable()
  ),
  createData(
    "2.4",
    "Categorias de tecnologia",
    "selectMultiple",
    yup.array().nullable(),
    {
      options: Object.keys(categoriasTecnologia).map((catId, idx) => ({
        id: catId,
        label: catId + ": " + categoriasTecnologia[catId],
      })),
    }
  ),
  createData(
    "2.5",
    "É preciso fazer Estudo de Impacto Ambiental?",
    "select",
    yup.number().positive().integer().nullable(),
    {
      options: opcoesBooleanas,
    }
  ),
  createData(
    "2.6",
    <b>Custo total do investimento em EE (R$) *</b>,
    "float",
    yup.number().positive().float().nullable()
  ),
  createData(
    "2.6.1",
    <React.Fragment>
      Custo total do investimento em EE (EUR)
      <Tooltip
        title={
          <React.Fragment>
            Ao inserir o valor do projeto em euros favor considerar a cotação do
            dia disponível em:
            <br />
            <Link
              target="_blank"
              rel="noopener"
              href="https://www.bcb.gov.br/conversao"
            >
              https://www.bcb.gov.br/conversao
            </Link>
          </React.Fragment>
        }
      >
        <HelpIcon />
      </Tooltip>
    </React.Fragment>,
    "float",
    yup.number().positive().float().nullable()
  ),
  createData(
    "2.8",
    "Período de implementação estimado (meses)",
    "integer",
    yup.number().positive().integer().nullable()
  ),
  createData("2.9", "Data de conclusão", "date", yup.date().nullable()),
  createData(
    "2.10",
    "Valor de investimento de fontes privadas",
    "float",
    yup.number().positive().float().nullable()
  ),
  createData(
    "3.3",
    "Investimento Elegível para EE de acordo com o " +
    "programa PotencializEE?",
    "select",
    yup.number().positive().integer().nullable(),
    {
      options: opcoesBooleanas,
    }
  ),
  createData(
    "3.4",
    <b>Valor esperado de economia com EE (KWh cumulativo-vida útil) *</b>,
    "integer",
    yup.number().positive().integer().nullable()
  ),
  createData(
    "3.5",
    <b>Porcentagem esperada de economia com EE (%) *</b>,
    "float",
    yup.number().positive().float().nullable()
  ),
  createData(
    "3.6",
    <b>
      Valor esperado de redução de emissões de gases de efeito estufa (T de CO2
      cumulativo-vida útil) *
    </b>,
    "integer",
    yup.number().positive().integer().nullable()
  ),
  createData(
    "3.7",
    <b>
      Porcentagem esperada de redução de emissões de gases de 'efeito estufa (%)
      *
    </b>,
    "float",
    yup.number().positive().float().nullable()
  ),
  createData(
    "3.8",
    <b>
      VPL da economia esperada de EE em relação à vida útil do investimento em
      EE (R$) *
    </b>,
    "float",
    yup.number().positive().float().nullable()
  ),
  createData(
    "3.9",
    "50% do custo do Investimento em EE coberto pelo VPL da " +
    "economia de EE",
    "select",
    yup.number().positive().integer().nullable(),
    {
      options: opcoesBooleanas,
    }
  ),
  createData(
    "4.3",
    "Nome Legal do Beneficiário Final",
    "text",
    yup.string().nullable()
  ),
  createData(
    "4.4",
    "Número/Código de Identificação do Beneficiário Final",
    "text",
    yup.string().nullable()
  ),
  createData(
    "4.5",
    "Categoria do Beneficiário Final",
    "text",
    yup.string().nullable()
  ),
  createData(
    "4.11",
    <b>Área do galpão industrial (em m2) *</b>,
    "integer",
    yup.number().positive().integer().nullable()
  ),
  createData(
    "4.12",
    <b>Número de funcionários do sexo feminino *</b>,
    "integer",
    yup.number().positive().integer().nullable()
  ),
  createData(
    "4.13",
    <b>Número de funcionários do sexo masculino *</b>,
    "integer",
    yup.number().positive().integer().nullable()
  ),
];

const DadosDiagnostico = ({
  dados,
  open,
  onClose,
  onSave,
  onCancel,
  disabled,
}) => {
  return DialogoDados({
    title: "Dados do Diagnóstico",
    rows: rows,
    open: open,
    onClose: onClose,
    onSave: onSave,
    onCancel: onCancel,
    defaultValues: dados,
    disabled: disabled,
  });
};

export default DadosDiagnostico;
