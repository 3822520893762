import React, { useState } from "react";
import api from "../../services/api";
import { Button, Box, Paper } from "@mui/material";
import { useStoreActions } from "easy-peasy";
import { CSVDownload } from "react-csv";
import { regioes, tagsRel } from "../../static";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import RelatorioWord from "./RelatorioWord";
import { cnpj } from "cpf-cnpj-validator";
import ExportacaoDadosRelatorioEnergetico from "./ExportacaoDadosDiagnosticoEnergetico";
import RelatorioGeral from "./RelatorioGeral";

const Relatorio = () => {
  let startLoading = useStoreActions((actions) => actions.nav.startLoading);
  let stopLoading = useStoreActions((actions) => actions.nav.stopLoading);
  const [exportaDados, setExportaDados] = React.useState([]);

  const audEnergeticaIniciada = [
    "diagIniciado",
    "diagReprovado",
    "finIniciado",
    "finReprovado",
    "finAprovado",
    "projetoIniciado",
    "projetoCadastrado",
    "emImplementacao",
    "confPrevBOT",
    "confPrevIf",
    "confPrevRP",
    "abaiPrevBOT",
    "abaiPrevIf",
    "abaiPrevRP"
  ];
  const audEnergeticaConcluida = [
    "diagReprovado",
    "finIniciado",
    "finReprovado",
    "finAprovado",
    "projetoIniciado",
    "projetoCadastrado",
    "emImplementacao",
    "confPrevBOT",
    "confPrevIf",
    "confPrevRP",
    "abaiPrevBOT",
    "abaiPrevIf",
    "abaiPrevRP"
  ];
  const implementacaoConcluida = ["confPrevBOT",
    "confPrevIf",
    "confPrevRP",
    "abaiPrevBOT",
    "abaiPrevIf",
    "abaiPrevRP"];
  const investimentoConcluido = [
    "finReprovado",
    "finAprovado",
    "projetoIniciado",
    "projetoCadastrado",
    "emImplementacao",
    "confPrevBOT",
    "confPrevIf",
    "confPrevRP",
    "abaiPrevBOT",
    "abaiPrevIf",
    "abaiPrevRP"
  ];
  const suporteDeimplementaçao = [
    "emImplementacao",
    "confPrevBOT",
    "confPrevIf",
    "confPrevRP",
    "abaiPrevBOT",
    "abaiPrevIf",
    "abaiPrevRP"
  ];

  const exportarDados = async () => {
    startLoading();
    try {
      let data = (await api.http.get("/relatorios/dados-agregado")).data;

      let data2 = (await api.http.get("/relatorios/dados-projeto")).data.map(
        (ref) => {
          let listaStr = JSON.parse(ref?.field_2_4);
          let listaFormatada = "";
          if (listaStr !== null) {
            listaFormatada = listaStr.join(", ");
          }
          return {
            ref_2_1: ref?.field_2_3,
            ref_2_2: audEnergeticaIniciada.includes(ref?.estagio)
              ? "SIM"
              : "NÃO",
            ref_2_5: audEnergeticaConcluida.includes(ref?.estagio)
              ? "SIM"
              : "NÃO",
            ref_2_6: ref?.aprovado_validador === 1 ? "SIM" : "NÃO",
            ref_2_7: suporteDeimplementaçao.includes(ref?.estagio)
              ? "SIM"
              : "NÃO",
            ref_2_10: implementacaoConcluida.includes(ref?.estagio)
              ? "SIM"
              : "NÃO",
            ref_2_11: listaFormatada,
            ref_2_12: ref?.field_2_5 == 1 ? "SIM" : "NÃO",
            ref_2_13: ref?.field_2_6_1,
            ref_2_15:
              regioes.find((regiao) => regiao.id === ref?.regiao_id)?.label ||
              "",
            ref_2_16: ref?.field_2_8,
            ref_2_17: ref?.field_2_9,
            ref_2_18: ref?.total,
            ref_2_19: ref?.field_2_10,
            ref_3_1: ref?.field_3_3 == 1 ? "SIM" : "NÃO",
            ref_3_2: ref?.field_3_4,
            ref_3_3: ref?.field_3_5,
            ref_3_4: ref?.field_3_6,
            ref_3_5: ref?.field_3_7,
            ref_3_6: ref?.field_3_8,
            ref_3_7: ref?.field_3_9 === 1 ? "SIM" : "NÃO",
            ref_3_8: investimentoConcluido.includes(ref?.estagio)
              ? "SIM"
              : "NÃO",
            ref_3_9: isNaN(parseFloat(ref?.field_2_6))
              ? undefined
              : parseFloat(ref?.field_2_6).toLocaleString("pt-BR", {
                style: "currency",
                currency: "BRL",
              }),
            ref_3_10: ref?.verificado_3_4,
            ref_3_11: ref?.verificado_3_5,
            ref_3_12: ref?.verificado_3_6,
            ref_3_13: ref?.verificado_3_7,
            ref_4_1: ref?.razao_social,
            ref_4_2: cnpj.format(ref?.cnpj),
            ref_4_3:
              regioes.find((regiao) => regiao.id === ref?.regiao_id)?.label ||
              "",
            ref_4_4: ref?.field_4_5,
            ref_4_5: ref?.cidade,
            ref_4_6: ref?.cep,
            ref_4_7: `${ref?.rua},${ref?.numero},${ref?.bairro},${ref?.cidade},${ref?.estado}`,
            ref_4_9: ref?.num_funcs,
            ref_4_10: ref?.setor_atuacao,
            ref_4_11: ref?.field_4_12,
            ref_4_12: ref?.field_4_13,
            ref_4_13: ref?.verificado_4_12,
            ref_4_14: ref?.verificado_4_13,
          };
        }
      );
      if (data && data2.length == 0) {
        stopLoading({
          message: "Nenhum registro encontrado.",
          severity: "error",
        });
      } else {
        setExportaDados([tagsRel.subHeaders, data, ...data2]);
        stopLoading();
        setExportaDados([]);
      }
    } catch (error) {
      stopLoading({
        message: error.toString(),
        severity: "error",
      });
    }
  };

  return (
    <Paper
      variant="outlined"
      className="center-box"
      sx={{
        textAlign: "center",
        px: 4,
        pt: 2,
        pb: 3,
        m: 5,
      }}
    >
      <Box
        className="center-box"
        display="flex"
        justifyContent="space-between"
        flexDirection="column"
        width={500}
        height={215}
      >
        <Button variant="contained" onClick={exportarDados} sx={{ height: 45 }}>
          <FileDownloadIcon /> Exportar Relatório (CSV)
        </Button>
        {exportaDados && exportaDados.length > 0 && (
          <CSVDownload
            data={exportaDados}
            headers={tagsRel.headers}
            target="_blank"
          />
        )}
        <RelatorioWord />
        <ExportacaoDadosRelatorioEnergetico />
        <RelatorioGeral />
      </Box>
    </Paper>
  );
};
export default Relatorio;
