import * as React from 'react';
import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Button,
  FormControl,
  CircularProgress,
  Paper,
  Grid
} from '@mui/material';
import api from "../../services/api";
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";
import { pt } from 'yup-locale-pt';
import {
  TextField,
  Select,
  Checkbox,
  CNPJField
} from '../../components';
import { useStoreState, useStoreActions } from 'easy-peasy';
import { categoriasTecnologia} from '../../static';

const DialogoInstFinanceira = ({
  open, onClose, tecnologias, regioes, instFinanceira
}) => {
  let loading = useStoreState((state) => state.nav.loading);
  let startLoading = useStoreActions((actions) => actions.nav.startLoading);
  let stopLoading = useStoreActions((actions) => actions.nav.stopLoading);
  const defaultValues = {
    nome: '',
    responsavel: '',
    email: '',
    cnpj: '',
    habilitado: true
  };
  const schema = yup.object({
    nome: yup.string().label('Nome da empresa'),
    responsavel: yup.string().label('Nome do responsável'),
    email: yup.string().label('Email do responsável'),
    cnpj: yup.string().cnpj().label('CNPJ').required(),
    habilitado: yup.boolean().label('Habilitado').required()
  }).required();
  const useFormRef = useForm({
    mode: 'onTouched',
    resolver: yupResolver(schema),
    defaultValues: defaultValues
  });

  const { handleSubmit, reset, formState: {isDirty} } = useFormRef;

  const onSave = async (data) => {
    startLoading();
    try {
      if(data.id) {
        await api.http.put('/inst-financeiras/'+data.id,data);
      } else {
        await api.http.post('/inst-financeiras',data);
      }
      stopLoading({ severity: 'success'});
      close(true);
    } catch (error) {
      stopLoading({
        message: error.toString(),
        severity: 'error'
      });
      throw error;
    };
  };

  const close = (reload=false) => {
    reset(defaultValues);
    onClose(reload);
  };

  React.useEffect(()=> {
    reset(instFinanceira);
  },[instFinanceira]);


  return (
    <Dialog open={open} onClose={()=>close()}>
      <DialogTitle>Dados da Instituição Financeira</DialogTitle>
      <DialogContent dividers>
        <FormControl sx={{ my: 2 }} fullWidth>
          <TextField
            margin="dense"
            fullWidth
            field="nome"
            useFormRef={useFormRef} schema={schema} />
        </FormControl>
        <FormControl sx={{ my: 2 }} fullWidth>
          <TextField
            margin="dense"
            fullWidth
            field="responsavel"
            useFormRef={useFormRef} schema={schema} />
        </FormControl>
        <FormControl sx={{ my: 2 }} fullWidth>
          <TextField
            margin="dense"
            fullWidth
            field="email"
            type="email"
            useFormRef={useFormRef} schema={schema} />
        </FormControl>
        <FormControl sx={{ my: 2 }} fullWidth>
          <CNPJField
            margin="dense"
            fullWidth
            field="cnpj"
            useFormRef={useFormRef} schema={schema} />
        </FormControl>
        <FormControl sx={{ my: 2 }} fullWidth>
          <Checkbox field="habilitado"
                    useFormRef={useFormRef} schema={schema} />
        </FormControl>
      </DialogContent>
      <DialogActions>
        { loading>0 && <CircularProgress
                         size={30}
                         sx={{
                           'position': 'absolute',
                           'left':25,
                           'bottom': 12 }} /> }
        <Button color="inherit" onClick={()=>close()}>
          CANCELAR
        </Button>
        <Button color="primary"
                onClick={handleSubmit(onSave)} disabled={!isDirty}>
          SALVAR
        </Button>
      </DialogActions>
      </Dialog>

  );
};


export default DialogoInstFinanceira;
