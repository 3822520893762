import * as React from 'react';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Fab,
  IconButton,
  CircularProgress
} from '@mui/material';
import PropTypes from 'prop-types';
import CloseIcon from '@mui/icons-material/Close';
import AddIcon from '@mui/icons-material/Add';
import {
  listaEnergeticos,
  subsegmentos,
  listaProcessos,
  sistemas
} from '../static';
import api from "../services/api";
import { useStoreState, useStoreActions } from 'easy-peasy';
import MaterialTable from '@material-table/core';
import {
  materialTableLocalization,
  materialTableCurrencySettings
} from '../config';
import DialogoBenchmarking from './DialogoBenchmarking';

const BootstrapDialogTitle = (props) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};
BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

const DadosBenchmarking = ({processo, open, onClose, disabled, final=false}) => {
  let startLoading = useStoreActions((actions) => actions.nav.startLoading);
  let stopLoading = useStoreActions((actions) => actions.nav.stopLoading);
  let loading = useStoreState((state) => state.nav.loading);
  const [openDlgBenchmarking, setOpenDlgBenchmarking] = React.useState(false);
  const [rows, setRows] = React.useState([]);
  const [benchmarking, setBenchmarking] = React.useState(null);

  const loadBenchmarking = async () => {
    startLoading();
    try {
      setRows(
        (await api.http.get('/benchmarkings',{
          params: {
            processoId: processo.id,
            final: final
          }
        })).data.data
      );
      stopLoading();
    } catch (error) {
      stopLoading({
        message: error.toString(),
        severity: 'error'
      });
    };
  };

  React.useEffect(async () => {
    loadBenchmarking();
  },[]);

  const onCloseDlgBenchmarking = (reload=false) => {
    setBenchmarking(null);
    setOpenDlgBenchmarking(false);
    if(reload) {
      loadBenchmarking();
    }
  };

  const onRowClick = (event,rowData) => {
    setBenchmarking(rowData);
    setOpenDlgBenchmarking(true);
  };

  return (
    <Dialog
      open={open}
      fullWidth={true}
      maxWidth={'xl'}>
      <BootstrapDialogTitle id="customized-dialog-title" onClose={onClose}>
      </BootstrapDialogTitle>
      <DialogContent>
      <DialogoBenchmarking
        benchmarking={benchmarking}
        processo={processo}
        final={final}
        open={openDlgBenchmarking}
        onClose={onCloseDlgBenchmarking}  />
        <MaterialTable
          columns={[
            {
              field: 'subsegmento', title: 'Sub-segmento',
              lookup: subsegmentos[processo.setorAtuacaoId]
                .reduce((map,subsegmento)=>{
                  map[subsegmento.id] = subsegmento.label;
                  return map;
                },{})
            },{
              field: 'processoBenchmarking', title: 'Processo',
              lookup: listaProcessos.reduce((map,processo)=>{
                map[processo.id] = processo.label;
                return map;
              },{})
            },{
              field: 'sistema', title: 'Sistema',
              lookup: Object.keys(sistemas).reduce((map,processo)=>{
                map = sistemas[processo].reduce((map2,sistema)=>{
                  map2[sistema.id] = sistema.label;
                  return map2;
                },map);
                return map;
              },{})
            },{
              field: 'producaoAnual', title: 'Produção Anual (ton)'
            },{
              field: 'energeticoUtilizado', title: 'Energético Utilizado',
              lookup: listaEnergeticos.reduce((map,energetico)=>{
                map[energetico.id] = energetico.label;
                return map;
              },{})
            },{
              field: 'consumoAnual', title: 'Consumo Anual Inicial (MWh)'
            },{
              field: 'consumoAnualFinal', title: 'Consumo Anual Final (MWh)'
            },{
              field: 'investimentoEstimado',
              title: 'Investimento Estimado (R$)',
              type: 'currency',
              currencySetting: materialTableCurrencySettings
            },{
              field: 'custoEnergetico', title: 'Custo do Energético (R$/MWh)',
              type: 'currency',
              currencySetting: materialTableCurrencySettings
            }
          ]}
          data={rows}
          title="Dados de Benchmarking"
          onRowClick={onRowClick}
          localization={materialTableLocalization}
        />
      </DialogContent>
      <DialogActions>
        { loading>0 && <CircularProgress
                         size={30}
                         sx={{
                           'position': 'absolute',
                           'left':25,
                           'bottom': 12 }} /> }
        { !disabled &&
          <Fab color="primary" aria-label="novo fornecedor"
               onClick={()=>{
                 setOpenDlgBenchmarking(true);
               }}>
            <AddIcon />
          </Fab>
        }
      </DialogActions>
    </Dialog>
  );
};

export default DadosBenchmarking;
