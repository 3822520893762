import React, { useEffect } from 'react';
import { Grid, Box, Typography } from "@mui/material";
import './ModalRelResumo.css';
import { Pie } from 'react-chartjs-2';
import ChartDataLabels from "chartjs-plugin-datalabels";
import { Chart as ChartJS, ArcElement, Tooltip } from 'chart.js';
ChartJS.register(ArcElement, Tooltip, ChartDataLabels);

const RelatorioResultadoChartConsumo = ({ lstQuestionario, lstRespostas }) => {
  const [seriesData, setSeriesData] = React.useState([]);
  const [totais, setTotais] = React.useState(0);

  const cores = [
    '#00ff00', '#F0E68C', '#4169E1',
    '#008000', '#293896', '#6495ED',
    '#62BB46', '#B0E0E6', '#F39325', '#4682B4',
    '#00BFFF', '#FF8C00', '#81D3EB', '#0000CD',

  ];

  const data = {
    labels: seriesData.map((item) => item.label),
    datasets: [
      {
        data: seriesData.map((item) => item.value),
        backgroundColor: cores,
      },
    ],
  };

  const options = {
    plugins: {
      datalabels: {
        formatter: (value, context) => {
          const total = context.dataset.data.reduce((a, b) => parseFloat(a) + parseFloat(b), 0);
          const percentage = ((value / total) * 100).toFixed(1);
          return `${percentage}%`;
        },
        color: '#000000',
        font: {
          weight: 'bold',
        },
        offset: 20,
        rotation: 0,
        anchor: 'center',
        align: 'center',
      },
      legend: {
        display: false, // Desabilita a legenda incorporada
        formatter: (value, context) => {
          const total = context.dataset.data.reduce((a, b) => parseFloat(a) + parseFloat(b), 0);
          const percentage = ((value / total) * 100).toFixed(1);
          return `${percentage}%`;
        },
      },
    },
  };

  const addToTotal = (value) => {
    setTotais((prevTotal) => prevTotal + value);
    // return context.dataset.data.reduce((a, b) => parseFloat(a) + parseFloat(b), 0);
  };

  const adicionarItem = (item) => {
    setSeriesData((dadosAtuais) => [...dadosAtuais, item]);
  };

  const getEnergiaEletrica = () => {
    const titulo = "Eletricidade";
    const mwh_ano = lstRespostas.prediagnostico["general-data_2_7"];
    const FatorEmissao = 1;

    addFonteResumo(titulo, Math.ceil(mwh_ano * FatorEmissao / 1000));
  };

  useEffect(() => {
    getEnergiaEletrica();

    if (lstRespostas.prediagnostico["general-data_3_1"] === true) { montaResumoFonte('fuel-data-1', 'Bagaço de cana', 2475.53) };
    if (lstRespostas.prediagnostico["general-data_3_2"] === true) { montaResumoFonte('fuel-data-2', 'Bio diesel (B100)', 9.20) };
    if (lstRespostas.prediagnostico["general-data_3_3"] === true) { montaResumoFonte('fuel-data-3', 'Bio gás', 6.97) };
    if (lstRespostas.prediagnostico["general-data_3_4"] === true) { montaResumoFonte('fuel-data-4', 'Carvão', 3428.55) };
    //if (lstRespostas.prediagnostico["general-data_3_5"] === true) { montaResumoFonte('fuel-data-5', 'Carvão vegetal', 1) };
    if (lstRespostas.prediagnostico["general-data_3_5"] === true) { montaResumoFonteLenha('fuel-data-5', 'Carvão vegetal', 7507.95, 0.024, 0.408096) };
    // if(lstRespostas.prediagnostico["general-data_3_6"] === true){montaResumoFonte('fuel-data-6', 'Eletricidade', 0.0860)};
    if (lstRespostas.prediagnostico["general-data_3_7"] === true) { montaResumoFonte('fuel-data-7', 'Gás natural', 10.23) };
    if (lstRespostas.prediagnostico["general-data_3_8"] === true) { montaResumoFonte('fuel-data-8', 'Gasolina', 12.09) };
    if (lstRespostas.prediagnostico["general-data_3_9"] === true) { montaResumoFonte('fuel-data-9', 'GLP', 12.90) };
    //if (lstRespostas.prediagnostico["general-data_3_10"] === true) { montaResumoFonte('fuel-data-10', 'Lenha', 1405.13) };
    if (lstRespostas.prediagnostico["general-data_3_10"] === true) { montaResumoFonteLenha('fuel-data-10', 'Lenha (m³)-umidade normal(43%)', 2522.021197, 0.0068, 0.3685212) };
    if (lstRespostas.prediagnostico["general-data_3_11"] === true) { montaResumoFonteLenha('fuel-data-11', 'Lenha (m³)-baixa umidade(36%)', 2562.698958, 0.0068, 0.3685212) };
    if (lstRespostas.prediagnostico["general-data_3_12"] === true) { montaResumoFonteLenha('fuel-data-12', 'Lenha (t)-umidade normal(43%)', 3602.89, 0.0068, 0.3685212) };
    if (lstRespostas.prediagnostico["general-data_3_13"] === true) { montaResumoFonteLenha('fuel-data-13', 'Lenha (t)-baixa umidade(36%)', 3661.00, 0.0068, 0.3685212) };
    // .end-> lenha
    if (lstRespostas.prediagnostico["general-data_3_14"] === true) { montaResumoFonte('fuel-data-14', 'Óleo combustível', 11145.71) };
    if (lstRespostas.prediagnostico["general-data_3_15"] === true) { montaResumoFonte('fuel-data-15', 'Óleo diesel', 11.74) };
    if (lstRespostas.prediagnostico["general-data_3_16"] === true) { montaResumoFonte('fuel-data-16', 'Querosene', 12.90) };
    if (lstRespostas.prediagnostico["general-data_3_17"] === true) { montaResumoFonte('fuel-data-17', 'Sol', 1) };
  }, []);

  // const addFonteResumo = (fonte, un_ano, mwh_ano, rs_ano, eeco2) => {
  const addFonteResumo = (fonte, mwh_ano) => {

    const fonteExiste = seriesData.some((item) => item.label === fonte);
    if (!fonteExiste) {
      addToTotal(mwh_ano);
      const novoItem = { value: mwh_ano, label: fonte };
      adicionarItem(novoItem);
    }
  };

  const getQuestionsAndAnswers = (groupName) => {
    const questionsAndAnswers = [];
    lstQuestionario.steps.forEach((step) => {
      step.groups?.forEach((group) => {
        if (group.name === groupName) {
          group.questions?.forEach((question) => {
            questionsAndAnswers.push({
              questionName: question.name,
              questionDescription: question.description,
              questionAnswer: question.answer,
              questionValidation: question.validation.type
            });
          });
        }
      });
    });
    return questionsAndAnswers;
  };

  const montaResumoFonteLenha = (grupo, titulo, FatorEmissao) => {
    let index = 0;
    let mwh_ano = 0;
    const questionAndAnswers = getQuestionsAndAnswers(grupo);
    questionAndAnswers.forEach((item) => {

      if (index === 2) {
        mwh_ano = lstRespostas.prediagnostico[item.questionName] || 0;

      }

      index += 1;
    });
    addFonteResumo(titulo, Math.ceil(mwh_ano * FatorEmissao / 1000));
  };

  const montaResumoFonte = (grupo, titulo, FatorEmissao) => {
    let index = 0;
    let mwh_ano = 0;
    const questionAndAnswers = getQuestionsAndAnswers(grupo);
    questionAndAnswers.forEach((item) => {

      if (index === 1) {
        mwh_ano = lstRespostas.prediagnostico[item.questionName] || 0;

      }

      index += 1;
    });
    addFonteResumo(titulo, Math.ceil(mwh_ano * FatorEmissao / 1000));
  };

  return (
    <div className='grid-space-container'>
      <Grid container spacing={0} className='grid-space-container' sx={{
        pageBreakInside: 'avoid'
      }}>
        <Box sx={{ width: '80%', marginTop: '20px' }}>
          <Typography variant="h6" gutterBottom>
            Percentual de consumo de cada fonte energética
          </Typography>
        </Box>
        <div style={{ width: '250px', heigth: '250px', margin: 'auto' }}>
          <Pie data={data} options={options} width={50} />
        </div>
      </Grid>
      <div className='legend-container'>
        {data.labels.map((label, index) => (
          <div key={index} className='legend-item'>
            <span className='legend-color' style={{ backgroundColor: cores[index] }}></span>
            <span>{label} - {data.datasets[0].data[index].toFixed(1)} - {((data.datasets[0].data[index] / totais) * 100).toFixed(1)}%</span>
          </div>
        ))}
      </div>
    </div>
  );
};

export default RelatorioResultadoChartConsumo;
