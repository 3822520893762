import React, {useState} from 'react';
import {
  TextField,
  Divider,
  Grid,
  FormControl
}  from '@mui/material';

const Timestamps = ({dados}) => {

  if(!dados)
    return '';

  const dataValue = (campo) => {
    return (campo)? new Date(campo).toLocaleString():'';
  };

  return (
    <React.Fragment>
      <Divider>
        <h3>Timestamps</h3>
      </Divider>
      <Grid container spacing={2}>
        <Grid item xs={4}>
          <FormControl sx={{ my: 2 }} fullWidth>
            <TextField label="Início" disabled={true}
                       InputLabelProps={{ shrink: true }}
                       value={dataValue(dados?.createdAt)}/>
          </FormControl>
        </Grid>
        <Grid item xs={4}>
          <FormControl sx={{ my: 2 }} fullWidth>
            <TextField label="Fim" disabled={true}
                       InputLabelProps={{ shrink: true }}
                       value={dataValue(dados?.finishedAt)}/>
          </FormControl>
        </Grid>
        <Grid item xs={4}>
          <FormControl sx={{ my: 2 }} fullWidth>
            <TextField label="Última alteração" disabled={true}
                       InputLabelProps={{ shrink: true }}
                       value={dataValue(dados?.updatedAt)}/>
          </FormControl>
        </Grid>
      </Grid>
    </React.Fragment>
  );
};

export default Timestamps;
