import * as React from 'react';
import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Button,
  FormControl,
  CircularProgress,
  Paper,
  Grid,
  Checkbox as MUICheckbox
} from '@mui/material';
import api from "../../services/api";
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";
import { pt } from 'yup-locale-pt';
import {
  TextField,
  Select,
  Checkbox,
  CNPJField,
  Autocomplete
} from '../../components';
import { useStoreState, useStoreActions } from 'easy-peasy';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import { listaEnergeticos } from '../../static';
const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const DialogoFornecedor = ({
  open, onClose, fornecedor
}) => {
  let loading = useStoreState((state) => state.nav.loading);
  let startLoading = useStoreActions((actions) => actions.nav.startLoading);
  let stopLoading = useStoreActions((actions) => actions.nav.stopLoading);
  const [tecnologias, setTecnologias] = React.useState([]);
  const [regioes, setRegioes] = React.useState([]);
  const [categorias, setCategorias] = React.useState([]);
  const defaultValues = {
    nome: '',
    responsavel: '',
    email: '',
    cnpj: '',
    habilitado: true,
    regioes: [],
    tecnologias: []
  };
  const schema = yup.object({
    nome: yup.string().label('Nome da empresa').required(),
    responsavel: yup.string().label('Nome do responsável').required(),
    email: yup.string().email().label('Email do responsável').required(),
    cnpj: yup.string().cnpj().label('CNPJ').required(),
    tecnologias: yup.array(yup.number().positive().integer())
      .min(1).nullable().label('Tecnologia').required(),
    regioes: yup.array(yup.number().positive().integer())
      .min(1).nullable().label('Região').required(),
    habilitado: yup.boolean().label('Habilitado').required()
  }).required();
  const useFormRef = useForm({
    mode: 'onTouched',
    resolver: yupResolver(schema),
    defaultValues: defaultValues
  });

  // load categorias, tecnologia e regiões
  React.useEffect(async () => {
    let mount = {
      isMounted: true
    };
    loadCategorias(mount);
    loadTecnologias(mount);
    loadRegioes(mount);
    return ()=>{mount.isMounted = false;};
  },[]);

  const loadCategorias = async(mount) => {
    startLoading();
    try {
      let data = (await api.http.get('/categorias')).data;
      if(mount.isMounted) {
        setCategorias(data);
      }
      stopLoading();
    } catch (error) {
      stopLoading({
        message: error.toString(),
        severity: 'error'
      });
    }
  };

  // load tecnologias
  const loadTecnologias = async(mount) => {
    startLoading();
    try {
      let data = (await api.http.get('/tecnologias')).data;
      if(mount.isMounted) {
        setTecnologias(data);
      }
      stopLoading();
    } catch (error) {
      stopLoading({
        message: error.toString(),
        severity: 'error'
      });
    }
  };

  // load regiões
  const loadRegioes = async(mount) => {
    startLoading();
    try {
      let data = (await api.http.get('/regioes')).data;
      if(mount.isMounted) {
        setRegioes(data);
      }
      stopLoading();
    } catch (error) {
      stopLoading({
        message: error.toString(),
        severity: 'error'
      });
    }
  };



  const { handleSubmit, reset, formState: {isDirty} } = useFormRef;

  const onSave = async (data) => {
    startLoading();
    try {
      if(data.id) {
        await api.http.put('/fornecedores/'+data.id,data);
      } else {
        await api.http.post('/fornecedores',data);
      }
      stopLoading({ severity: 'success'});
      close(true);
    } catch (error) {
      stopLoading({
        message: error.toString(),
        severity: 'error'
      });
      throw error;
    };
  };

  const close = (reload=false) => {
    reset(defaultValues);
    onClose(reload);
  };

  React.useEffect(()=> {
    if(fornecedor) {
      reset({
        ...fornecedor,
        regioes: fornecedor.regiao.map(regiao=>regiao.id),
        tecnologias: fornecedor.tecnologia.map(tecnologia=>tecnologia.id)
      });
    }
  },[fornecedor]);

  return (
    <Dialog open={open} onClose={()=>close()}>
      <DialogTitle>Dados do fornecedor</DialogTitle>
      <DialogContent dividers>
        <FormControl sx={{ my: 2 }} fullWidth>
          <TextField
            margin="dense"
            fullWidth
            field="nome"
            useFormRef={useFormRef} schema={schema} />
        </FormControl>
        <FormControl sx={{ my: 2 }} fullWidth>
          <TextField
            margin="dense"
            fullWidth
            field="responsavel"
            useFormRef={useFormRef} schema={schema} />
        </FormControl>
        <FormControl sx={{ my: 2 }} fullWidth>
          <TextField
            margin="dense"
            fullWidth
            field="email"
            type="email"
            useFormRef={useFormRef} schema={schema} />
        </FormControl>
        <FormControl sx={{ my: 2 }} fullWidth>
          <CNPJField
            margin="dense"
            fullWidth
            field="cnpj"
            useFormRef={useFormRef} schema={schema} />
        </FormControl>
        <FormControl sx={{ my: 2 }} fullWidth>
          <Autocomplete
            multiple
            disableCloseOnSelect
            field="tecnologias"
            useFormRef={useFormRef}
            schema={schema}
            options={tecnologias}
            renderOption={(props, option, {selected}) =>  (
              <li {...props}>
                <MUICheckbox
                  icon={icon}
                  checkedIcon={checkedIcon}
                  style={{ marginRight: 8 }}
                  checked={selected}
                />
                {props.key}
              </li>
            )}
            groupBy={(option)=> 'Categoria ' +
                     option.categoria
                     + ': ' +
                     categorias.filter((categoria) =>
                                       categoria.id == option.categoria)
                     .map((label)=>label.nome)
                    }
          />
        </FormControl>
        <FormControl sx={{ my: 2 }} fullWidth>
          <Autocomplete
            multiple
            disableCloseOnSelect
            field="regioes"
            useFormRef={useFormRef}
            schema={schema}
            options={regioes}
            renderOption={(props, option, {selected}) =>  (
              <li {...props}>
                <MUICheckbox
                  icon={icon}
                  checkedIcon={checkedIcon}
                  style={{ marginRight: 8 }}
                  checked={selected}
                />
                {props.key}
              </li>
            )}
          />
        </FormControl>
        <FormControl sx={{ my: 2 }} fullWidth>
          <Checkbox field="habilitado"
                    useFormRef={useFormRef} schema={schema} />
        </FormControl>
      </DialogContent>
      <DialogActions>
        { loading>0 && <CircularProgress
                         size={30}
                         sx={{
                           'position': 'absolute',
                           'left':25,
                           'bottom': 12 }} /> }
        <Button color="inherit" onClick={()=>close()}>
          CANCELAR
        </Button>
        <Button color="primary"
                onClick={handleSubmit(onSave)} disabled={!isDirty}>
          SALVAR
        </Button>
      </DialogActions>
      </Dialog>

  );
};


export default DialogoFornecedor;
