import { yupResolver } from "@hookform/resolvers/yup";
import {
  Box,
  Button,
  DialogContentText,
  Divider,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  Paper,
  Radio,
  RadioGroup,
  TextField,
} from "@mui/material";
import { useStoreActions, useStoreState } from "easy-peasy";
import React, { useEffect, useMemo } from "react";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { pt } from "yup-locale-pt";
import {
  Checkbox,
  DialogoConfirmacao,
  DialogoConfirmacaoSimples,
  FileField,
  FileListField,
  Select,
  StepByStepPreDiagnostico2,
  Timestamps,
} from "../../components";
import api from "../../services/api";

import {
  motivosPMENaoContratarDiagnostico,
  selecionarPMEVaiContratarDiagnostico,
  opcoesBooleanas,
} from '../../static';
import is from "date-fns/locale/is";
import checkFinishReport from "../../store/checkFinishReport";

yup.setLocale(pt);

const VisitaTecnica = () => {
  let user = useStoreState((state) => state.auth.user);
  let finishReportGenerate = useStoreState((state) => state.checkFinishReport.finish)
  let currProcesso = useStoreState((state) => state.processo.current);
  let selectProcesso = useStoreActions((actions) => actions.processo.select);
  let startLoading = useStoreActions((actions) => actions.nav.startLoading);
  let stopLoading = useStoreActions((actions) => actions.nav.stopLoading);
  const [listaTecnicos, setListaTecnicos] = React.useState([]);
  const [listaScrumMasters, setListaScrumMasters] = React.useState([]);
  const [visitaTecnica, setVisitaTecnica] = React.useState(null);
  const [openIsUseGenerateReport, setOpenIsUseGenerateReport] = React.useState(false);
  //const [dataPreDiagnostico, setDataPreDiagnostico] = React.useState(null);
  //const [isChangeReportGenerate, setIsChangeReportGenerate] = React.useState(null);

  const [exibirScrumMaster, setExibirScrumMaster] = React.useState(true);

  const schema = yup
    .object({
      tecnicoId: yup.number().positive().nullable().label("Escolha um técnico"),
      scrumMasterId: yup.number().positive().nullable().label("Escolha um Especialista Lider"),
      scrumNotApply: yup.number().nullable().label('Esse projeto possui um especialista líder alocado?'),
      preDiagnosticoId: yup
        .number()
        .positive()
        .nullable()
        .label("Pré-diagnóstico"),
      pendenciasId: yup.number().positive().nullable().label("Pendências"),
      dreId: yup.number().nullable().label("DRE mais recente"),
      balancoId: yup.number().nullable().label("Balanço mais recente"),
      docsTecnicosField: yup
        .array()
        .label("Arraste documentos técnicos aqui ou clique"),
      faturasEnergiaField: yup
        .array()
        .label("Arraste 12 últimas faturas de energia elétrica"),
      faturasEnergeticosField: yup
        .array()
        .label("Arraste as faturas dos energéticos"),
      cartaoCnpjId: yup
        .number()
        .positive()
        .nullable()
        .label("Cartão do CNPJ (emissão máxima de 30 dias)"),
      certNegInssId: yup
        .number()
        .positive()
        .nullable()
        .label("Certidão negativa de débito expedida pelo INSS"),
      certRegFgtsId: yup
        .number()
        .positive()
        .nullable()
        .label("Certificado de regularidade do FGTS"),
      certNegDebtTrabId: yup
        .number()
        .positive()
        .nullable()
        .label("Certidão negativa de débitos trabalhistas"),
      certNegDebtEstId: yup
        .number()
        .positive()
        .nullable()
        .label("Certidão negativa de débitos estaduais"),
      pendenciasSanadas: yup.boolean().label("Pendências sanadas"),
      relatorioId: yup.number().positive().nullable().label("Relatório"),
      outrosDocsField: yup
        .array()
        .label("Arraste outros documentos aqui ou clique"),
      isUseGenerateReport: yup.boolean().nullable().label('Não utilizar o relatório gerado'),
      isReportDeliveredPME: yup.boolean().nullable().label('Pré-diagnóstico entregue para PME'),
      isContractedDiag: yup.number().nullable().label('Selecione se a PME vai contratar o Diagnóstico'),
      isPMENaoContratou: yup.number().nullable().label('Favor elencar o motivo da não contratação do Diagnóstico'),
    })
    .required();

  // console.log("finishReportGenerate: ", finishReportGenerate);

  const defaultValues = {
    tecnicoId: "",
    preDiagnosticoId: null,
    pendenciasId: null,
    relatorioId: null,
    outrosDocsField: [],
    dreId: null,
    balancoId: null,
    cartaoCnpjId: null,
    certNegInssId: null,
    certRegFgtsId: null,
    certNegDebtTrabId: null,
    certNegDebtEstId: null,
    isPMENaoContratou: 0,
    scrumNotApply: null,
  };

  const useFormRef = useForm({
    mode: "onTouched",
    resolver: yupResolver(schema),
    defaultValues: defaultValues,
  });

  const {
    handleSubmit,
    watch,
    reset,
    getValues,
    setValue,
    formState: { isDirty, isSubmitting },
  } = useFormRef;

  const scrumMasterId = watch("scrumMasterId");
  const tecnicoId = watch("tecnicoId");
  const isPMENaoContratou = watch("isPMENaoContratou");
  const isContractedDiag = watch("isContractedDiag");
  const isUseGenerateReport = watch("isUseGenerateReport");
  const isReportDeliveredPME = watch("isReportDeliveredPME");
  //const relatorioId = watch("relatorioId");
  //const pendenciasSanadas = watch("pendenciasSanadas");
  const scrumNotApply = watch("scrumNotApply");

  const [aprovando, setAprovando] = React.useState([]);
  const [confirmacaoMsg, setConfirmacaoMsg] = React.useState([]);

  const [isReadtoclose, setIsReadtoclose] = React.useState(0);

  const [isreportGenerate, setIsreportGenerate] = React.useState(false);

  // const [exibirScrumNotApply, setExibirScrumNotApply] = React.useState(0);

  const verificaPreenchimento = () => {
    let erros = [];

    if (isUseGenerateReport
    )
      if (!getValues("relatorioId")) erros.push("Inclusão de relatório");

    if (erros.length > 0)
      return (
        <React.Fragment>
          <DialogContentText>
            Ainda não foram realizadas as seguintes ações:
          </DialogContentText>
          <ul>
            {erros.map((erro, index) => (
              <li key={index}>
                <DialogContentText>{erro}</DialogContentText>
              </li>
            ))}
          </ul>
        </React.Fragment>
      );
    return "";
  };

  const aprova = (data) => {
    setAprovando(true);
    setConfirmacaoMsg(
      <React.Fragment>
        {verificaPreenchimento()}
        <DialogContentText>
          Esta ação não pode ser desfeita. Tem certeza que deseja <b>aprovar</b>{" "}
          para o diagnóstico energético?
        </DialogContentText>
      </React.Fragment>
    );
    setOpenConfirmacao(true);
  };

  const recusa = () => {
    setAprovando(false);
    setConfirmacaoMsg(
      <React.Fragment>
        Esta ação não pode ser desfeita. Tem certeza que deseja <b>reprovar</b>{" "}
        para diagnóstico energético?
      </React.Fragment>
    );
    setOpenConfirmacao(true);
  };

  const desfazerRecusa = () => {
    setOpenConfirmaDesfRecusa(true);
  };

  const salva = async (data) => {

    startLoading();
    if (visitaTecnica?.reportGenerate
      && isContractedDiag === 2
      && (isPMENaoContratou === undefined || isPMENaoContratou === null || isPMENaoContratou === "")) {
      stopLoading({
        message: "Informe o motivo da não contratação do Diagnóstico",
        severity: "error",
      });
    }
    else {
      try {
        let retorno;
        if (data.id) {
          retorno = (await api.http.put("/visitas_tecnicas/" + data.id, data))
            .data;
        } else {
          console.log("salvar o scrum master ");
          retorno = (
            await api.http.post("/visitas_tecnicas", {
              ...data,
              processoId: currProcesso.id,
            })
          ).data;
        }

        stopLoading({ severity: "success" });
        if (retorno.visitaTecnica.isReadtoclose === 1) {
          setIsReadtoclose(1);
        };
        reset(retorno.visitaTecnica);
        setVisitaTecnica(retorno.visitaTecnica);
        selectProcesso(retorno.processo);
      } catch (error) {
        stopLoading({
          message: error.toString(),
          severity: "error",
        });
        throw error;
      }
    }
  };

  const cancela = () => {
    reset();
  };

  const [openConfirmacao, setOpenConfirmacao] = React.useState(false);

  const onSimConfirma = async (data) => {
    setOpenConfirmacao(false);
    const confirmaData = {
      ...data,
      estagio: aprovando ? "preDiagAprovado" : "preDiagReprovado",
    };
    startLoading();
    try {
      let post = (
        await api.http.put(
          "/visitas_tecnicas/" + visitaTecnica.id,
          confirmaData
        )
      ).data;

      if (post.visitaTecnica.isReadtoclose === 1) {
        setIsReadtoclose(1);
      };

      if (currProcesso) {
        setValue("scrumNotApply", currProcesso.scrumNotApply);
      }

      selectProcesso(post.processo);
      setVisitaTecnica(post.visitaTecnica); //TODO algo se perdendo por aqui que a tela atualiza o processo mas perde o pré-diagnóstico carregado até o próximo rebuild
      reset(post.visitaTecnica);
      stopLoading({ severity: "success" });
    } catch (error) {
      stopLoading({
        message: error.toString(),
        severity: "error",
      });
      throw error;
    }
  };
  const onNaoConfirma = () => {
    setOpenConfirmacao(false);
  };

  const [openConfirmaDesfRecusa, setOpenConfirmaDesfRecusa] =
    React.useState(false);

  const onConfirmaDesfRecusa = async (data) => {
    setOpenConfirmaDesfRecusa(false);
    const confirmaData = {
      estagio: "preDiagIniciado",
    };
    startLoading();
    try {
      let put = (
        await api.http.put(
          "/visitas_tecnicas/" + visitaTecnica.id,
          confirmaData
        )
      ).data;
      if (put.visitaTecnica.isReadtoclose === 1) {
        setIsReadtoclose(1);
      };
      selectProcesso(put.processo);
      reset(put.visitaTecnica);
      setVisitaTecnica(put.visitaTecnica);
      stopLoading({ severity: "success" });
    } catch (error) {
      stopLoading({
        message: error.toString(),
        severity: "error",
      });
      throw error;
    }
  };

  const onNaoConfirmaDesfRecusa = () => {
    setOpenConfirmaDesfRecusa(false);
  };

  const disabledAlocacao = () => {

    //testar se é admin então permite selecionar
    if (currProcesso.scrumNotApply === 0) {
      return true;
    }
    else
      if ((user.roleId === "superAdmin")
        && ["preDiagIniciado", "inicioAprovado"].includes(
          currProcesso.estagio
        )) {
        return false;
      }
      //se não, se for 
      else
        if (0 === user.id) {
          return false;
        }
        else
          return true;
  };


  const disabled = () => {

    // console.log("estagio: ", currProcesso.estagio);

    if (!["preDiagIniciado", "inicioAprovado"].includes(currProcesso.estagio)
      && ["superAdmin"].includes(user.roleId)
    ) {
      console.log("entrou aqui linha 380");
      return false;
    }

    if (!["preDiagIniciado", "inicioAprovado"].includes(
      currProcesso.estagio
    )) {
      console.log("entrou aqui linha 387");
      return true;
    }
    else {
      // console.log("entrou aqui linha 390");

      if (["scrumMaster", "superAdmin", "consultorEE"].includes(user.roleId)
        && ["preDiagIniciado", "inicioAprovado"].includes(currProcesso.estagio))
        return false;
      else
        return (
          (user.roleId !== "superAdmin" &&
            !["preDiagIniciado", "inicioAprovado"].includes(
              currProcesso.estagio
            )) ||
          (user.roleId === "consultorEE" && tecnicoId !== user.id) ||
          ![
            "consultorEE",
            "gestorSENAI",
            "superAdmin",
            "validadorIndSENAI",
          ].includes(user.roleId)
        );
    }
  };


  useEffect(() => {
    const fetchData = async () => {
      try {
        if (currProcesso.estagio !== "inicioAprovado") {
          let visitaTecnicaData = (
            await api.http.get("/visitas_tecnicas/" + currProcesso.id)
          ).data;
          if (visitaTecnicaData.isReadtoclose === 1) {
            setIsReadtoclose(1);
          };

          if (visitaTecnicaData.reportGenerate) {
            reset(visitaTecnicaData);
            setVisitaTecnica(visitaTecnicaData);
          };
          if (visitaTecnicaData.isReportDeliveredPME === 1) {
            setValue("isReportDeliveredPME", true);
          }
          else {
            setValue("isReportDeliveredPME", false);
          }
          if (visitaTecnicaData.isUseGenerateReport === 1) {

            setValue("isUseGenerateReport", true);
          }
          else {
            setValue("isUseGenerateReport", false);
          }
        }
      } catch (error) {
        console.error('Erro ao buscar os dados:', error);
      }
    };

    const intervalId = setInterval(() => {
      if (!visitaTecnica.reportGenerate) {
        fetchData();
      } else {
        clearInterval(intervalId);
      }
    }, 60000);

    return () => clearInterval(intervalId);
  }, [visitaTecnica]);

  React.useEffect(async () => {
    startLoading();
    try {
      setListaTecnicos(
        (
          await api.http.get("/users", {
            params: {
              roleId: "consultorEE",
              habilitado: true,
              ordenado_alfa: true,
            },
          })
        ).data
      );

      setListaScrumMasters(
        (
          await api.http.get("/users", {
            params: {
              roleId: "ScrumMaster",
              habilitado: true,
              ordenado_alfa: true,
            },
          })
        ).data
      );

      let visitaTecnicaData = (
        await api.http.get("/visitas_tecnicas/" + currProcesso.id)
      ).data;

      // console.log("currprocesso: ", currProcesso);
      // console.log("visitaTecnicaData: ", visitaTecnicaData);

      //if(currProcesso.scrumNotApply === 1){
      setValue("scrumNotApply", currProcesso.scrumNotApply)
      //}

      setExibirScrumMaster(currProcesso.scrumNotApply === 1 ? false : true);


      if (currProcesso.estagio !== "inicioAprovado") {

        if (visitaTecnicaData.isUseGenerateReport === 1) {

          setValue("isUseGenerateReport", true);
        }
        else {
          setValue("isUseGenerateReport", false);
        }

        visitaTecnicaData.isReportDeliveredPME === 1 ? setValue("isReportDeliveredPME", true) : setValue("isReportDeliveredPME", false);
        if (visitaTecnicaData.isReadtoclose === 1) {
          if (visitaTecnicaData.isContractedDiag === 1) {
            setValue("isContractedDiag", 1);
          }
          else { setValue("isContractedDiag", 2); }
        }

        reset(visitaTecnicaData);
        setVisitaTecnica(visitaTecnicaData);
        if (visitaTecnicaData.isReadtoclose === 1) {
          setIsReadtoclose(1);
        };
      }
      stopLoading();
    } catch (error) {
      stopLoading({
        message: error.toString(),
        severity: "error",
      });
    }
  }, []);

  React.useEffect(async () => {
    startLoading();
    try {

      let visitaTecnicaData = (
        await api.http.get("/visitas_tecnicas/" + currProcesso.id)
      ).data;

      setValue("scrumNotApply", currProcesso.scrumNotApply)

      setExibirScrumMaster(currProcesso.scrumNotApply === 1 ? false : true);

      if (currProcesso.estagio !== "inicioAprovado") {

        if (visitaTecnicaData.isUseGenerateReport === 1) {

          setValue("isUseGenerateReport", true);
        }
        else {
          setValue("isUseGenerateReport", false);
        }

        visitaTecnicaData.isReportDeliveredPME === 1 ? setValue("isReportDeliveredPME", true) : setValue("isReportDeliveredPME", false);
        if (visitaTecnicaData.isReadtoclose === 1) {
          if (visitaTecnicaData.isContractedDiag === 1) {
            setValue("isContractedDiag", 1);
          }
          else { setValue("isContractedDiag", 2); }
        }

        reset(visitaTecnicaData);
        setVisitaTecnica(visitaTecnicaData);
        if (visitaTecnicaData.isReadtoclose === 1) {
          setIsReadtoclose(1);
        };
      }
      stopLoading();
    } catch (error) {
      stopLoading({
        message: error.toString(),
        severity: "error",
      });
    }
  }, [finishReportGenerate]);


  const handleSalvarScrumMaster = async () => {

    startLoading();
    try {
      let retorno;

      if (watch("scrumMasterId") && !watch("tecnicoId")) {
        retorno = (
          await api.http.post("/visitas_tecnicas/scrummaster", {
            processoId: currProcesso.id,
            scrumMasterId: watch("scrumMasterId"),
          })
        ).data;
      }
      else {

        retorno = (
          await api.http.post("/visitas_tecnicas", {
            tecnicoId: watch("tecnicoId"),
            scrumMasterId: watch("scrumMasterId"),
            processoId: currProcesso.id,
          })
        ).data;
      }

      reset(retorno.visitaTecnica);
      setVisitaTecnica(retorno.visitaTecnica);
      selectProcesso(retorno.processo);
      //setExibirScrumMaster(false);
      stopLoading({ severity: "success" });
    } catch (error) {
      stopLoading({
        message: error.toString(),
        severity: "error",
      });
      throw error;
    }
  };



  const actionButtons = () => {

    if (isContractedDiag === 2
      && isReadtoclose === 1
      && currProcesso.isFormstep
      && (currProcesso.estagio === 'preDiagAprovado'
        || currProcesso.estagio === 'preDiagIniciado'
        || currProcesso.estagio === 'preDiagReprovado')) {
      return (
        <React.Fragment>
          <Button
            variant="outlined"
            size="large"
            sx={{ mr: 2 }}
            onClick={recusa}
          >
            RECUSAR PARA DIAGNÓSTICO
          </Button>
        </React.Fragment>
      );
    }
    else {
      // console.log("está entrando aqui")
      // console.log("iscontratedDiag: ", isContractedDiag);
      // console.log("isReadtoclose: ", isReadtoclose);
      // console.log("currProcesso: ", currProcesso);
      if (isContractedDiag === 1
        && isReadtoclose === 1
        && currProcesso.isFormstep
        && currProcesso.estagio !== 'preDiagAprovado'
        && currProcesso.estagio !== 'diagIniciado'
        && (currProcesso.estagio === 'preDiagIniciado'
          || currProcesso.estagio === 'preDiagReprovado')) {
        return (
          <React.Fragment>
            <Button variant="contained" size="large" onClick={aprova}>
              APROVAR PARA DIAGNÓSTICO
            </Button>
          </React.Fragment>
        );
      }
      else {

        if (exibirScrumMaster && currProcesso.scrumNotApply !== 2) {
          return (
            <React.Fragment>
              <Button
                variant="outlined"
                size="large"
                sx={{ mr: 2 }}
                onClick={cancela}
                disabled={!isDirty || isSubmitting}
              >
                CANCELAR
              </Button>
              <Button
                variant="contained"
                size="large"
                onClick={handleSalvarScrumMaster}
                disabled={!isDirty || isSubmitting}
              >
                SALVAR
              </Button>
            </React.Fragment>
          );
        }
        else {
          if (
            isDirty ||
            // visitaTecnica ||
            !["preDiagReprovado", "preDiagAprovado", "diagIniciado"].includes(currProcesso.estagio)
          ) {
            return (
              <React.Fragment>
                <Button
                  variant="outlined"
                  size="large"
                  sx={{ mr: 2 }}
                  onClick={cancela}
                  disabled={!isDirty || isSubmitting}
                >
                  CANCELAR
                </Button>
                <Button
                  variant="contained"
                  size="large"
                  onClick={handleSubmit(salva)}
                  disabled={!isDirty || isSubmitting}
                >
                  SALVAR
                </Button>
              </React.Fragment>
            );
          } else {
            if (
              user.roleId === "superAdmin"
              && (currProcesso.estagio === "preDiagReprovado")
            ) {
              return (
                <React.Fragment>
                  <Button
                    variant="outlined"
                    size="large"
                    sx={{ mr: 2 }}
                    onClick={desfazerRecusa}
                  >
                    DESFAZER PROCESSO RECUSADO
                  </Button>
                </React.Fragment>
              );
            }
            else {
              if (currProcesso.estagio === "preDiagIniciado") {

                return (
                  <React.Fragment>
                    {!currProcesso.isFormstep && (
                      <Button
                        variant="outlined"
                        size="large"
                        sx={{ mr: 2 }}
                        onClick={recusa}
                      >
                        RECUSAR PARA DIAGNÓSTICO
                      </Button>)}
                    {!currProcesso.isFormstep && (
                      <Button variant="contained" size="large" onClick={aprova}>
                        APROVAR PARA DIAGNÓSTICO
                      </Button>)}
                  </React.Fragment>
                );
              }
              else if (isDirty
                && user.roleId === "superAdmin"
                // visitaTecnica ||
                //!["preDiagReprovado", "preDiagAprovado", "diagIniciado"].includes(currProcesso.estagio)
              ) {

                return (
                  <React.Fragment>
                    <Button
                      variant="outlined"
                      size="large"
                      sx={{ mr: 2 }}
                      onClick={cancela}
                      disabled={!isDirty || isSubmitting}
                    >
                      CANCELAR f
                    </Button>
                    <Button
                      variant="contained"
                      size="large"
                      onClick={handleSubmit(salva)}
                      disabled={!isDirty || isSubmitting}
                    >
                      SALVAR f
                    </Button>
                  </React.Fragment>
                );

              }
            }
          }
        }
      }
    };
  };

  const onConfirmaIsUseGenerateReport = async (data) => {
    setValue("isUseGenerateReport", true);
    setOpenIsUseGenerateReport(false);
  }

  const onNaoConfirmaIsUseGenerateReport = () => {
    setValue("isUseGenerateReport", false);
    setOpenIsUseGenerateReport(false);
  }

  const exibeCorpo = useMemo(() => {
    return visitaTecnica?.id && tecnicoId && visitaTecnica?.tecnicoId;
  }, [tecnicoId, visitaTecnica]);

  const handleClickUseGenerateReport = () => {
    if (isUseGenerateReport === null || isUseGenerateReport === false || isUseGenerateReport === 0) {
      setOpenIsUseGenerateReport(true);
    }
  };

  React.useEffect(() => {
    if (isContractedDiag === 1) {
      setValue("isPMENaoContratou", 0);
    }
  }, [isContractedDiag]);

  const visibleToScrumMaster = () => {
    let exibir = true;
    if (scrumNotApply === 2 && (user.roleId === "superAdmin" || user.roleId === 'gestorSENAI')) {
      exibir = false;
    }

    if ((user.roleId === "scrumMaster" && user.id === scrumMasterId) && scrumNotApply === 1) {
      exibir = false;
    }

    if (scrumNotApply === 1 && (user.roleId === "superAdmin")) {
      exibir = false;
    }
    return exibir;
  }

  return (
    <Paper
      variant="outlined"
      className="center-box no-print"
      sx={{
        textAlign: "center",
        px: 4,
        pt: 2,
        pb: 3,
        maxWidth: "md",
      }}
    >
      <DialogoConfirmacao
        title="Atenção"
        open={openConfirmacao}
        onSim={onSimConfirma}
        onNao={onNaoConfirma}
        criteriosUrl="https://venidera.sharepoint.com/:w:/s/PotencializEE/Eb1F3i4nSM1Es7g5HS2z6E8BeXYpGFXlxvvTCfhwHRDiZA?e=X3DSq0"
      >
        {confirmacaoMsg}
      </DialogoConfirmacao>
      <DialogoConfirmacaoSimples
        title="Atenção"
        open={openConfirmaDesfRecusa}
        onSim={onConfirmaDesfRecusa}
        onNao={onNaoConfirmaDesfRecusa}
      >
        Tem certeza que deseja desfazer recusa do pré-diagnóstico?
      </DialogoConfirmacaoSimples>
      <DialogoConfirmacao
        title="Atenção"
        open={openIsUseGenerateReport}
        onSim={onConfirmaIsUseGenerateReport}
        onNao={onNaoConfirmaIsUseGenerateReport}
      >
        Descreva aqui a justificativa para a não utilização do relatório gerado pelo sistema.
      </DialogoConfirmacao>

      {currProcesso.scrumNotApply === 0 && (
        <>
          <Divider>
            <h3>Alocação do Especialista Lider do projeto</h3>
          </Divider>

          <form>
            {!exibeCorpo && (
              <>
                <FormControl sx={{ my: 2 }} fullWidth>
                  <Select
                    field="scrumNotApply"
                    useFormRef={useFormRef}
                    schema={schema}
                    options={opcoesBooleanas}
                    disabled={currProcesso.scrumNotApply > 0}
                  />
                </FormControl>
              </>
            )}

            {scrumNotApply > 0 && (
              <>
                <FormControl sx={{ my: 2 }} fullWidth>
                  <Select
                    field="scrumMasterId"
                    useFormRef={useFormRef}
                    schema={schema}
                    options={listaScrumMasters}
                    getOptionLabel={(option) => option.nome}
                    disabled={scrumNotApply !== 1}
                  //disabled={disabledBtScrumMaster()}
                  />
                </FormControl>
              </>
            )}

            <Divider>
              <h3>Alocação de especialista para visita</h3>
            </Divider>
            <FormControl sx={{ my: 2 }} fullWidth>
              <Select
                field="tecnicoId"
                useFormRef={useFormRef}
                schema={schema}
                options={listaTecnicos}
                getOptionLabel={(option) => option.nome}
                disabled={visibleToScrumMaster()}
              />
            </FormControl>


          </form>
        </>
      )}


      <form onSubmit={handleSubmit(aprova)}>


        {currProcesso.scrumNotApply > 0 && (
          <>

            <Divider>
              <h3>Alocação do Especialista Lider do projeto</h3>
            </Divider>
            {!exibeCorpo && (
              <FormControl sx={{ my: 2 }} fullWidth>
                <Select
                  field="scrumNotApply"
                  useFormRef={useFormRef}
                  schema={schema}
                  options={opcoesBooleanas}
                  disabled={true}
                />
              </FormControl>)}
            <FormControl sx={{ my: 2 }} fullWidth>
              <Select
                field="scrumMasterId"
                useFormRef={useFormRef}
                schema={schema}
                options={listaScrumMasters}
                getOptionLabel={(option) => option.nome}
                disabled={true}
              />
            </FormControl>

            <Divider>
              <h3>Alocação de especialista para visita</h3>
            </Divider>
            <FormControl sx={{ my: 2 }} fullWidth>
              <Select
                field="tecnicoId"
                useFormRef={useFormRef}
                schema={schema}
                options={listaTecnicos}
                getOptionLabel={(option) => option.nome}
                disabled={(disabledAlocacao())}
              />
            </FormControl>
          </>)}


        {exibeCorpo && (
          <React.Fragment>
            {currProcesso.isFormstep ? (
              <React.Fragment>
                <StepByStepPreDiagnostico2 />
                {/* {visitaTecnica.reportGenerate ? ( */}
                {visitaTecnica.reportGenerate || finishReportGenerate ? (
                  <>
                    <Divider>
                      <h3>Apresentação para o PME</h3>
                    </Divider>
                    <Box sx={{ width: '100%', marginTop: '20px' }}>
                      <FormControl sx={{ mb: 2 }}>
                        <Checkbox
                          field="isUseGenerateReport"
                          disabled={disabled()}
                          schema={schema}
                          useFormRef={useFormRef}
                          onClick={handleClickUseGenerateReport}
                        />
                      </FormControl>
                    </Box>

                    {(visitaTecnica?.reportGenerate
                      && isUseGenerateReport) && (
                        <FormControl sx={{ mb: 2 }} fullWidth>
                          <FileField
                            field="relatorioId"
                            fileData={visitaTecnica?.relatorio || null}
                            useFormRef={useFormRef}
                            schema={schema}
                            disabled={disabled()}
                          />
                        </FormControl>
                      )}
                    {(visitaTecnica?.reportGenerate || finishReportGenerate) && (
                      <Box sx={{ width: '100%', marginTop: '20px' }}>
                        <FormControl sx={{ mb: 2 }}>
                          <Checkbox
                            field="isReportDeliveredPME"
                            schema={schema}
                            useFormRef={useFormRef}
                            disabled={disabled()}
                          />
                        </FormControl>
                      </Box>
                    )}

                    {(
                      visitaTecnica?.reportGenerate &&
                      isReportDeliveredPME) ? (
                      <Box sx={{ width: '100%', marginTop: '20px', justifyContent: "center", justifyItems: "center" }}>
                        <FormControl sx={{ my: 2 }} fullWidth>
                          <Select
                            field="isContractedDiag"
                            useFormRef={useFormRef}
                            schema={schema}
                            options={selecionarPMEVaiContratarDiagnostico}
                            getOptionLabel={(option) => option.label}
                            disabled={isReadtoclose === 1}
                          />
                        </FormControl>
                      </Box>
                    ) : null}

                    {(visitaTecnica?.reportGenerate
                      && isContractedDiag === 2
                      && isReportDeliveredPME) && (
                        <FormControl sx={{ my: 2 }} fullWidth>
                          <Select
                            field="isPMENaoContratou"
                            useFormRef={useFormRef}
                            schema={schema}
                            options={motivosPMENaoContratarDiagnostico}
                            getOptionLabel={(option) => option.label}
                            disabled={isReadtoclose === 1}
                          />
                        </FormControl>
                      )}
                  </>) : null}

              </React.Fragment>
            ) : (
              <>
                <Divider>
                  <h3>Documentos</h3>
                </Divider>
                <FormControl sx={{ my: 2 }} fullWidth>
                  <FileField
                    field="preDiagnosticoId"
                    disabled={disabled()}
                    fileData={visitaTecnica?.preDiagnostico}
                    useFormRef={useFormRef}
                    schema={schema}
                  />
                </FormControl>
              </>
            )}

            {(!visitaTecnica?.dreId || !visitaTecnica?.balancoId) && (
              <React.Fragment>
                <h4>Demonstrativos financeiros</h4>
              </React.Fragment>
            )}

            <Grid container spacing={2}>
              {!visitaTecnica?.dreId && (
                <React.Fragment>
                  <Grid item xs={6}>
                    <FormControl sx={{ my: 2 }} fullWidth>
                      <FileField
                        field="dreId"
                        disabled={disabled()}
                        fileData={currProcesso?.dre}
                        useFormRef={useFormRef}
                        schema={schema}
                      />
                    </FormControl>
                  </Grid>
                </React.Fragment>
              )}
              {!visitaTecnica?.balancoId && (
                <React.Fragment>
                  <Grid item xs={6}>
                    <FormControl sx={{ my: 2 }} fullWidth>
                      <FileField
                        field="balancoId"
                        disabled={disabled()}
                        fileData={currProcesso?.balanco}
                        useFormRef={useFormRef}
                        schema={schema}
                      />
                    </FormControl>
                  </Grid>
                </React.Fragment>
              )}
            </Grid>

            {!visitaTecnica?.docsTecnicosId && (
              <React.Fragment>
                <h4>Documentos técnicos</h4>
                <FileListField
                  field="docsTecnicos"
                  disabled={disabled()}
                  useFormRef={useFormRef}
                  schema={schema}
                  initialFiles={currProcesso?.docsTecnicos} //TODO mudar esses currProcesso pra pegar da visita tecnica
                />
              </React.Fragment>
            )}
            {(!visitaTecnica?.faturasEnergiaId ||
              visitaTecnica?.faturasEnergia.arquivos.length < 0) && (
                <React.Fragment>
                  <h4>Documentos financeiros</h4>
                  <FileListField
                    field="faturasEnergia"
                    disabled={disabled()}
                    useFormRef={useFormRef}
                    schema={schema}
                    initialFiles={visitaTecnica?.faturasEnergia}
                  />
                </React.Fragment>
              )}
            {!visitaTecnica?.faturasEnergeticosId && (
              <React.Fragment>
                <h4>Faturas dos Energéticos</h4>
                <FileListField
                  field="faturasEnergeticos"
                  disabled={disabled()}
                  useFormRef={useFormRef}
                  schema={schema}
                  initialFiles={currProcesso?.faturasEnergeticos}
                />
              </React.Fragment>
            )}

            <Grid container spacing={2}>
              {!visitaTecnica?.cartaoCnpjId && (
                <React.Fragment>
                  <Grid item xs={4}>
                    <FormControl sx={{ my: 2 }} fullWidth>
                      <FileField
                        field="cartaoCnpjId"
                        disabled={disabled()}
                        fileData={currProcesso?.cartaoCnpj}
                        useFormRef={useFormRef}
                        schema={schema}
                      />
                    </FormControl>
                  </Grid>
                </React.Fragment>
              )}
              {!visitaTecnica?.certNegInssId && (
                <React.Fragment>
                  <Grid item xs={4}>
                    <FormControl sx={{ my: 2 }} fullWidth>
                      <FileField
                        field="certNegInssId"
                        disabled={disabled()}
                        fileData={currProcesso?.certNegInss}
                        useFormRef={useFormRef}
                        schema={schema}
                      />
                    </FormControl>
                  </Grid>
                </React.Fragment>
              )}
              {!visitaTecnica?.certRegFgtsId && (
                <React.Fragment>
                  <Grid item xs={4}>
                    <FormControl sx={{ my: 2 }} fullWidth>
                      <FileField
                        field="certRegFgtsId"
                        disabled={disabled()}
                        fileData={currProcesso?.certRegFgts}
                        useFormRef={useFormRef}
                        schema={schema}
                      />
                    </FormControl>
                  </Grid>
                </React.Fragment>
              )}
            </Grid>

            <Grid container spacing={2} marginBottom={4}>
              {!visitaTecnica?.certNegDebtTrabId && (
                <React.Fragment>
                  <Grid item xs={6}>
                    <FormControl sx={{ my: 2 }} fullWidth>
                      <FileField
                        field="certNegDebtTrabId"
                        disabled={disabled()}
                        fileData={currProcesso?.certNegDebtTrab}
                        useFormRef={useFormRef}
                        schema={schema}
                      />
                    </FormControl>
                  </Grid>
                </React.Fragment>
              )}
              {!visitaTecnica?.certNegDebtEstId && (
                <React.Fragment>
                  <Grid item xs={6}>
                    <FormControl sx={{ my: 2 }} fullWidth>
                      <FileField
                        field="certNegDebtEstId"
                        disabled={disabled()}
                        fileData={currProcesso?.certNegDebtEst}
                        useFormRef={useFormRef}
                        schema={schema}
                      />
                    </FormControl>
                  </Grid>
                </React.Fragment>
              )}
            </Grid>

            <Divider>
              <h3>Outros Documentos</h3>
            </Divider>

            <FileListField
              field="outrosDocs"
              disabled={disabled()}
              useFormRef={useFormRef}
              schema={schema}
              initialFiles={visitaTecnica?.outrosDocs}
            />

            {currProcesso && currProcesso.estagio != "preDiagIniciado" && (
              <React.Fragment>
                <Divider>
                  <h3>
                    Observações de{" "}
                    {currProcesso.estagio == "preDiagReprovado"
                      ? "reprovação"
                      : "aprovação"}
                  </h3>
                </Divider>
                <FormControl sx={{ my: 2 }} fullWidth>
                  <TextField
                    value={visitaTecnica?.observacoes}
                    multiline
                    rows={4}
                    disabled={true}
                  />
                </FormControl>
              </React.Fragment>
            )}
          </React.Fragment>
        )}

        <Timestamps dados={visitaTecnica} />

        {/* {!disabled() && exibirScrumMaster && ( */}
        {/* {!disabled() && ( */}
        {/* {currProcesso.scrumNotApply > 0 && ( */}
        <Box sx={{ mt: 3, textAlign: "center" }}>{actionButtons()}</Box>
        {/* )} */}
      </form>
    </Paper >
  );
};

export default VisitaTecnica;
