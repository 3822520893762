import axios from 'axios';
import {errorMessages} from '../config';
const API_URL_BASE = process.env.REACT_APP_API_URL_BASE;
const AuthHeader = 'Authorization';

class ApiProvider {
  constructor() {
    // cria instancia do axios
    this.http = axios.create({
      baseURL: API_URL_BASE,
      // timeout: 3000,
      headers: {
        "Content-Type": "application/json; charset=utf-8",
      },
    });

    // configura token de acesso caso esteja salvo
    let token = localStorage.getItem(AuthHeader);
    if (!token) {
      token = sessionStorage.getItem(AuthHeader);
    }
    if (token) {
      this.http.defaults.headers.common[AuthHeader] = token;
    }

    // configura mensagens de respostas
    this.http.interceptors.response.use(function (response) {
      // Qualquer código de status que dentro do limite de 2xx faz com que
      // está função seja acionada
      // Faz alguma coisa com os dados de resposta
      return response;
    }, function (error) {
      // Qualquer código de status que não esteja no limite do código 2xx faz
      // com que está função seja acionada
      // Faz alguma coisa com o erro da resposta
      if(error.response &&
         error.response.status >= 400 && error.response.status < 600) {

        let errorCode, errorMessage;

        if(error.response.data.message) {
          let messageExp = /^.*((ER_|E_)[A-Z_]+)\:\s(.+)$/;
          let messageExec = messageExp.exec(error.response.data.message);
          if(messageExec) {
            errorCode = messageExec[1];
            errorMessage = messageExec[3];
          }
          else
            errorMessage = error.response.data.message;
        } else if (error.response.data.errors) {
          [errorCode, errorMessage] =
            error.response.data.errors[0].message.split(':');
        }

        if(errorCode in errorMessages){
          error.toString = ()=>errorMessages[errorCode];
        } else {
          error.toString = ()=>errorMessage;
        }
      }
      return Promise.reject(error);
    });
  }


  async signin({email,password,manterConectado=false}) {
    let login = await this.http.post('/login',{email:email,password:password});
    let auth = login.data.type + ' ' + login.data.token;
    if (manterConectado) {
      localStorage.setItem(AuthHeader, auth);
    } else {
      sessionStorage.setItem(AuthHeader, auth);
    }
    this.http.defaults.headers.common[AuthHeader] = auth;
  }

  signout() {
    localStorage.removeItem(AuthHeader);
    sessionStorage.removeItem(AuthHeader);
    delete this.http.defaults.headers.common[AuthHeader];
  }
};


const api = new ApiProvider();

export default api;
