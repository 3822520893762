import React, { useEffect } from 'react';
import {
  Grid,
  Box,
  Typography,
} from "@mui/material";
import './ModalRelResumo.css';
import { Bar } from 'react-chartjs-2';
// Importando os módulos necessários para Chart.js
import { Chart as ChartJS, BarElement, Tooltip, Legend, CategoryScale, LinearScale } from 'chart.js';
import { formatCurrency } from '../../services/utils';
import { number } from 'yup';
ChartJS.register(BarElement, Tooltip, Legend, CategoryScale, LinearScale); // Registrar escalas e elementos


const RelatorioResultadoChartCustoAnual = ({ lstQuestionario, lstRespostas }) => {

  const [seriesData, setSeriesData] = React.useState([]);

  const cores = [
    '#0000CD', '#00ff00', '#F0E68C', '#4169E1',
    '#008000', '#293896', '#6495ED',
    '#62BB46', '#B0E0E6', '#F39325', '#4682B4',
    '#00BFFF', '#FF8C00', '#81D3EB',

  ];

  const data = {
    labels: seriesData.map((item) => item.label), // Labels para a legenda
    datasets: [
      {
        label: 'Custo anual',
        data: seriesData.map((item) => Number(item.value)), // Valores para cada fatia
        backgroundColor: cores,
        borderColor: '#000', // Cor da borda das barras
        borderWidth: 1,
      },
    ],
  };

  const options = {
    scales: {
      y: {
        beginAtZero: true, // Iniciar eixo Y do zero
        ticks: {
          callback: function (value) {
            return `R$ ${value.toLocaleString('pt-BR')}`;
          }
        }
      },
    },
    plugins: {
      datalabels: {
        formatter: (value) => {
          return value.toLocaleString('pt-BR', {
            minimumFractionDigits: 0,
            maximumFractionDigits: 0
          });
        },
        color: '#000000',
        font: {
          weight: 'bold',
        },
      },
      legend: {
        display: false, // Opcional: oculta a legenda do gráfico
      },
    },
  };

  const adicionarItem = (item) => {
    setSeriesData((dadosAtuais) => [...dadosAtuais, item]); // Adiciona o novo item ao final da lista
  };

  const getEnergiaEletrica = () => {
    const titulo = "Eletricidade";
    const un_ano = lstRespostas.prediagnostico["general-data_2_8"];
    // const un_ano = lstRespostas.prediagnostico["general-data_2_7"];
    //const mwh_ano = lstRespostas.prediagnostico["general-data_2_8"];
    const FatorEmissao = 1;
    //const vidasUteis = 0.0860;

    addFonteResumo(titulo, un_ano, un_ano);

  };

  useEffect(() => {
    getEnergiaEletrica();
    if (lstRespostas.prediagnostico["general-data_3_1"] === true) { montaResumoFonte('fuel-data-1', 'Bagaço de cana', 2475.53) };
    if (lstRespostas.prediagnostico["general-data_3_2"] === true) { montaResumoFonte('fuel-data-2', 'Bio diesel (B100)', 9.20) };
    if (lstRespostas.prediagnostico["general-data_3_3"] === true) { montaResumoFonte('fuel-data-3', 'Bio gás', 6.97) };
    if (lstRespostas.prediagnostico["general-data_3_4"] === true) { montaResumoFonte('fuel-data-4', 'Carvão', 3428.55) };
    //if (lstRespostas.prediagnostico["general-data_3_5"] === true) { montaResumoFonte('fuel-data-5', 'Carvão vegetal', 1) };
    if (lstRespostas.prediagnostico["general-data_3_5"] === true) { montaResumoFonteLenha('fuel-data-5', 'Carvão vegetal', 7507.95, 0.024, 0.408096) };
    // if(lstRespostas.prediagnostico["general-data_3_6"] === true){montaResumoFonte('fuel-data-6', 'Eletricidade', 0.0860)};
    if (lstRespostas.prediagnostico["general-data_3_7"] === true) { montaResumoFonte('fuel-data-7', 'Gás natural', 10.23) };
    if (lstRespostas.prediagnostico["general-data_3_8"] === true) { montaResumoFonte('fuel-data-8', 'Gasolina', 12.09) };
    if (lstRespostas.prediagnostico["general-data_3_9"] === true) { montaResumoFonte('fuel-data-9', 'GLP', 12.90) };
    //if (lstRespostas.prediagnostico["general-data_3_10"] === true) { montaResumoFonte('fuel-data-10', 'Lenha', 1405.13) };
    //lenha
    if (lstRespostas.prediagnostico["general-data_3_10"] === true) { montaResumoFonteLenha('fuel-data-10', 'Lenha (m³)-umidade normal(43%)', 2522.021197, 0.0068, 0.3685212) };
    if (lstRespostas.prediagnostico["general-data_3_11"] === true) { montaResumoFonteLenha('fuel-data-11', 'Lenha (m³)-baixa umidade(36%)', 2562.698958, 0.0068, 0.3685212) };
    if (lstRespostas.prediagnostico["general-data_3_12"] === true) { montaResumoFonteLenha('fuel-data-12', 'Lenha (t)-umidade normal(43%)', 3602.89, 0.0068, 0.3685212) };
    if (lstRespostas.prediagnostico["general-data_3_13"] === true) { montaResumoFonteLenha('fuel-data-13', 'Lenha (t)-baixa umidade(36%)', 3661.00, 0.0068, 0.3685212) };
    // .end-> lenha
    if (lstRespostas.prediagnostico["general-data_3_14"] === true) { montaResumoFonte('fuel-data-14', 'Óleo combustível', 11145.71) };
    if (lstRespostas.prediagnostico["general-data_3_15"] === true) { montaResumoFonte('fuel-data-15', 'Óleo diesel', 11.74) };
    if (lstRespostas.prediagnostico["general-data_3_16"] === true) { montaResumoFonte('fuel-data-16', 'Querosene', 12.09) };
    if (lstRespostas.prediagnostico["general-data_3_17"] === true) { montaResumoFonte('fuel-data-17', 'Sol', 1) };
  }, []);

  //método que monta a lista de fontes energéticas
  const addFonteResumo = (fonte, un_ano, mwh_ano, rs_ano, eeco2) => {
    // Crie o novo item
    const newFonte = {
      fonte,
      un_ano,
      mwh_ano,
      rs_ano,
      eeco2,
    };

    const fonteExiste = seriesData.some((item) => item.label === fonte);
    if (!fonteExiste) {

      const novoItem = { value: mwh_ano, label: fonte };

      adicionarItem(novoItem);

    };
  }

  // Função para obter a lista de perguntas e respostas de um grupo específico
  const getQuestionsAndAnswers = (groupName) => {
    const questionsAndAnswers = [];

    lstQuestionario.steps.forEach((step) => {
      step.groups?.forEach((group) => {
        if (group.name === groupName) {
          group.questions?.forEach((question) => {
            questionsAndAnswers.push({
              questionName: question.name,
              questionDescription: question.description,
              questionAnswer: question.answer,
              questionValidation: question.validation.type
            });
          });
        }
      });
    });

    return questionsAndAnswers;
  };

  const montaResumoFonte = ((grupo, titulo, FatorEmissao) => {
    let index = 0;
    let un_ano = 0;
    let mwh_ano = 0;
    let rs_ano = 0;
    const questionAndAnswers = getQuestionsAndAnswers(grupo);

    questionAndAnswers.forEach((item) => {
      if (index === 1) { un_ano = lstRespostas.prediagnostico[item.questionName] ? lstRespostas.prediagnostico[item.questionName] : 0; }
      else {
        if (index === 2) { mwh_ano = lstRespostas.prediagnostico[item.questionName] ? lstRespostas.prediagnostico[item.questionName] : 0; }
        else {
          if (index === 3) { rs_ano = lstRespostas.prediagnostico[item.questionName] ? lstRespostas.prediagnostico[item.questionName] : 0; }
        }
      };
      index += 1;
    });

    addFonteResumo(titulo, un_ano, mwh_ano, rs_ano, FatorEmissao === 0 ? 0 : Math.ceil(un_ano * FatorEmissao / 1000));

  });

  const montaResumoFonteLenha = ((grupo, titulo, FatorEmissao) => {
    let index = 0;
    let un_ano = 0;
    let mwh_ano = 0;
    let rs_ano = 0;
    const questionAndAnswers = getQuestionsAndAnswers(grupo);

    questionAndAnswers.forEach((item) => {
      if (index === 2) { un_ano = lstRespostas.prediagnostico[item.questionName] ? lstRespostas.prediagnostico[item.questionName] : 0; }
      else {
        if (index === 3) { mwh_ano = lstRespostas.prediagnostico[item.questionName] ? lstRespostas.prediagnostico[item.questionName] : 0; }
        else {
          if (index === 4) { rs_ano = lstRespostas.prediagnostico[item.questionName] ? lstRespostas.prediagnostico[item.questionName] : 0; }
        }
      };
      index += 1;
    });

    addFonteResumo(titulo, un_ano, mwh_ano, rs_ano, FatorEmissao === 0 ? 0 : Math.ceil(un_ano * FatorEmissao / 1000));

  });

  return (
    <div className='grid-space-container'>
      <Grid container spacing={0} className='grid-space-container' sx={{
        pageBreakInside: 'avoid'
      }}>
        <Box sx={{ width: '80%', marginTop: '20px' }}>
          <Typography variant="h4" gutterBottom>
            Custo médio anual por fonte energética
          </Typography>
        </Box>
        <Bar data={data} options={options} className='chart-container' /> {/* Renderizar gráfico de barras */}
      </Grid>


    </div>
  );
};

export default RelatorioResultadoChartCustoAnual;
