import React, { useEffect } from 'react';
import {
  Grid,
  Box,
  Typography,
} from "@mui/material";

import './ModalRelResumo.css';

const RelatorioResultadoEspecificosSegmento = ({ lstQuestionario, lstRespostas }) => {

  //método para retornar o valor para o relatorio de um select
  const getValueFromOptions = (questionName, answerValue) => {
    let valueFromOptions = null;
    lstQuestionario.steps.forEach((step) => {
      if (step.groups) {
        step.groups?.forEach((group) => {
          if (group.questions) {
            group.questions.forEach((question) => {
              if (question.name === questionName) {
                if (question.validation.options) {
                  question.validation.options.forEach((option) => {
                    if (parseInt(option.key) === parseInt(answerValue)) {
                      valueFromOptions = option.value;
                    }
                  });
                }
              }
            });
          }
        });
      }
    });
    return valueFromOptions;
  };

  //método para retornar o valor para o relatorio de um select
  const getValueFromValidateOptions = (questionName, answerValue) => {
    let valueFromOptions = null;
    lstQuestionario.steps.forEach((step) => {
      if (step.groups) {
        step.groups?.forEach((group) => {
          if (group.questions) {
            group.questions.forEach((question) => {
              if (question.name === questionName) {
                if (question.validation.options) {
                  question.validation.options.forEach((option) => {
                    if (parseInt(option.key) === parseInt(answerValue)) {
                      valueFromOptions = option.value;
                    }
                  });
                }
              }
            });
          }
        });
      }
    });
    return valueFromOptions;
  };

  // Função para obter a lista de perguntas e respostas de um grupo específico
  const getQuestionsAndAnswers = (groupName) => {
    const questionsAndAnswers = [];

    lstQuestionario.steps.forEach((step) => {
      step.groups?.forEach((group) => {
        if (group.name === groupName) {
          group.questions?.forEach((question) => {
            questionsAndAnswers.push({
              questionName: question.name,
              questionDescription: question.description,
              questionAnswer: question.answer,
              questionValidation: question.validation.type
            });
          });
        }
      });
    });

    return questionsAndAnswers;
  };

  const montaHmtlQuestionariosRespondido = (grupo, titulo) => {
    const questionAndAnswers = getQuestionsAndAnswers(grupo);
    const qtd = questionAndAnswers.length;

    return (
      <><Box className='title-modal'>
        <Typography variant="h4" gutterBottom>
          {titulo}:
        </Typography>
      </Box>
        <Grid container spacing={0} className='grid-container'>
          {questionAndAnswers
            .filter(item => lstRespostas.prediagnostico[item.questionName] !== undefined && lstRespostas.prediagnostico[item.questionName] !== '')
            .map((item, index) => (
              <React.Fragment key={index}>
                <Grid item xs={4} className={qtd > 0 ? "item-response" : ""}>
                  <h5>{item.questionDescription}</h5>
                </Grid>
                <Grid item xs={8} className={qtd > 0 ? "item-response" : ""}>
                  <h4>
                    {item.questionValidation === 'RADIO' ? (
                      parseInt(lstRespostas.prediagnostico[item.questionName]) === 1 ? 'Sim' :
                        parseInt(lstRespostas.prediagnostico[item.questionName]) === 0 ? 'Não' :
                          parseInt(lstRespostas.prediagnostico[item.questionName]) === 2 ? 'Em andamento' :
                            item?.questionThirdOption
                    ) : (
                      item.questionValidation === 'SELECT' ?
                        getValueFromOptions(item.questionName, lstRespostas.prediagnostico[item.questionName]) :
                        lstRespostas.prediagnostico[item.questionName]
                    )}
                  </h4>
                </Grid>
              </React.Fragment>
            ))}
        </Grid></>
    );
  };


  return (
    <div style={{
      pageBreakInside: 'avoid'
    }}>
      {montaHmtlQuestionariosRespondido("general-data_4", "SISTEMAS INDUSTRIAIS ESPECÍFICOS DA LINHA DE PRODUÇÃO")}
      {montaHmtlQuestionariosRespondido("general-data_5", "MONITORAMENTO E GERENCIAMENTO ENERGÉTICO")}
      {montaHmtlQuestionariosRespondido("general-data_6", "PARTIDA DE MOTORES ELÉTRICOS")}
      {montaHmtlQuestionariosRespondido("general-data_7", "MOTOGERADORES", "general-data_7_1")}
      {montaHmtlQuestionariosRespondido("general-data_8", "SISTEMA FOTOVOLTAICO", "general-data_8_1")}
      {montaHmtlQuestionariosRespondido("general-data_9", "COLETORES SOLARES", "general-data_9_1")}
      {montaHmtlQuestionariosRespondido("general-data_10", "RESÍDUOS", "general-data_10_1")}
    </div>
  );
};

export default RelatorioResultadoEspecificosSegmento;