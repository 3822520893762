import TextField  from '@mui/material/TextField';
import * as yup from "yup";
import { cnpj } from 'cpf-cnpj-validator';

yup.addMethod(yup.string,'cnpj',function cnpjTest() {
  return this.test(
    'is-cnpj',
    (d) => 'CNPJ inválido',
    (value) => (value)?cnpj.isValid(value):true);
});

const CNPJField = ({field,useFormRef,schema,...props}) => {
  const {register, formState: {errors}} = useFormRef;
  const cnpjMask = event => {
    let v = event.target.value.replace(/\D/g,"");
    //Coloca ponto entre o segundo e o terceiro dígitos
    v=v.replace(/^(\d{2})(\d)/,"$1.$2");
    //Coloca ponto entre o quinto e o sexto dígitos
    v=v.replace(/^(\d{2})\.(\d{3})(\d)/,"$1.$2.$3");
    //Coloca uma barra entre o oitavo e o nono dígitos
    v=v.replace(/\.(\d{3})(\d)/,".$1/$2");
    //Coloca um hífen depois do bloco de quatro dígitos
    v=v.replace(/(\d{4})(\d)/,"$1-$2");
    // captura 2 numeros seguidos de um traço e não deixa
    // ser digitado mais nada
    v=v.replace(/(-\d{2})\d+?$/, '$1');
    event.target.value = v;
  };

  return (
    <TextField
      label={schema.fields[field].spec.label}
      required={schema.fields[field].exclusiveTests['required']}
      error={errors[field]!=null}
      helperText={errors[field]?.message}
      {...register(field,{
        onChange: cnpjMask
      })}
      {...props} />
  );
};

export default CNPJField;
