import * as React from 'react';
import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Button,
  IconButton

} from '@mui/material';
import PropTypes from 'prop-types';
import CloseIcon from '@mui/icons-material/Close';
import { driveBase } from '../config';

const BootstrapDialogTitle = (props) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};
BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

const DialogoVisualizador = ({file, open, onClose}) => {


  const src = () => {
    return driveBase+file.fileRef;
  };

  const content = () => {
    if(file) {
      if (file.mimeType=='application/pdf' ||
          file.mimeType=='text/html' ) {
        return (
          <iframe src={src()} frameBorder="0" ></iframe>
        );
      } else if(file.mimeType.indexOf('image')==0) {
        return (
          <img src={src()} className="img-responsive" />
        );
      }
    }

    return '';
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth="lg"
      fullWidth={true}>
      <BootstrapDialogTitle id="customized-dialog-title" onClose={onClose}>
        {file?.fileName}
      </BootstrapDialogTitle>
      <DialogContent className="file-viewer">
        {content()}
      </DialogContent>
    </Dialog>
  );
};

export default DialogoVisualizador;
