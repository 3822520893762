import { createStore } from 'easy-peasy';

import authStore from './authStore';
import interesseStore from './interesseStore';
import navStore from './navStore';
import processoStore from './processoStore';
import checkFinishReport from './checkFinishReport';


const store = createStore({
  interesse: interesseStore,
  auth: authStore,
  nav: navStore,
  processo: processoStore,
  checkFinishReport: checkFinishReport
});

export default store;
