import React, { useState } from "react";
import ReactDOM from "react-dom";
import { Paper, Button, Autocomplete, Box } from "@mui/material";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { pt } from "yup-locale-pt";
import { useNavigate } from "react-router-dom";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { DialogoMensagem, CamposProcessoInicial } from "../../components";
import api from "../../services/api";
import { useStoreState, useStoreActions } from "easy-peasy";
yup.setLocale(pt);

const NovoProcesso = () => {
  let startLoading = useStoreActions((actions) => actions.nav.startLoading);
  let stopLoading = useStoreActions((actions) => actions.nav.stopLoading);
  let cnpjValidado = useStoreState((state) => state.interesse.cnpjValidado);

  let navigate = useNavigate();

  const [confirma, setConfirma] = useState(false);
  const [openMensagem, setOpenMensagem] = useState(false);

  const proximo = (data) => {
    setConfirma(true);
    window.scrollTo({
      top: 0,
      behavior: "smooth",
      /* you can also use 'auto' behaviour
        in place of 'smooth' */
    });
  };

  const voltar = () => {
    setConfirma(false);
    window.scrollTo({
      top: 0,
      behavior: "smooth",
      /* you can also use 'auto' behaviour
        in place of 'smooth' */
    });
  };

  const confirmar = async (data) => {
    startLoading();
    try {
      await api.http.post("/interesses", data);
      window._paq.push(["FormAnalytics::trackFormConversion", "potencializee"]);
      stopLoading();
      setOpenMensagem(true);
    } catch (error) {
      stopLoading({
        message: error.toString(),
        severity: "error",
      });
      throw error;
    }
  };

  const onOkMensagem = () => {
    navigate("/", { replace: true });
  };

  const schema = yup
    .object({
      cnpj: yup.string().label("CNPJ").cnpj().required(),
      razaoSocial: yup.string().required().label("Razão social"),
      numFuncs: yup
        .number()
        .required()
        .positive()
        .integer()
        .label("Número de funcionários"),
      valMedioFatEnergia: yup
        .number()
        .float()
        .required()
        .label("Valor médio da fatura de energia elétrica"),
      setorAtuacaoId: yup
        .number()
        .required()
        .nullable()
        .positive()
        .integer()
        .label("Setor de atuação"),
      cep: yup.string().required().label("CEP"),
      rua: yup.string().required().label("Rua"),
      numero: yup.string().required().label("Número"),
      complemento: yup.string().label("Complemento"),
      bairro: yup.string().required().label("Bairro"),
      cidade: yup.string().required().label("Cidade"),
      estado: yup.string().required().label("Estado"),
      nome: yup.string().required().label("Nome completo"),
      telefone: yup.string().required().label("Telefone de contato"),
      email: yup.string().email().required().label("Email"),
      comoSoubeProgramaId: yup
        .number()
        .required()
        .nullable()
        .positive()
        .integer()
        .label("Como sua empresa soube do Programa?"),      
    })    
    .required();

    

  const useFormRef = useForm({
    mode: "onTouched",
    resolver: yupResolver(schema),
    defaultValues: {
      cnpj: cnpjValidado,
    },
  });

  const { handleSubmit, watch } = useFormRef;

  const watchEstado = watch("estado");

  const intro = () => {
    if (confirma) {
      return (
        <p>
          Por favor, verifique os dados preenchidos. Caso estiver de acordo, por
          favor, confirme seu interesse.
        </p>
      );
    } else {
      return <p>Por favor, preencha e nos envie os seguintes dados:</p>;
    }
  };

  const buttons = () => {
    if (confirma) {
      return (
        <Box sx={{ mt: 2 }}>
          <Button
            onClick={voltar}
            variant="outlined"
            size="large"
            startIcon={<ArrowBackIcon />}
            sx={{ mr: 2 }}
          >
            VOLTAR
          </Button>
          <Button
            onClick={handleSubmit(confirmar)}
            variant="contained"
            size="large"
            endIcon={<ArrowForwardIcon />}
          >
            Confirmar interesse
          </Button>
        </Box>
      );
    } else {
      return (
        <Box sx={{ mt: 2 }}>
          <Button
            onClick={handleSubmit(proximo)}
            variant="contained"
            size="large"
            endIcon={<ArrowForwardIcon />}
          >
            PRÓXIMO
          </Button>
        </Box>
      );
    }
  };

  return (
    <Paper
      variant="outlined"
      className="center-box"
      sx={{
        textAlign: "center",
        px: 4,
        pt: 2,
        pb: 3,
        m: 5,
        maxWidth: "md",
      }}
    >
      <DialogoMensagem title="Sucesso" open={openMensagem} onOk={onOkMensagem}>
        Sua aplicação foi registrada com sucesso em nosso sistema. Em breve
        entraremos em contato com o responsável legal.
      </DialogoMensagem>
      {intro()}
      <form name="potencializee" onSubmit={handleSubmit(proximo)}>
        <CamposProcessoInicial
          useFormRef={useFormRef}
          schema={schema}
          disabled={confirma}
        />
      </form>
      {buttons()}
    </Paper>
  );
};

export default NovoProcesso;
