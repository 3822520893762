import React from 'react';
import {
  Grid,
  Box,
  Typography,
} from "@mui/material";
import './ModalRelResumo.css';
import { formatCurrency, formatInteger, formatTime } from '../../services/utils';

const RelatorioResultadoCombustiveis = ({ lstQuestionario, lstRespostas }) => {

  //método para retornar o valor para o relatorio de um select
  // const getValueFromOptions = (questionName, answerValue) => {
  //   let valueFromOptions = null;
  //   lstQuestionario.steps.forEach((step) => {
  //     if (step.groups) {
  //       step.groups?.forEach((group) => {
  //         if (group.questions) {
  //           group.questions.forEach((question) => {
  //             if (question.name === questionName) {
  //               if (question.validation.options) {
  //                 question.validation.options.forEach((option) => {
  //                   if (parseInt(option.key) === parseInt(answerValue)) {
  //                     valueFromOptions = option.value;
  //                   }
  //                 });
  //               }
  //             }
  //           });
  //         }
  //       });
  //     }
  //   });
  //   return valueFromOptions;
  // };

  // Função para obter a lista de perguntas e respostas de um grupo específico
  const getQuestionsAndAnswers = (groupName) => {
    const questionsAndAnswers = [];

    lstQuestionario.steps.forEach((step) => {
      step.groups?.forEach((group) => {
        if (group.name === groupName) {
          group.questions?.forEach((question) => {
            questionsAndAnswers.push({
              questionName: question.name,
              questionDescription: question.description,
              questionValidation: question.validation.type
            });
          });
        }
      });
    });

    return questionsAndAnswers;
  };

  //método que monta o html para exibir informações de combustiveis
  const montaHmtlCombustivel = (grupo, titulo) => {
    const questionAndAnswers = getQuestionsAndAnswers(grupo);

    if (grupo === "fuel-data-11") {
      // console.log("grupo combustivel: ", questionAndAnswers);
      // console.log("question response: ", lstRespostas.prediagnostico["fuel-data_11_2"] === "0" ? 'Não' : 'Sim')
      // console.log("lstRespostas.prediagnostico[fuel - data_11_2]: ", lstRespostas.prediagnostico["fuel-data_11_2"]);
    }

    //  incluir dados nos graficos
    return (
      <Grid container spacing={0} className="grid-container">
        <Box className='title-section'>
          <Typography variant="h4" gutterBottom>
            {titulo}
          </Typography>
        </Box>
        {questionAndAnswers.map((item, index) => (
          <><Grid item xs={4} className='item-response'>
            <h5>{item.questionDescription}: </h5>
          </Grid>
            <Grid item xs={8} className='item-response'>
              <h4>{
                item.questionValidation === 'RADIO' ? (
                  lstRespostas.prediagnostico[item.questionName] === "0" ? 'Não' : 'Sim'
                ) : (
                  item.questionValidation === 'TEXT' ?
                    lstRespostas.prediagnostico[item.questionName] :
                    // formatCurrency(lstRespostas.prediagnostico[item.questionName])
                    item.questionValidation === 'DOUBLE' ?
                      formatCurrency(isNaN(lstRespostas.prediagnostico[item.questionName]) ? 0 : lstRespostas.prediagnostico[item.questionName])
                      : formatInteger(isNaN(lstRespostas.prediagnostico[item.questionName]) ? 0 : lstRespostas.prediagnostico[item.questionName])
                )
              }</h4>
            </Grid></>
        ))}
      </Grid>
    );
  };

  return (
    <>
      <Box className='title'>
        <Typography variant="h4" gutterBottom>
          COMBUSTÍVEIS:
        </Typography>
      </Box>
      {lstRespostas.prediagnostico["general-data_3_1"] ? montaHmtlCombustivel('fuel-data-1', 'Bagaço de cana') : null}
      {lstRespostas.prediagnostico["general-data_3_2"] ? montaHmtlCombustivel('fuel-data-2', 'Bio diesel (B100)') : null}
      {lstRespostas.prediagnostico["general-data_3_3"] ? montaHmtlCombustivel('fuel-data-3', 'Bio gás') : null}
      {lstRespostas.prediagnostico["general-data_3_4"] ? montaHmtlCombustivel('fuel-data-4', 'Carvão') : null}
      {lstRespostas.prediagnostico["general-data_3_5"] ? montaHmtlCombustivel('fuel-data-5', 'Carvão vegetal') : null}
      {/* {lstRespostas.prediagnostico["general-data_3_6"]?montaHmtlCombustivel('fuel-data-6', 'Eletricidade', 0.0860)} : null*/}
      {lstRespostas.prediagnostico["general-data_3_7"] ? montaHmtlCombustivel('fuel-data-7', 'Gás natural') : null}
      {lstRespostas.prediagnostico["general-data_3_8"] ? montaHmtlCombustivel('fuel-data-8', 'Gasolina') : null}
      {lstRespostas.prediagnostico["general-data_3_9"] ? montaHmtlCombustivel('fuel-data-9', 'GLP') : null}
      {lstRespostas.prediagnostico["general-data_3_10"] ? montaHmtlCombustivel('fuel-data-10', 'Lenha (m³)-umidade normal(43%)') : null}
      {lstRespostas.prediagnostico["general-data_3_11"] ? montaHmtlCombustivel('fuel-data-11', 'Lenha (m³)-baixa umidade(36%)') : null}
      {lstRespostas.prediagnostico["general-data_3_12"] ? montaHmtlCombustivel('fuel-data-12', 'Lenha (t)-umidade normal(43%)') : null}
      {lstRespostas.prediagnostico["general-data_3_13"] ? montaHmtlCombustivel('fuel-data-13', 'Lenha (t)-baixa umidade(36%)') : null}

      {lstRespostas.prediagnostico["general-data_3_14"] ? montaHmtlCombustivel('fuel-data-14', 'Óleo combustível') : null}
      {lstRespostas.prediagnostico["general-data_3_15"] ? montaHmtlCombustivel('fuel-data-15', 'Óleo diesel') : null}
      {lstRespostas.prediagnostico["general-data_3_16"] ? montaHmtlCombustivel('fuel-data-16', 'Querosene') : null}
      {lstRespostas.prediagnostico["general-data_3_17"] ? montaHmtlCombustivel('fuel-data-17', 'Sol') : null}
    </>
  );
};

export default RelatorioResultadoCombustiveis;