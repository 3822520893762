import { action } from 'easy-peasy';

const processoStore = {
  current: null,
  select: action((state,payload)=>{
    state.current = payload;
  }),
};

export default processoStore;
