import React, { useEffect, useState } from "react";
import { useStoreActions, useStoreState } from "easy-peasy";
import {
    FormControl,
    Divider,
    Grid,
    Box,
    Typography,
    Button,
} from "@mui/material";
import api from "../services/api";
import DialogoConfirmacaoSimples from './DialogoConfirmacaoSimples';

import './StepPreDiagnosticoRelatorios/ModalRelResumo.css';

const StepPreDiagnosticoRelOutrasAcoes = ({ currProcesso, jsonData, jsonBanco, handleBack, openModal }) => {
    let setFinish = useStoreActions((actions) => actions.checkFinishReport.setFinish);
    const [exibirDados, setExibirDados] = React.useState(null);
    const [listOutrasAcoes, setListOutrasAcoes] = React.useState([]);
    const [relatorioGerado, setRelatorioGerado] = React.useState(null);
    const [botaoRelatorio, setBotaoRelatorio] = React.useState("Gerar Relatório");
    const [openConfirmaFinalizarPreDiagnostico, setOpenConfirmaFinalizarPreDiagnostico] = React.useState(false);
    const [isMobile, setIsMobile] = useState(false);
    const [currentStep, setCurrentStep] = useState(0);

    useEffect(() => {
        // Verifique o tamanho da tela e atualize o estado
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 600);
        };
        window.addEventListener("resize", handleResize);
        handleResize(); // Verifique o tamanho da tela ao carregar o componente

        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, []);

    useEffect(() => {
        setExibirDados(1);
        getStatusRelatorio();
        setListOutrasAcoes(getQuestionsAndAnswers("other-actions-considered_2"));
    }, []);

    const getStatusRelatorio = async () => {
        try {
            //   //recuperar o nome do auditor
            const dados = (await api.http.get("/prediagnosticos/report/" + jsonBanco.processoId + "/finished")).data;

            if (dados.reportGenerate !== null) {
                setRelatorioGerado(true);
                setBotaoRelatorio("Relatório");
            }
            else {
                setCurrentStep(dados.currentStep);
            }
        } catch (error) {
            console.error("Error API: ", error);
        }
    };

    const handleValidarRelatorioEncerado = () => {

        if (relatorioGerado === true) {
            openModal();
        } else {
            setOpenConfirmaFinalizarPreDiagnostico(true);
        }

    }

    const getQuestionsAndAnswers = (groupName) => {
        const questionsAndAnswers = [];
        let incluirpergunta = false;
        jsonData.steps.forEach((step) => {
            step.groups?.forEach((group) => {
                if (group.name === groupName) {
                    group.questions?.forEach((question) => {
                        //const  resultadoResposta = (parseInt(jsonBanco.prediagnostico[question.referenceQuestion]) === 1 ? true : false);
                        const resultadoResposta = parseInt(jsonBanco.prediagnostico[question.referenceQuestion]);
                        incluirpergunta = false;
                        // console.log("========================");
                        // console.log(question.name);
                        // console.log(resultadoResposta);
                        // console.log("========================");
                        if (question.energyEficiencySugestion === resultadoResposta) {
                            if (question.name === "other-actions-considered_2_4") {
                                // console.log("========================");
                                // console.log("outras ações ");
                                // console.log("resposta ", jsonBanco.prediagnostico["general-data_8_1"]);
                                // console.log(jsonBanco.prediagnostico["general-data_8_5"]);
                                // console.log(jsonBanco.prediagnostico["general-data_8_6"]);
                                if (
                                    jsonBanco.prediagnostico["general-data_8_1"] === "0" &&
                                    jsonBanco.prediagnostico["general-data_8_5"] === "1"
                                    /*
                                       regra indicada pelo vitor ,não para possui sistema fotovoltaico, e sim para a area disponivel
                                       &&
                                    (jsonBanco.prediagnostico["general-data_8_6"] !== null && jsonBanco.prediagnostico["general-data_8_6"] !== undefined)
                                    */
                                ) {
                                    incluirpergunta = true;
                                }
                            }
                            else {
                                if (question.name === "other-actions-considered_2_10") {
                                    if (jsonBanco.prediagnostico["general-data_7_1"] === "1"
                                        && parseInt(jsonBanco.prediagnostico["general-data_7_3"], 10) > 2080
                                        && parseInt(jsonBanco.prediagnostico["general-data_7_4"], 10) > 100
                                    ) {
                                        incluirpergunta = true;
                                    }
                                }
                                if (question.name === "other-actions-considered_2_11") {
                                    if (jsonBanco.prediagnostico["general-data_9_1"] === "0"
                                        && jsonBanco.prediagnostico["general-data_9_4"] === "1"
                                        && jsonBanco.prediagnostico["general-data_9_6"] === "1"
                                    ) {
                                        incluirpergunta = true;
                                    }
                                }
                                else {
                                    incluirpergunta = true;
                                }
                            }


                            if (incluirpergunta) {
                                questionsAndAnswers.push({
                                    questionName: question.name,
                                    questionDescription: question.description,
                                    questionObservation: question.observations
                                });
                            }
                        }
                    });
                }
            });
        });

        return questionsAndAnswers;
    };

    const finishedReportPreDiagnostico = async () => {
        try {
            const response = (
                await api.http.post("/prediagnosticos/report/finished", {
                    processo_id: currProcesso.id,
                    reportFinished: true,
                    currentStep: (currentStep + 2),
                })
            ).data;

        } catch (error) {
            console.error(error);
        }
    };

    const onConfirmaFinalizarPreDiagnostico = async (data) => {
        //registrar o ok na api
        finishedReportPreDiagnostico();
        openModal();
        setFinish(true);
        setOpenConfirmaFinalizarPreDiagnostico(false);
    };

    const onNaoConfirmaFinalizarPreDiagnostico = () => {
        setOpenConfirmaFinalizarPreDiagnostico(false);
    };

    return (

        <FormControl>
            <DialogoConfirmacaoSimples
                title="Atenção"
                open={openConfirmaFinalizarPreDiagnostico}
                onSim={onConfirmaFinalizarPreDiagnostico}
                onNao={onNaoConfirmaFinalizarPreDiagnostico}
            >
                O relatório gerado <b>NÃO</b> poderá mais ser editado. Ao finalizar você confirma que todos os dados foram atentamente analisados e que está de acordo com o relatório finalizado e que o mesmo será apresentado a empresa. <br />Deseja confirmar a finalização?
            </DialogoConfirmacaoSimples>
            <Divider>
                <h3>Outras ações consideradas</h3>
            </Divider>
            <Box
                className="container"
                sx={{
                    padding: "25px",
                }}
            >
                <Grid container spacing={0} className="grid-container-step">
                    {listOutrasAcoes.map((item, index) => (
                        <>
                            <Grid item xs={12} className="item-response-outrasacoes">
                                <body2>{index + 1}. {item.questionObservation} </body2>
                            </Grid>
                        </>
                    ))}
                </Grid>

                <Button
                    variant="outlined"
                    onClick={handleBack}
                    sx={{
                        flex: "none",
                        width: "40%",
                        padding: "10px",
                        marginTop: 2,
                        marginRight: "1rem",
                    }}
                >
                    Voltar
                </Button>
                <Button
                    variant="contained"
                    onClick={() => {
                        handleValidarRelatorioEncerado();
                    }}
                    sx={{
                        flex: 1,
                        padding: "10px",
                        width: "40%",
                        marginTop: 2,
                    }}
                    className={`${isMobile ? "mobile-hidden" : ""}`}
                >
                    {botaoRelatorio}
                </Button>
            </Box>
        </FormControl>
    );
};

export default StepPreDiagnosticoRelOutrasAcoes;