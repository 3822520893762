import React, { useEffect, useState, useRef } from 'react';
import Button from '@mui/material/Button';
import StepPreDiagnosticoRelResultado from './StepPreDiagnosticoRelResultado';
import './StepPreDiagnosticoRelatorios/ModalRelResumo.css';
import { ReactToPrint } from 'react-to-print';
import Dialog from '@mui/material/Dialog';
import { DialogTitle, DialogContent, DialogActions, Box, Backdrop } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import ListItemText from '@mui/material/ListItemText';
import ListItemButton from '@mui/material/ListItemButton';
import Slide from '@mui/material/Slide';
import {
  Paper,
} from "@mui/material";


const StepPreDiagnosticoModalRelatorioResumo = ({ isOpen, onClose, currProcesso, jsonData, jsonBanco }) => {

  const modalRef = useRef(null);
  const handlePrint = (e) => {
    e.preventDefault();
    //console.log("inicio impressão ", currProcesso);
    const printArea = modalRef.current;
    const printLogo = document.createElement('div');
    const logoImage = document.createElement('img');
    const originalTitle = document.title; // Guarda o título original

    if (modalRef.current) {
      // printLogo.className = 'print-logo';
      // logoImage.src = 'https://potencializee.develop.pumpkintech.com.br/logo.png';
      // logoImage.alt = 'Potencializze';
      // printLogo.appendChild(logoImage);
      // document.body.appendChild(printLogo);

      // Define o título temporário para o nome do arquivo
      document.title = "Pré-Diagnostico - " + currProcesso.razaoSocial;

      // Executa a impressão
      window.print();

      // Remove o logo e restaura o título original
      document.body.removeChild(printLogo);
      document.title = originalTitle;
    }

  };



  return (

    isOpen && (


      <div style={{
        top: 0,
        left: 0,
        width: "100vw",
        height: "100% !important",
        backgroundColor: "white",
        position: "absolute",
        zIndex: 999
      }}
      >

        <DialogContent className="print-container">
          <div ref={modalRef}>
            <Paper
              variant="elevation"
              className="center-box"
              sx={{
                textAlign: "center",
                px: 4,
                pt: 2,
                pb: 3,
                maxWidth: "md",
              }}
            >
              <StepPreDiagnosticoRelResultado
                jsonData={jsonData}
                jsonBanco={jsonBanco}
                currProcesso={currProcesso} />
            </Paper>
          </div>

        </DialogContent>
        <DialogActions className='print-hidden'>
          {/* <Button onClick={handleExport}>Exportar</Button> */}

          <Button onClick={handlePrint}>Imprimir</Button>

          <Button onClick={onClose}>Fechar</Button>
        </DialogActions>
      </div>
    )
  );
};

export default StepPreDiagnosticoModalRelatorioResumo;