import * as React from 'react';
import Tooltip from '@mui/material/Tooltip';
import InfoIcon from '@mui/icons-material/Info';

const InfoTooltip = ({ text }) => {
    
    return (
        <Tooltip title={text}>
          <InfoIcon style={{ color: "white" }}></InfoIcon>
        </Tooltip>
      );
  };
  
  export default InfoTooltip;
  