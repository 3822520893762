import React, { useState } from "react";
import {
  Grid,
  FormControl,
  Divider,
  Button,
  Box,
  Stack,
  Paper,
  DialogContentText,
  TextField as MUITextField,
} from "@mui/material";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { pt } from "yup-locale-pt";
import {
  DialogoConfirmacao,
  Select,
  DateField,
  CNPJField,
  TextField,
  Checkbox,
  Timestamps,
  Autocomplete,
} from "../../components";
import { useStoreState, useStoreActions } from "easy-peasy";
import api from "../../services/api";
import DialogoAcompanhaOrcamentos from "./DialogoAcompanhaOrcamentos";
yup.setLocale(pt);

const CadastroProjeto = () => {
  let currProcesso = useStoreState((state) => state.processo.current);
  let selectProcesso = useStoreActions((actions) => actions.processo.select);
  let startLoading = useStoreActions((actions) => actions.nav.startLoading);
  let stopLoading = useStoreActions((actions) => actions.nav.stopLoading);
  const [projeto, setProjeto] = React.useState(null);
  const [listaConsultores, setListaConsultores] = React.useState([]);
  const [listaScrumMasters, setListaScrumMasters] = React.useState([]);
  const [fornecedores, setFornecedores] = React.useState([]);

  const [exibirScrumMaster, setExibirScrumMaster] = React.useState(false);
  const [exibirAuditor, setExibirAuditor] = React.useState(false);

  const schema = yup
    .object({
      consultorId: yup
        .number()
        .positive()
        .when("responsavel", {
          is: (responsavel) => responsavel === "S",
          then: (schema) => schema.required(),
        })
        .transform((value) => (isNaN(value) ? undefined : value))
        .label("Consultor"),
      dataInicio: yup
        .date()
        .nullable()
        .label("Data prevista para início")
        .when(["$projeto", "responsavel"], {
          is: (projeto, responsavel) => projeto && responsavel === "S",
          then: (schema) => schema.required(),
        }),
      dataFim: yup
        .date()
        .nullable()
        .label("Data prevista para finalização")
        .when(["$projeto", "responsavel"], {
          is: (projeto, responsavel) => projeto && responsavel === "S",
          then: (schema) => schema.required(),
        }),
      cnpjsExec: yup
        .string()
        .nullable()
        .label("CNPJ das empresas executoras (um CNPJ por linha)"),
      obsProjeto: yup.string().nullable().label("Observações"),
      altEficiencia: yup
        .string()
        .nullable()
        .label(
          "Alterações em relação as medidas de " +
          "eficiência energética inicialmente " +
          "previstas"
        ),
      orcamentosRevisados: yup.boolean().label("Orçamentos revisados"),
      responsavel: yup
        .string()
        .nullable()
        .label("Responsável pelo Acompanhamento do Projeto"),
      nomeEntidadeResponsavel: yup
        .string()
        .nullable()
        .when("responsavel", {
          is: (responsavel) => "BT".includes(responsavel),
          then: (schema) =>
            schema
              .required()
              .label(responsavel === "B" ? "ESCO Responsável" : "Responsável"),
        }),
      fornecedoresResponsaveis: yup
        .array()
        .nullable()
        .label("Fornecedores Responsáveis"),
      scrumMasterId: yup
        .number()
        .positive()
        .label("Escolha um Especialista Lider")
        .nullable(),
    })
    .required();

  const defaultValues = {
    dataInicio: null,
    dataFim: null,
    consultorId: null,
  };

  const useFormRef = useForm({
    mode: "onTouched",
    context: { projeto },
    resolver: yupResolver(schema),
    defaultValues: defaultValues,
  });

  const {
    handleSubmit,
    watch,
    reset,
    getValues,
    setValue,
    formState: { isDirty, isSubmitting },
  } = useFormRef;

  const listaResponsaveis = [
    {
      nome: "SENAI",
      id: "S",
    },
    {
      nome: "ESCOs (BOT)",
      id: "B",
    },
    {
      nome: "PME",
      id: "P",
    },
    {
      nome: "Fornecedores",
      id: "F",
    },
    {
      nome: "Terceiros",
      id: "T",
    },
  ];

  const consultorId = watch("consultorId");
  const scrumMasterId = watch("scrumMasterId");
  const dataInicio = watch("dataInicio");
  const dataFim = watch("dataFim");
  const responsavel = watch("responsavel");
  const nomeEntidadeResponsavel = watch("nomeEntidadeResponsavel");

  const [confirmacaoMsg, setConfirmacaoMsg] = React.useState([]);

  const loadFornecedores = async (mount) => {
    startLoading();
    try {
      let data = (await api.http.get("/fornecedores/simplified")).data;
      if (mount.isMounted)
        setFornecedores(
          data.map((fornecedor) => ({
            label: fornecedor.nome,
            id: fornecedor.id,
          }))
        );

      setListaScrumMasters(
        (
          await api.http.get("/users", {
            params: {
              roleId: "scrumMaster",
              habilitado: true,
              ordenado_alfa: true,
            },
          })
        ).data
      );
      stopLoading();
    } catch (error) {
      stopLoading({
        message: error.toString(),
        severity: "error",
      });
    }
  };

  React.useEffect(() => {
    if (
      responsavel === "F" &&
      (fornecedores === null || fornecedores.length === 0)
    ) {
      loadFornecedores();
    }
  }, [responsavel]);

  const verificaPreenchimento = () => {
    let erros = [];
    if (responsavel === "S" && !getValues("cnpjsExec"))
      erros.push("Inclusão das empresas executoras");
    if (erros.length > 0)
      return (
        <React.Fragment>
          <DialogContentText>
            Ainda não foram realizadas as seguintes ações:
          </DialogContentText>
          <ul>
            {erros.map((erro, index) => (
              <li key={index}>
                <DialogContentText>{erro}</DialogContentText>
              </li>
            ))}
          </ul>
        </React.Fragment>
      );
    return "";
  };

  const aprova = (data) => {
    setConfirmacaoMsg(
      <React.Fragment>
        {verificaPreenchimento()}
        <DialogContentText>
          Esta ação não pode ser desfeita. Tem certeza que deseja{" "}
          <b>iniciar </b>o projeto?
        </DialogContentText>
      </React.Fragment>
    );
    setOpenConfirmacao(true);
  };

  const salva = async (data) => {
    startLoading();
    try {
      let projetoData;
      if (data.id)
        projetoData = (await api.http.put("/projetos/" + data.id, data)).data;
      else {
        let post = (
          await api.http.post("/projetos", {
            ...data,
            processoId: currProcesso.id,
          })
        ).data;
        projetoData = post.projeto;

        selectProcesso(post.processo);
      }

      stopLoading({ severity: "success" });
      projetoData.fornecedoresResponsaveis =
        projetoData.fornecedoresResponsaveis?.map(
          (fornecedor) => fornecedor.id
        );
      reset(projetoData);
      setProjeto(projetoData);
    } catch (error) {
      stopLoading({
        message: error.toString(),
        severity: "error",
      });
      throw error;
    }
  };

  const cancela = () => {
    reset();
  };

  const [openConfirmacao, setOpenConfirmacao] = React.useState(false);

  const onSimConfirma = async (data) => {
    setOpenConfirmacao(false);
    const confirmaData = {
      ...data,
      estagio: "projetoCadastrado",
    };
    startLoading();
    try {
      let post = (await api.http.put("/projetos/" + projeto.id, confirmaData))
        .data;
      setProjeto(post.projeto);
      reset(post.projeto);
      selectProcesso(post.processo);
      stopLoading({ severity: "success" });
    } catch (error) {
      stopLoading({
        message: error.toString(),
        severity: "error",
      });
      throw error;
    }
  };
  const onNaoConfirma = () => {
    setOpenConfirmacao(false);
  };

  let user = useStoreState((state) => state.auth.user);
  const disabledAlocacao = () => {
    return (
      (user.roleId !== "superAdmin" &&
        !["projetoIniciado", "finAprovado"].includes(currProcesso.estagio)) ||
      !["superAdmin", "gestorSENAI", "scrumMaster"].includes(user.roleId)
    );
  };
  const disabled = () => {
    return (
      (user.roleId !== "superAdmin" &&
        !["projetoIniciado", "finAprovado"].includes(currProcesso.estagio)) ||
      ![
        "consultorEE",
        "superAdmin",
        "validadorIndSENAI",
        "gestorSENAI",
      ].includes(user.roleId) ||
      (["B", "T"].includes(responsavel) &&
        (nomeEntidadeResponsavel === undefined ||
          nomeEntidadeResponsavel === null ||
          nomeEntidadeResponsavel === ""))
    );
  };

  React.useEffect(async () => {
    let mount = {
      isMounted: true,
    };
    loadConsultores(mount);
    loadFornecedores(mount);
    return () => {
      mount.isMounted = false;
    };
  }, []);

  const loadConsultores = async (mount) => {
    startLoading();
    try {
      let data = (
        await api.http.get("/users", {
          params: {
            roleId: "consultorEE",
            habilitado: true,
            ordenado_alfa: true,
          },
        })
      ).data;
      if (mount.isMounted) setListaConsultores(data);
      stopLoading();
    } catch (error) {
      stopLoading({
        message: error.toString(),
        severity: "error",
      });
    }
  };

  React.useEffect(async () => {
    startLoading();
    try {
      if (
        currProcesso.estagio != "finAprovado" &&
        currProcesso.estagio !== "finRecProprio" &&
        currProcesso.estagio !== "financiadoBOT"
      ) {
        let projetoData = (await api.http.get("/projetos/" + currProcesso.id))
          .data;
        // console.log("projetoDAta", projetoData);
        projetoData.fornecedoresResponsaveis =
          projetoData.fornecedoresResponsaveis?.map(
            (fornecedor) => fornecedor.id
          );
        reset(projetoData);
        setProjeto(projetoData);
        if (projetoData.scrumMasterId) {
          setExibirScrumMaster(true);
        }
      }
      else {
        //método que recupera o Especialista Lider da fase anterior
        const diagnosticosdata = (await api.http.get("/diagnosticos/" + currProcesso.id))
          .data;
        // console.log("diagnosticosdata", diagnosticosdata);

        let projetoData = (await api.http.get("/projetos/" + currProcesso.id))
          .data;
        // console.log("projetoData", projetoData);

        projetoData.fornecedoresResponsaveis =
          projetoData.fornecedoresResponsaveis?.map(
            (fornecedor) => fornecedor.id
          );
        reset(projetoData);
        setProjeto(projetoData);
        if (projetoData.scrumMasterId) {
          setExibirScrumMaster(true);
        }

        //atualizar o valor do Especialista Lider
        setValue("scrumMasterId", diagnosticosdata.scrumMasterId);
      }

      if (currProcesso.scrumNotApply === 2) {
        setExibirScrumMaster(true);
      }
      stopLoading();
    } catch (error) {
      stopLoading({
        message: error.toString(),
        severity: "error",
      });
    }
  }, []);

  const [openDlgAcomOrcs, setOpenDlgAcomOrcs] = React.useState(false);

  const acompanhaOrcamento = () => {
    setOpenDlgAcomOrcs(true);
  };

  const onCloseDlgAcomOrcs = () => {
    setOpenDlgAcomOrcs(false);
  };

  const handleSalvarScrumMaster = async () => {

    // Coloque a lógica para salvar o Especialista Lider aqui

    startLoading();
    try {
      let retorno;

      retorno = (
        await api.http.post("/projetos/scrummaster", {
          processoId: currProcesso.id,
          scrumMasterId: scrumMasterId,
        })
      ).data;

      stopLoading({ severity: "success" });
      //reset(retorno.diagnostico);

      reset(retorno.projeto);
      setProjeto(retorno.projeto);
      //setDiagnostico(retorno.diagnostico);
      selectProcesso(retorno.processo);
      setExibirScrumMaster(true);
    } catch (error) {
      stopLoading({
        message: error.toString(),
        severity: "error",
      });
      throw error;
    }
  };

  const disabledBtScrumMaster = () => {
    return (
      (user.roleId !== "superAdmin" &&
        user.roleId !== "gestorSENAI"
      )
    );
  };

  const actionButtons2 = () => {
    return (
      <React.Fragment>
        {/* <Button
          variant="outlined"
          size="large"
          sx={{ mr: 2 }}
          onClick={cancela}
        // disabled={!isDirty || isSubmitting}
        >
          CANCELAR
        </Button> */}
        <Button
          variant="contained"
          size="large"
          onClick={handleSalvarScrumMaster}
        //  disabled={!isDirty || isSubmitting}
        >
          SALVAR
        </Button>
      </React.Fragment>
    );
  }

  // console.log("Responsavel ", responsavel);

  const actionButtons = () => {
    if (
      isDirty ||
      !projeto ||
      (responsavel === "S" && (!dataInicio || !dataFim)) ||
      currProcesso.estagio != "projetoIniciado"
    ) {
      return (
        <React.Fragment>
          <Button
            variant="outlined"
            size="large"
            sx={{ mr: 2 }}
            onClick={cancela}
            disabled={!isDirty || isSubmitting}
          >
            CANCELAR
          </Button>
          <Button
            variant="contained"
            size="large"
            onClick={handleSubmit(salva)}
            disabled={!isDirty || isSubmitting}
          >
            SALVAR
          </Button>
        </React.Fragment>
      );
    } else {
      return (
        <React.Fragment>
          <Button variant="contained" size="large" onClick={aprova}>
            INICIAR PROJETO
          </Button>
        </React.Fragment>
      );
    }
  };

  return (
    <Paper
      variant="outlined"
      className="center-box"
      sx={{
        textAlign: "center",
        px: 4,
        pt: 2,
        pb: 3,
        maxWidth: "md",
      }}
    >
      <DialogoConfirmacao
        title="Atenção"
        open={openConfirmacao}
        onSim={onSimConfirma}
        onNao={onNaoConfirma}
      >
        {confirmacaoMsg}
      </DialogoConfirmacao>
      <DialogoAcompanhaOrcamentos
        open={openDlgAcomOrcs}
        onClose={onCloseDlgAcomOrcs}
        disabled={disabled()}
      />

      {!exibirScrumMaster && (
        <>
          <form>
            <Divider>
              <h3>Alocação do Especialista Lider do projeto</h3>
            </Divider>
            <FormControl sx={{ my: 2 }} fullWidth>
              <Select
                field="scrumMasterId"
                useFormRef={useFormRef}
                schema={schema}
                options={listaScrumMasters}
                getOptionLabel={(option) => option.nome}
                disabled={disabledBtScrumMaster()}
              />
            </FormControl>

            <Box sx={{ mt: 3, textAlign: "center" }}>{actionButtons2()}</Box>

          </form>
        </>
      )}

      {/* {exibirScrumMaster && ( */}
      <>
        <form onSubmit={handleSubmit(aprova)}>
          {currProcesso.scrumNotApply === 1 && (
            <>
              <Divider>
                <h3>Alocação do Especialista Lider do projeto</h3>
              </Divider>
              <FormControl sx={{ my: 2 }} fullWidth>
                <Select
                  field="scrumMasterId"
                  useFormRef={useFormRef}
                  schema={schema}
                  options={listaScrumMasters}
                  getOptionLabel={(option) => option.nome}
                  disabled={disabledBtScrumMaster()}
                />
              </FormControl>
            </>
          )}

          <Divider>
            <h3>Responsável pelo Acompanhamento do Projeto</h3>
          </Divider>
          <FormControl sx={{ my: 2 }} fullWidth>
            <Select
              field="responsavel"
              useFormRef={useFormRef}
              schema={schema}
              options={listaResponsaveis}
              getOptionLabel={(option) => option.nome}
              disabled={disabledAlocacao()}
            />
          </FormControl>
          {responsavel && responsavel === "S" && (
            <React.Fragment>
              <Divider>
                <h3>Responsável pelo M&V</h3>
              </Divider>
              <FormControl sx={{ my: 2 }} fullWidth>
                <Select
                  field="consultorId"
                  useFormRef={useFormRef}
                  schema={schema}
                  options={listaConsultores}
                  getOptionLabel={(option) => option.nome}
                  disabled={disabledAlocacao()}
                />
              </FormControl>
            </React.Fragment>
          )}
          {responsavel && "BT".includes(responsavel) && (
            <React.Fragment>
              <Divider>
                <h3>
                  {responsavel === "B"
                    ? "ESCO Responsável"
                    : "Terceiro Responsável"}
                </h3>
              </Divider>
              <FormControl sx={{ my: 2 }} fullWidth>
                <TextField
                  field="nomeEntidadeResponsavel"
                  rows={6}
                  useFormRef={useFormRef}
                  schema={schema}
                  disabled={
                    disabled() &&
                    nomeEntidadeResponsavel !== undefined &&
                    nomeEntidadeResponsavel !== ""
                  }
                />
              </FormControl>
            </React.Fragment>
          )}

          {responsavel && responsavel === "F" && (
            <React.Fragment>
              <Divider>
                <h3>"Fornecedores Responsáveis"</h3>
              </Divider>
              <FormControl sx={{ my: 2 }} fullWidth>
                <Autocomplete
                  field="fornecedoresResponsaveis"
                  rows={6}
                  useFormRef={useFormRef}
                  schema={schema}
                  disabled={disabled()}
                  multiple
                  options={fornecedores}
                  getOptionLabel={(fornecedor) => fornecedor.label}
                />
              </FormControl>
            </React.Fragment>
          )}

          {projeto?.id && consultorId && responsavel && (
            <React.Fragment>
              <Divider>
                <h3>Cadastro do Projeto</h3>
              </Divider>
              <Grid container spacing={2}>
                <Grid item xs={4}>
                  <FormControl sx={{ my: 2 }} fullWidth>
                    <DateField
                      required
                      field="dataInicio"
                      useFormRef={useFormRef}
                      schema={schema}
                      disabled={disabled()}
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={4}>
                  <FormControl sx={{ my: 2 }} fullWidth>
                    <DateField
                      required
                      field="dataFim"
                      useFormRef={useFormRef}
                      schema={schema}
                      disabled={disabled()}
                    />
                  </FormControl>
                </Grid>
              </Grid>
              <FormControl sx={{ my: 2 }} fullWidth>
                <TextField
                  field="cnpjsExec"
                  multiline
                  rows={6}
                  useFormRef={useFormRef}
                  schema={schema}
                  disabled={disabled()}
                />
              </FormControl>
              <FormControl sx={{ my: 2 }} fullWidth>
                <TextField
                  field="obsProjeto"
                  multiline
                  rows={6}
                  useFormRef={useFormRef}
                  schema={schema}
                  disabled={disabled()}
                />
              </FormControl>
              <FormControl sx={{ my: 2 }} fullWidth>
                <TextField
                  field="altEficiencia"
                  multiline
                  rows={6}
                  useFormRef={useFormRef}
                  schema={schema}
                  disabled={disabled()}
                />
              </FormControl>
              <FormControl sx={{ my: 2 }} fullWidth>
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <Button variant="outlined" onClick={acompanhaOrcamento}>
                      REVISA ORÇAMENTO
                    </Button>
                  </Grid>
                  <Grid item xs={6}>
                    <Checkbox
                      field="orcamentosRevisados"
                      disabled={disabled()}
                      schema={schema}
                      useFormRef={useFormRef}
                    />
                  </Grid>
                </Grid>
              </FormControl>
              {projeto?.id &&
                currProcesso &&
                currProcesso.estagio != "projetoIniciado" && (
                  <React.Fragment>
                    <Divider>
                      <h3>Observações de projeto iniciado</h3>
                    </Divider>
                    <FormControl sx={{ my: 2 }} fullWidth>
                      <MUITextField
                        value={projeto?.observacoes}
                        multiline
                        rows={4}
                        disabled={true}
                      />
                    </FormControl>
                  </React.Fragment>
                )}

              <Timestamps dados={projeto} />
            </React.Fragment>
          )}
          {!disabled() && (
            <Box sx={{ mt: 3, textAlign: "center" }}>{actionButtons()}</Box>
          )}
        </form>
      </>
      {/* )} */}

    </Paper>
  );
};

export default CadastroProjeto;
