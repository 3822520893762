import * as React from "react";
import { Autocomplete as MUIAutocomplete, TextField } from "@mui/material";
import { Controller } from "react-hook-form";

const Autocomplete = ({
  field,
  useFormRef,
  schema,
  options,
  groupBy,
  disabled,
  defaultValue,
  ...props
}) => {
  const { control } = useFormRef;

  return (
    <Controller
      name={field}
      control={control}
      render={({
        field: { onChange, onBlur, value, name, ref },
        fieldState: { invalid, isTouched, isDirty, error },
      }) => {
        const transfOutput = (value) => {
          if (props.multiple) {
            if (value) return value.map((val) => val.id);
            else return [];
          } else {
            if (value) return value.id;
            else return null;
          }
        };

        const transfInput = (value) => {
          if (props.multiple) {
            let result = [];
            if (!value) return result;
            for (let i = 0; i < value.length; i++) {
              let selected = options.find((option) => option.id == value[i]);
              if (selected) result.push(selected);
            }
            return result;
          } else {
            if (!value) return null;
            return options.find((option) => option.id == value);
          }
        };

        return (
          <MUIAutocomplete
            options={options}
            groupBy={groupBy}
            noOptionsText="Sem opções"
            value={transfInput(value)}
            renderInput={(params) => (
              <TextField
                {...params}
                label={schema?.fields[field].spec.label}
                required={schema?.fields[field].exclusiveTests["required"]}
                error={error != null}
                helperText={error?.message}
                onBlur={onBlur}
                inputRef={ref}
              />
            )}
            onChange={(event, item) => {
              onChange(transfOutput(item));
            }}
            disabled={disabled}
            {...props}
          />
        );
      }}
    />
  );
};

export default Autocomplete;
