import MaterialTable from "@material-table/core";
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
} from "@mui/material";
import { useStoreActions, useStoreState } from "easy-peasy";
import { useEffect, useState } from "react";
import { materialTableLocalization } from "../../config";
import api from "../../services/api";

const columns = [
  {
    field: "createdAt",
    align: "left",
    title: "Data",
  },
  {
    field: "acao",
    align: "left",
    title: "Tipo de ação",
  },
  {
    field: "email",
    align: "left",
    title: "Usuário",
  },
  {
    field: "processo",
    align: "left",
    title: "Processo",
  },
  {
    field: "razaoSocial",
    align: "left",
    title: "Razão Social",
  },
  {
    field: "etapa",
    align: "left",
    title: "Etapa",
  },
];

const acao = {
  1: "Criação",
  2: "Atualização",
};

const etapa = {
  1: "Cadastro de Elegíveis",
  2: "Pré-Diagnóstico Energético",
  3: "Diagnóstico Energético",
  4: "Financiamento",
  5: "Cadastro do Projeto",
  6: "Implementação",
};

const DialogoHistorico = ({ open, onClose }) => {
  let loading = useStoreState((state) => state.nav.loading);
  let startLoading = useStoreActions((actions) => actions.nav.startLoading);
  let stopLoading = useStoreActions((actions) => actions.nav.stopLoading);
  const [historico, setHistorico] = useState([]);

  const loadHistorico = async () => {
    startLoading();
    try {
      const response = await api.http.get("/historico");

      const data = response.data.map((hist) => {
        return {
          ...hist,
          createdAt: new Date(hist.createdAt).toLocaleDateString(),
          acao: acao[hist.acao],
          etapa: etapa[hist.etapa],
        };
      });

      setHistorico(data);
      stopLoading();
    } catch (error) {}
  };

  useEffect(async () => {
    if (open) await loadHistorico();
  }, [open]);

  return (
    <Dialog open={open} onClose={onClose} maxWidth="xl" fullWidth={true}>
      <DialogContent>
        <MaterialTable
          columns={columns}
          data={historico}
          title="Histórico"
          localization={materialTableLocalization}
          options={{
            search: false,
          }}
        />
      </DialogContent>
      <DialogActions>
        {loading > 0 && (
          <CircularProgress
            size={30}
            sx={{
              position: "absolute",
              left: 25,
              bottom: 12,
            }}
          />
        )}
        <Button
          color="primary"
          onClick={() => {
            onClose();
          }}
        >
          OK
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DialogoHistorico;
