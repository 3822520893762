import React, {useState} from 'react';
import PropTypes from 'prop-types';
import {
  Tabs,
  Tab,
  Box,
  Paper,
  Tooltip,
  Button
} from '@mui/material';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import AdjustIcon from '@mui/icons-material/Adjust';
import MaterialTable from '@material-table/core';
import {
  materialTableLocalization,
  materialTableCurrencySettings
} from '../../config';
import { useStoreActions } from 'easy-peasy';
import api from "../../services/api";
import {
  listaEnergeticos,
  subsegmentos,
  listaProcessos,
  sistemas,
  textoExAnte,
  textoExPost
} from '../../static';
import { CSVDownload } from "react-csv";
import DialogoTargets from './DialogoTargets';

const Benchmarking = () => {

  let startLoading = useStoreActions((actions) => actions.nav.startLoading);
  let stopLoading = useStoreActions((actions) => actions.nav.stopLoading);

  const loadBenchmarking = async (query) => {
    startLoading();
    try {
      let getBenchmarkings = (await api.http.get('/benchmarkings',{
        params: {
          final: final,
          page: query.page+1,
          pageSize: query.pageSize,
          search: query.search,
          orderBy: query.orderBy?query.orderBy.field:undefined,
          orderDirection: query.orderDirection?query.orderDirection:undefined
        }
      })).data;
      stopLoading();
      return {
        data: getBenchmarkings.data,
        page: getBenchmarkings.meta.current_page-1,
        totalCount: getBenchmarkings.meta.total
      };
    } catch (error) {
      stopLoading({
        message: error.toString(),
        severity: 'error'
      });
      return {
        data: [],
        page: 0,
        totalCount: 0
      };
    };
  };

  const [final, setFinal] = React.useState(0);

  const handleChange = (event, newValue) => {
    setFinal(newValue);
    tableRef.current && tableRef.current.onQueryChange();
  };

  const columns = [{
    field: 'createdAt', title: 'Data',
    type: 'date'
  },{
    field: 'subsegmento', title: 'Sub-segmento',
    lookup: Object.keys(subsegmentos).reduce((map,segmento)=>{
      map = subsegmentos[segmento].reduce((map,subsegmento)=>{
        map[subsegmento.id] = subsegmento.label;
        return map;
      },map);
      return map;
    },{})
  },{
    field: 'processoBenchmarking', title: 'Processo',
    lookup: listaProcessos.reduce((map,processo)=>{
      map[processo.id] = processo.label;
      return map;
    },{})
  },{
    field: 'sistema', title: 'Sistema',
    lookup: Object.keys(sistemas).reduce((map,processo)=>{
      map = sistemas[processo].reduce((map2,sistema)=>{
        map2[sistema.id] = sistema.label;
        return map2;
      },map);
      return map;
    },{})
  },{
    field: 'producaoAnual', title: 'Produção Anual (ton)'
  },{
    field: 'energeticoUtilizado', title: 'Energético Utilizado',
    lookup: listaEnergeticos.reduce((map,energetico)=>{
      map[energetico.id] = energetico.label;
      return map;
    },{})
  },{
    field: 'consumoAnual', title: 'Consumo Anual Atual (MWh)'
  },{
    field: 'consumoAnualFinal', title: 'Consumo Anual Proposto (MWh)'
  },{
    field: 'investimentoEstimado',
    title: 'Investimento Estimado (R$)',
    type: 'currency',
    currencySetting: materialTableCurrencySettings
  },{
    field: 'custoEnergetico', title: 'Custo do Energético (R$/MWh)',
    type: 'currency',
    currencySetting: materialTableCurrencySettings
  }];

  const tableRef = React.createRef();
  const [registros, setRegistros] = React.useState([]);
  const exporta = async () => {
    startLoading();
    try {
      setRegistros((await api.http.get("benchmarkings",{
        params: {
          final: final
        }
      })).data);
      setRegistros([]);
      stopLoading({ severity: 'success' });
    } catch(error) {
      stopLoading({
        message: error.toString(),
        severity: 'error'
      });
    }
  };

  const [openDlgTargets, setOpenDlgTargets] = React.useState(false);

  const target = () => {
    setOpenDlgTargets(true);
  };

  const onCloseDlgTargets = (reload=false) => {
    setOpenDlgTargets(false);
  };

  return (
    <Paper variant="outlined" className='center-box' sx={{my:3}}>
      { registros && registros.length>0 &&
        <CSVDownload
          data={registros}
          target="_blank" />
      }
      <DialogoTargets
        open={openDlgTargets}
        onClose={onCloseDlgTargets} />
      <Box sx={{float:"right"}}>
        <Button variant="outlined" sx={{ mr: 2, mt:2 }} onClick={target}>
          <AdjustIcon /> Resultados Benchmarking
        </Button>
        <Button variant="outlined" sx={{ mr: 3, mt: 2 }}  onClick={exporta}>
          <FileDownloadIcon /> Exportar
        </Button>
      </Box>
      <Tabs
        value={final}
          variant="scrollable"
          scrollButtons="auto"
          allowScrollButtonsMobile
          onChange={handleChange}  >
        <Tab label={<Tooltip title={textoExAnte}>
                      <span>Ex-Ante</span>
                    </Tooltip>} />
        <Tab label={<Tooltip title={textoExPost}>
                      <span>Ex-Post</span>
                    </Tooltip>} />
      </Tabs>
      <Box sx={{ p: 3 }}>
        <MaterialTable
          tableRef={tableRef}
          columns={columns}
          data={loadBenchmarking}
          title="Dados de Benchmarking"
          localization={materialTableLocalization}
        />
      </Box>
    </Paper>
  );
};

export default Benchmarking;
