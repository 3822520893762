import React, { useEffect } from 'react';
import { Grid, Box, Typography } from "@mui/material";
import './ModalRelResumo.css';
import { Pie } from 'react-chartjs-2';
// Importando os módulos necessários para Chart.js
import ChartDataLabels from "chartjs-plugin-datalabels";
import { Chart as ChartJS, ArcElement, Tooltip } from 'chart.js';
ChartJS.register(ArcElement, Tooltip, ChartDataLabels);

const RelatorioResultadoChartEmissao = ({ lstQuestionario, lstRespostas }) => {

  const [seriesData, setSeriesData] = React.useState([]);
  const [totais, setTotais] = React.useState(0);

  const cores = [
    '#0000CD', '#00ff00', '#F0E68C', '#4169E1',
    '#008000', '#293896', '#6495ED',
    '#62BB46', '#B0E0E6', '#F39325', '#4682B4',
    '#00BFFF', '#FF8C00', '#81D3EB',

  ];

  const data = {
    labels: seriesData.map((item) => item.label), // Labels para a legenda
    datasets: [
      {
        data: seriesData.map((item) => item.value), // Valores para cada fatia
        backgroundColor: cores,
      },
    ],
  };

  const options = {
    plugins: {
      datalabels: {
        formatter: (value, context) => {
          // Calcula a porcentagem com base nos dados
          const total = context.dataset.data.reduce((a, b) => a + b, 0);
          const percentage = ((value / total) * 100).toFixed(1);
          return `${percentage}%`; // Retorna a porcentagem como rótulo
        },
        color: '#000000', // Cor do texto
        font: {
          weight: 'bold', // Negrito
        },
        offset: 20,
        rotation: 0,
        anchor: 'center', // Centraliza no meio da fatia
        align: 'center', // Alinha no centro
      },
      legend: {
        display: false, // Desabilita a legenda incorporada
      },
    },
  };

  const addToTotal = (value) => {
    setTotais((prevTotal) => prevTotal + value);
    // return context.dataset.data.reduce((a, b) => parseFloat(a) + parseFloat(b), 0);
  };

  const adicionarItem = (item) => {
    setSeriesData((dadosAtuais) => [...dadosAtuais, item]); // Adiciona o novo item ao final da lista
  };

  const getEnergiaEletrica = () => {
    const titulo = "Eletricidade";
    const un_ano = lstRespostas.prediagnostico["general-data_2_7"];
    const mwh_ano = lstRespostas.prediagnostico["general-data_2_8"];
    const FatorEmissao = 1;
    const vidasUteis = 0.0860;

    addFonteResumo(titulo, un_ano, Math.ceil(un_ano * FatorEmissao / 1000), mwh_ano, (vidasUteis === 0 ? 0 : ((un_ano * FatorEmissao / 1000) * vidasUteis)));
  };

  useEffect(() => {
    getEnergiaEletrica();
    if (lstRespostas.prediagnostico["general-data_3_1"]) montaResumoFonte('fuel-data-1', 'Bagaço de cana', 2475.53, 0.3672);
    if (lstRespostas.prediagnostico["general-data_3_2"]) montaResumoFonte('fuel-data-2', 'Bio diesel (B100)', 9.20, 0.0009);
    if (lstRespostas.prediagnostico["general-data_3_3"]) montaResumoFonte('fuel-data-3', 'Bio gás', 6.97, 0.196875);
    if (lstRespostas.prediagnostico["general-data_3_4"]) montaResumoFonte('fuel-data-4', 'Carvão', 3428.55, 0.3668);
    // if (lstRespostas.prediagnostico["general-data_3_5"]) montaResumoFonte('fuel-data-5', 'Carvão vegetal', 1, 0.0240);
    if (lstRespostas.prediagnostico["general-data_3_5"] === true) { montaResumoFonteLenha('fuel-data-5', 'Carvão vegetal', 7507.952502, 0.024, 0.408096) };
    if (lstRespostas.prediagnostico["general-data_3_7"]) montaResumoFonte('fuel-data-7', 'Gás natural', 10.23, 0.2022);
    if (lstRespostas.prediagnostico["general-data_3_8"]) montaResumoFonte('fuel-data-8', 'Gasolina', 12.09, 0.2504);
    if (lstRespostas.prediagnostico["general-data_3_9"]) montaResumoFonte('fuel-data-9', 'GLP', 12.90, 0.2272);
    if (lstRespostas.prediagnostico["general-data_3_10"] === true) { montaResumoFonteLenha('fuel-data-10', 'Lenha (m³)-umidade normal(43%)', 2522.021197, 0.0068, 0.3685212) };
    if (lstRespostas.prediagnostico["general-data_3_11"] === true) { montaResumoFonteLenha('fuel-data-11', 'Lenha (m³)-baixa umidade(36%)', 2562.698958, 0.0068, 0.3685212) };
    if (lstRespostas.prediagnostico["general-data_3_12"] === true) { montaResumoFonteLenha('fuel-data-12', 'Lenha (t)-umidade normal(43%)', 3602.89, 0.0068, 0.3685212) };
    if (lstRespostas.prediagnostico["general-data_3_13"] === true) { montaResumoFonteLenha('fuel-data-13', 'Lenha (t)-baixa umidade(36%)', 3661.00, 0.0068, 0.3685212) };
    // if (lstRespostas.prediagnostico["general-data_3_10"]) montaResumoFonte('fuel-data-10', 'Lenha', 1405.13, 0.0068);
    if (lstRespostas.prediagnostico["general-data_3_14"]) montaResumoFonte('fuel-data-14', 'Óleo combustível', 11145.71, 0.2794);
    if (lstRespostas.prediagnostico["general-data_3_15"]) montaResumoFonte('fuel-data-15', 'Óleo diesel', 11.74, 0.2675);
    if (lstRespostas.prediagnostico["general-data_3_16"]) montaResumoFonte('fuel-data-16', 'Querosene', 12.09, 0.2596);
    if (lstRespostas.prediagnostico["general-data_3_17"]) montaResumoFonte('fuel-data-17', 'Sol', 1, 1);
  }, []);


  //método que monta a lista de fontes energéticas
  const addFonteResumo = (fonte, un_ano, mwh_ano, rs_ano, eeco2) => {
    // Crie o novo item
    const novoItem = { value: eeco2, label: fonte };
    // console.log("-----------------------------------------------------------------------------");
    // console.log("fonte:", fonte)
    // console.log("un_ano: ", un_ano)
    // console.log("mwh_ano: ", mwh_ano)
    // console.log("rs_ano: ", rs_ano)
    // console.log("eeco2: ", eeco2)
    // console.log("novoItem: ", novoItem);
    // console.log("-----------------------------------------------------------------------------");

    const fonteExiste = seriesData.some((item) => item.label === fonte);
    if (!fonteExiste) {
      addToTotal(eeco2);

      adicionarItem(novoItem);
    }
  };
  // Função para obter a lista de perguntas e respostas de um grupo específico
  const getQuestionsAndAnswers = (groupName) => {
    const questionsAndAnswers = [];
    lstQuestionario.steps.forEach((step) => {
      step.groups?.forEach((group) => {
        if (group.name === groupName) {
          group.questions?.forEach((question) => {
            questionsAndAnswers.push({
              questionName: question.name,
              questionDescription: question.description,
              questionAnswer: question.answer,
              questionValidation: question.validation.type
            });
          });
        }
      });
    });
    return questionsAndAnswers;
  };

  const montaResumoFonteLenha = ((grupo, titulo, FatorEmissao, FatorEmissaoCo2Cetificado, FatorEmissaoCo2SemCertificado) => {
    let index = 0;
    let un_ano = 0;
    let mwh_ano = 0;
    let rs_ano = 0;
    let combustivel = 0;
    const questionAndAnswers = getQuestionsAndAnswers(grupo);

    // console.log("lenha ", titulo);
    // console.log("Questions ", questionAndAnswers);

    questionAndAnswers.forEach((item) => {
      if (index === 1) {
        // console.log("resumo fontes energeticas: ", lstRespostas.prediagnostico[item.questionName]);
        // console.log(item.questionName);
        combustivel = lstRespostas.prediagnostico[item.questionName];// ? lstRespostas.prediagnostico[item.questionName] : 0
      }
      else
        if (index === 2) { un_ano = lstRespostas.prediagnostico[item.questionName] ? lstRespostas.prediagnostico[item.questionName] : 0; }
        else {
          if (index === 3) { mwh_ano = lstRespostas.prediagnostico[item.questionName] ? lstRespostas.prediagnostico[item.questionName] : 0; }
          else {
            if (index === 4) { rs_ano = lstRespostas.prediagnostico[item.questionName] ? lstRespostas.prediagnostico[item.questionName] : 0; }
          }
        };

      index += 1;
    });
    if (combustivel === "0") {

      // console.log("=================================================================================");
      // console.log("Titulo: ", titulo);
      // console.log("un ano: ", un_ano);
      // console.log("fator Emissão: ", FatorEmissao);
      // console.log(Math.ceil(un_ano * FatorEmissao / 1000));
      // console.log("mwh ano: ", mwh_ano);
      // console.log("vidas uteis: ", FatorEmissaoCo2SemCertificado)
      // console.log(((un_ano * FatorEmissao / 1000) * FatorEmissaoCo2SemCertificado));

      addFonteResumo(titulo, un_ano, Math.ceil(un_ano * FatorEmissao / 1000), mwh_ano, (FatorEmissaoCo2SemCertificado === 0 ? 0 : ((un_ano * FatorEmissao / 1000) * FatorEmissaoCo2SemCertificado)));
    }
    else {
      // console.log("Titulo: ", titulo);
      // console.log("un ano: ", un_ano);
      // console.log("fator Emissão: ", FatorEmissao);
      // console.log(Math.ceil(un_ano * FatorEmissao / 1000));
      // console.log("mwh ano: ", mwh_ano);
      // console.log("vidas uteis: ", FatorEmissaoCo2Cetificado)
      // console.log(((un_ano * FatorEmissao / 1000) * FatorEmissaoCo2Cetificado));

      addFonteResumo(titulo, un_ano, Math.ceil(un_ano * FatorEmissao / 1000), mwh_ano, (FatorEmissaoCo2Cetificado === 0 ? 0 : ((un_ano * FatorEmissao / 1000) * FatorEmissaoCo2Cetificado)));
    }

  });

  const montaResumoFonte = ((grupo, titulo, FatorEmissao, vidasUteis) => {
    let index = 0;
    let un_ano = 0;
    let mwh_ano = 0;
    let rs_ano = 0;
    const questionAndAnswers = getQuestionsAndAnswers(grupo);

    questionAndAnswers.forEach((item) => {
      if (index === 1) { un_ano = lstRespostas.prediagnostico[item.questionName] ? lstRespostas.prediagnostico[item.questionName] : 0; }
      else {
        if (index === 2) { mwh_ano = lstRespostas.prediagnostico[item.questionName] ? lstRespostas.prediagnostico[item.questionName] : 0; }
        else {
          if (index === 3) { rs_ano = lstRespostas.prediagnostico[item.questionName] ? lstRespostas.prediagnostico[item.questionName] : 0; }
        }
      };
      index += 1;
    });



    //addFonteResumo(titulo, un_ano, mwh_ano, rs_ano, FatorEmissao === 0? 0 : Math.ceil(un_ano * FatorEmissao / 1000));
    addFonteResumo(titulo, un_ano, mwh_ano, rs_ano, (vidasUteis === 0 ? 0 : ((un_ano * FatorEmissao / 1000) * vidasUteis)))

  });

  return (
    <>
      <Grid container spacing={0} className='grid-space-container'>
        <Box sx={{ width: '80%', marginTop: '20px' }}>
          <Typography variant="h4" gutterBottom>
            Percentual de emissões de cada fonte energética
          </Typography>
        </Box>
        <div style={{ width: '250px', heigth: '250px', margin: 'auto' }}>
          <Pie data={data} options={options} width={50} />
        </div>{/* Renderizando o gráfico de pizza */}
      </Grid>
      <div className='legend-container'>
        {data.labels.map((label, index) => (
          <div key={index} className='legend-item'>
            <span className='legend-color' style={{ backgroundColor: cores[index] }}></span>
            <span>{label} - {data.datasets[0].data[index].toFixed(1)} - {((data.datasets[0].data[index] / totais) * 100).toFixed(1)}%</span>
          </div>
        ))}
      </div>
    </>
  );
};

export default RelatorioResultadoChartEmissao;