import React, { useState } from 'react';
import MaterialTable from '@material-table/core';
import {
  Box, Paper, Fab
}from '@mui/material';
import api from "../../services/api";
import AddIcon from '@mui/icons-material/Add';
import { useStoreState, useStoreActions } from 'easy-peasy';
import { materialTableLocalization } from '../../config';
import {
  listaCategoriasTecnologia,
  regioes
} from '../../static';
import DialogoInstFinanceira from './DialogoInstFinanceira';

const InstFinanceiras = () => {
  let startLoading = useStoreActions((actions) => actions.nav.startLoading);
  let stopLoading = useStoreActions((actions) => actions.nav.stopLoading);

  const [columns, setColumns] = React.useState([]);
  const [rows, setRows] = React.useState([]);

  const loadInstFinanceiras = async (query) => {
    startLoading();
    try {
      setRows((await api.http.get('/inst-financeiras')).data);
      stopLoading();
    } catch (error) {
      stopLoading({
        message: error.toString(),
        severity: 'error'
      });
    };
  };

  React.useEffect(async () => {
    setColumns([{
      field: 'nome',
      align: 'left',
      title: 'Nome',
    },{
      field: 'responsavel',
      align: 'left',
      title: 'Responsável',
    },{
      field: 'email',
      align: 'left',
      title: 'Email',
    },{
      field: 'cnpj',
      align: 'left',
      title: 'CNPJ',
    },{
      field: 'habilitado',
      align: 'center',
      title: 'Habilitado',
      type:"boolean"
    }]);
    loadInstFinanceiras();
  },[]);

  const [openDlgInstFinanceira, setOpenDlgInstFinanceira] = React.useState(false);
  const [instFinanceira, setInstFinanceira] = React.useState(null);
  const onCloseDlgInstFinanceira = (reload=false) => {
    setInstFinanceira(null);
    setOpenDlgInstFinanceira(false);
    if(reload)
      loadInstFinanceiras();
  };

  const onRowClick = (event,rowData) => {
    setInstFinanceira(rowData);
    setOpenDlgInstFinanceira(true);
  };


  return (
    <Box sx={{ width: '100%' }}>
      <DialogoInstFinanceira
        open={openDlgInstFinanceira}
        onClose={onCloseDlgInstFinanceira}
        tecnologias={listaCategoriasTecnologia}
        regioes={regioes}
        instFinanceira={instFinanceira}
      />
      <Paper variant="outlined" className='center-box' sx={{ width: '100%', my: 3 }}>
        <MaterialTable
          columns={columns}
          data={rows}
          title="InstFinanceiras"
          onRowClick={onRowClick}
          localization={materialTableLocalization}
        />
        <Fab color="primary" aria-label="novo instFinanceira" onClick={()=>{
          setOpenDlgInstFinanceira(true);
        }}>
          <AddIcon />
        </Fab>
      </Paper>
    </Box>
  );
};

export default InstFinanceiras;
