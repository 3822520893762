import React, {useState} from 'react';
import SemiCircleProgressBar from "react-progressbar-semicircle";

const SemiCirculo = ({min,max,valor,unidade,children}) => {
  const percentColors = [
    { pct: 0.0, color: { r: 0xff, g: 0x26, b: 0 } },
    { pct: 0.5, color: { r: 0xff, g: 0xd4, b: 0x79 } },
    { pct: 1.0, color: { r: 0x62, g: 0xbb, b: 0x46 } }]; 

  const getColorForPercentage = (pct)=> {
    for (var i = 1; i < percentColors.length - 1; i++) {
      if (pct < percentColors[i].pct) {
        break;
      }
    }
    var lower = percentColors[i - 1];
    var upper = percentColors[i];
    var range = upper.pct - lower.pct;
    var rangePct = (pct - lower.pct) / range;
    var pctLower = 1 - rangePct;
    var pctUpper = rangePct;
    var color = {
      r: Math.floor(lower.color.r * pctLower + upper.color.r * pctUpper),
      g: Math.floor(lower.color.g * pctLower + upper.color.g * pctUpper),
      b: Math.floor(lower.color.b * pctLower + upper.color.b * pctUpper)
    };
    return 'rgb(' + [color.r, color.g, color.b].join(',') + ')';
    // or output as hex if preferred
  };

  const getValor = () => {
    return valor?valor:0;
  };

  return (
    <div className="semi-circle">
      <h4>{children}</h4>
      <SemiCircleProgressBar
        stroke={getColorForPercentage(getValor()/(max-min))}
        strokeWidth={30}
        percentage={getValor()*100/(max-min)} />
      <small className="graph-min">
        {(min).toLocaleString(undefined,{
          minimumFractionDigits: 2, maximumFractionDigits: 2
        })}
      </small>
      <small className="graph-max">
        {(max).toLocaleString(undefined,{
          minimumFractionDigits: 2, maximumFractionDigits: 2
        })}
      </small>

      <div>
        <h2>
          {(getValor()).toLocaleString(undefined,{
          minimumFractionDigits: 2, maximumFractionDigits: 2
        })}
        </h2>
        
        <p>{unidade}</p>
      </div>

    </div>
  );
};

export default SemiCirculo;
