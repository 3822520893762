import React, { useCallback } from "react";
import { DropzoneAreaBase } from "react-mui-dropzone";
import { FormHelperText, Link } from "@mui/material";
import {
  AttachFile,
  Audiotrack,
  Description,
  PictureAsPdf,
  Theaters,
} from "@mui/icons-material/";
import { DialogoVisualizador } from "./";
import { useStoreActions } from "easy-peasy";
import { driveBase } from "../config";
import api from "../services/api";

const FileListField = ({
  field,
  useFormRef,
  schema,
  initialFiles,
  disabled,
  ...props
}) => {
  const {
    register,
    watch,
    setValue,
    formState: { errors },
  } = useFormRef;
  let startLoading = useStoreActions((actions) => actions.nav.startLoading);
  let stopLoading = useStoreActions((actions) => actions.nav.stopLoading);
  const [file, setFile] = React.useState(null);
  const [openDlgVis, setOpenDlgVis] = React.useState(false);

  const fieldIds = field + "Ids";
  const listField = field + "Field";
  const registerField = register(listField);
  const registerFieldIds = register(fieldIds);

  const fileList = watch(listField, []);

  const uploadFile = async (fileObj) => {
    const formData = new FormData();
    formData.append("file", fileObj.file);
    startLoading();
    try {
      let uploaded = (
        await api.http.post("/upload", formData, {
          headers: { "Content-Type": "multipart/form-data" },
        })
      ).data;
      stopLoading();
      return {
        file: fileObj.file,
        data: uploaded,
      };
    } catch (error) {
      stopLoading({
        message: error.toString(),
        severity: "error",
      });
      return null;
    }
  };

  const handleAdd = async (newFileObjs) => {
    let uploads = [];
    for (let i = 0; i < newFileObjs.length; i++)
      uploads.push(uploadFile(newFileObjs[i]));
    uploads = await Promise.all(uploads);
    setListField(
      [...(fileList ? fileList : []), ...uploads.filter((upload) => upload)],
      {
        shouldValidate: true,
        shouldDirty: true,
        shouldTouch: true,
      }
    );
  };

  const handleDelete = (deletedFile) => {
    setListField(
      fileList.filter((file) => file != deletedFile),
      {
        shouldValidate: true,
        shouldDirty: true,
        shouldTouch: true,
      }
    );
  };

  const PreviewIcon = ({ fileObject, classes }) => {
    const { type } = fileObject.file;
    const iconProps = {
      className: classes.image,
    };

    if (type.startsWith("video/")) return <Theaters {...iconProps} />;
    if (type.startsWith("audio/")) return <Audiotrack {...iconProps} />;

    switch (type) {
      case "application/msword":
      case "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
        return <Description {...iconProps} />;
      case "application/pdf":
        return <PictureAsPdf {...iconProps} />;
      default:
        return <AttachFile {...iconProps} />;
    }
  };

  const handlePreviewIcon = (fileObject, classes) => {
    let fileData = fileObject.data;

    let onClick;
    if (
      fileData.mimeType == "application/pdf" ||
      fileData.mimeType == "text/html" ||
      fileData.mimeType.indexOf("image") == 0
    ) {
      onClick = () => onOpenFileClick(fileData);
    } else {
      onClick = () => download(fileData);
    }

    return (
      <Link onClick={onClick}>
        <PreviewIcon fileObject={fileObject} classes={classes} />
      </Link>
    );
  };

  const onOpenFileClick = (fileData) => {
    setFile(fileData);
    setOpenDlgVis(true);
  };

  const onCloseDlgVis = () => {
    setFile(null);
    setOpenDlgVis(false);
  };

  const download = (fileData) => {
    window.location.href = driveBase + fileData.fileRef;
  };

  const createFileFromUrl = async (url, fileData) => {
    const response = await fetch(url, { mode: "no-cors" });
    const data = await response.blob();
    const metadata = { type: data.type };
    const filename = fileData.fileName;
    return new File([data], filename, metadata);
  };

  const setListField = (newList, options) => {
    setValue(listField, newList, options);
    setValue(
      fieldIds,
      newList.map((fileObj) => fileObj.data.id),
      options
    );
  };

  React.useEffect(async () => {
    if (initialFiles) {
      let initialList = await Promise.all(
        initialFiles.arquivos.map(async (arquivoLista) => ({
          file: await createFileFromUrl(
            driveBase + arquivoLista.arquivo.fileRef,
            arquivoLista.arquivo
          ),
          data: arquivoLista.arquivo,
        }))
      );
      setListField(initialList, {
        shouldValidate: false,
        shouldDirty: false,
        shouldTouch: false,
      });
    }
  }, [initialFiles]);

  return (
    <React.Fragment>
      <DialogoVisualizador
        file={file}
        open={openDlgVis}
        onClose={onCloseDlgVis}
      />
      <div className="dropzone-component">
        <DropzoneAreaBase
          filesLimit={15}
          maxFileSize={52428800}
          fileObjects={fileList}
          dropzoneText={schema.fields[listField].spec.label}
          onAdd={handleAdd}
          onDelete={handleDelete}
          getPreviewIcon={handlePreviewIcon}
          showPreviews={true}
          showPreviewsInDropzone={false}
          showFileNamesInPreview={true}
          showAlerts={["error"]}
          previewText="Documentos"
          dropzoneProps={{ disabled: disabled }}
        />
        <FormHelperText error={errors[listField] != null}>
          {errors[listField]?.message}
        </FormHelperText>
      </div>
    </React.Fragment>
  );
};

export default FileListField;
