import React from "react";
import styles from "./TaxaConversaoWidget.module.css";

const TaxaConversaoWidget = ({ title, subtitle, value, isPercent }) => {
  return (
    <div className={styles.container}>
      <div className={styles.title}>{title}</div>
      <div className={styles.subtitle}>{subtitle}</div>
      <div className={styles.value}>
        {isPercent
          ? value.toLocaleString("pt-BR", {
              maximumFractionDigits: 2,
              minimumFractionDigits: 2,
            }) + "%"
          : Number.isInteger(value)
          ? value
          : value.toLocaleString("pt-BR", {
              maximumFractionDigits: 2,
              minimumFractionDigits: 2,
            })}
      </div>
    </div>
  );
};

export default TaxaConversaoWidget;
