import * as React from 'react';
import * as yup from "yup";
import { pt } from 'yup-locale-pt';
import {
  DialogoDados
} from '../../components';
import {
  categoriasTecnologia,
  listaCategoriasTecnologia,
  opcoesBooleanas
} from '../../static';

const createData = (ref,titulo,type,schema,extra=null) => {
  return {
    ref,
    titulo,
    type,
    schema,
    extra
  };
};

const rows = [
  createData('2.1','Número de inclusão do Crédito para EE','integer',null,{
    field: 'processoId',
    readOnly: true
  }),
  createData('2.2','Número do Crédito para EE','integer',
             yup.number().positive().integer().nullable()),
  createData('2.7','Valor do Empréstimo alocado (R$)','float',
             yup.number().positive().float().nullable()),
  createData('2.7.1','Valor do Empréstimo alocado (EUR)','float',
             yup.number().positive().float().nullable()),
  createData('5.3','Data de assinatura do Crédito para EE',
             'date',yup.date().nullable()),
  createData('5.4','Valor assinado','float',
             yup.number().positive().float().nullable()),
  createData('5.5','Taxa de juros','float',
             yup.number().positive().float().nullable()),
  createData('5.6','Prazo','integer',
             yup.number().positive().integer().nullable()),
  createData('5.7','Prazo de carência','integer',
             yup.number().positive().integer().nullable()),
  createData('5.8','Data final de repagamento','date',yup.date().nullable()),
  createData('5.9','Garantia remanescente (em valor)','float',
             yup.number().positive().float().nullable()),
  createData('5.10','Classificação interna do Beneficiário Final na '+
             'data de assinatura ','text', yup.string().nullable()),
  createData('5.11','Probabilidade de inadimplência','float',
             yup.number().positive().float().nullable()),
  createData('7.3','Número do Pedido de Garantia','integer',
             yup.number().positive().integer().nullable()),
  createData('7.4','Data do Pedido de Garantia','date',yup.date().nullable()),
  createData('7.5','Data da Validação de Garantia','date',
             yup.date().nullable()),
  createData('7.6','Data do Prejuízo ( da honra de aval)','date',
             yup.date().nullable()),
  createData('7.7','Prejuízo (Principal)  honra de aval ','float',
             yup.number().positive().float().nullable()),
  createData('7.8','Perda (Juros) ','float',
             yup.number().positive().float().nullable()),
  createData('7.9','Principal Recuperado antes de um Pedido de '+
             'Transferência de Garantia','float',
             yup.number().positive().float().nullable()),
  createData('7.10','Juros Recuperado antes de um Pedido de '+
             'Transferência de Garantia','float',
             yup.number().positive().float().nullable()),
  createData('7.11','Valor de Pagamento do Pedido de Transferência '+
             'de Garantia (Principal)','float',
             yup.number().positive().float().nullable()),
  createData('8.3','Número de Solicitação de Honra de Aval','integer',
             yup.number().positive().integer().nullable()),
  createData('8.4','Data da Solicitação de Honra de Aval','date',
             yup.date().nullable()),
  createData('8.5','Data de recuperação ','date',yup.date().nullable()),
  createData('8.6','Valor recuperado ','float',
             yup.number().positive().float().nullable()),
  createData('8.7','Taxa de recuperação na data do relatório','float',
             yup.number().positive().float().nullable()),
  createData('8.8','Valor recuperado transferido para o Fundo Garantidor',
             'float', yup.number().positive().float().nullable()),
  createData('8.9','Data da transferência','date',yup.date().nullable()),
];

const DadosFinanciamento =  ({
  dados, open, onClose, onSave, onCancel, disabled
}) => {
  return DialogoDados({
    title: 'Dados Iniciais do Financiamento',
    rows: rows,
    open: open,
    onClose: onClose,
    onSave: onSave,
    onCancel: onCancel,
    defaultValues: dados,
    disabled: disabled
  });
};

export default DadosFinanciamento;
