import React, { useState } from "react";
import ReactDOM from "react-dom";
import { Grid, Box, Paper, Tabs, Tab } from "@mui/material";
import PropTypes from "prop-types";
import PainelGeral from "./PainelGeral";
import IndicadoresTecnicos from "./IndicadoresTecnicos";
import GestaoSenai from "./GestaoSenai";
import IndicadoresFinanceiros from "./IndicadoresFinanceiros";
import Relatorio from "./Retatorio";
import Estagios from "./Estagios";

const TabPanel = (props) => {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`impl-tabpanel-${index}`}
      aria-labelledby={`impl-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
};

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

const Indicadores = () => {
  const [abaImpl, setAbaImpl] = React.useState(0);
  const handleTabChange = (event, newValue) => {
    setAbaImpl(newValue);
  };

  function a11yProps(index) {
    return {
      id: `impl-tab-${index}`,
      "aria-controls": `impl-tabpanel-${index}`,
    };
  }

  return (
    <Paper variant="outlined" className="center-box" sx={{ my: 3 }}>
      <Tabs
        value={abaImpl}
        onChange={handleTabChange}
        centered
        sx={{ borderBottom: 1, borderColor: "divider" }}
      >
        <Tab label="Painel Geral" {...a11yProps(0)} />
        <Tab label="Indicadores Técnicos" {...a11yProps(1)} />
        <Tab label="Indicadores Financeiros" {...a11yProps(2)} />
        <Tab label="Gestão SENAI" {...a11yProps(3)} />
        <Tab label="Relatório" {...a11yProps(4)} />
        <Tab label="Estágios" {...a11yProps(5)} />
      </Tabs>

      {/* Painel Geral */}
      <TabPanel value={abaImpl} index={0} className="tabpanel">
        <PainelGeral />
      </TabPanel>

      {/* Indicadores Técnicos */}
      <TabPanel value={abaImpl} index={1} className="tabpanel">
        <IndicadoresTecnicos />
      </TabPanel>

      {/*  Indicadores Financeiros */}
      <TabPanel value={abaImpl} index={2} className="tabpanel">
        <IndicadoresFinanceiros />
      </TabPanel>

      {/*Gestão SENAI*/}
      <TabPanel value={abaImpl} index={3} className="tabpanel">
        <GestaoSenai />
      </TabPanel>

      {/*Relatório*/}
      <TabPanel value={abaImpl} index={4} className="tabpanel">
        <Relatorio />
      </TabPanel>

      {/*Relatório*/}
      <TabPanel value={abaImpl} index={5} className="tabpanel">
        <Estagios />
      </TabPanel>
    </Paper>
  );
};

export default Indicadores;
