import TextField  from '@mui/material/TextField';

const TelField = ({field,useFormRef,schema,...props}) => {
  const {register, formState: {errors}} = useFormRef;
  const telMask = event => {
    let v = event.target.value
        .replace(/\D/g, '')
        .replace(/^(\d{2})(\d)/,'($1) $2')
        .replace(/(\d)(\d{4})$/,'$1-$2');
    event.target.value = v;
  };

  return (
    <TextField
      label={schema.fields[field].spec.label}
      required={schema.fields[field].exclusiveTests['required']}
      error={errors[field]!=null}
      helperText={errors[field]?.message}
      {...register(field,{
        onChange: telMask
      })}
      {...props} />
  );
};

export default TelField;
