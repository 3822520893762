import React,{ useState} from 'react';
import ReactDOM from 'react-dom';
import {
  Paper,
  Button,
  FormHelperText,
  Box,
  Typography,
  Container,
  autocompleteClasses
} from '@mui/material';
import {  CNPJField } from '../../components';
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";
import { pt } from 'yup-locale-pt';
import {
  useNavigate,
} from "react-router-dom";
import { useRecaptcha } from "react-hook-recaptcha";
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { useStoreActions } from 'easy-peasy';

yup.setLocale(pt);

const sitekey = "6LeK1MceAAAAACM096lU71zJeznaIVqLuzuwvDE0";
const containerId = "recaptcha_id";


const Interesse = () => {
  const setCnpjValidado = useStoreActions((actions) => actions.interesse.setCnpjValidado);
  const navigate = useNavigate();
  let recaptchaInstance;

  const [captchaResponse, setCaptchaResponse] = useState(null);
  const [captchaErrorMsg, setCaptchaErrorMsg] = useState(null);

  const onSubmit = (data) => {
    if(captchaResponse==null) {
      setCaptchaErrorMsg('Captcha inválido');
    } else {
      setCnpjValidado(data.cnpj);
      navigate('/novo_processo');
    }
  };

  const schema = yup.object({
    cnpj: yup.string().label('CNPJ').cnpj().required()
  }).required();

  const useFormRef = useForm({
    mode: 'onTouched',
    resolver: yupResolver(schema)
  });

  const { handleSubmit } = useFormRef;

  const successCallback = (response) => {
    setCaptchaResponse(response);
    setCaptchaErrorMsg(null);
  };

  const expiredCallback = () => {
    setCaptchaResponse(null);
  };

  useRecaptcha({
    containerId,
    successCallback,
    expiredCallback,
    sitekey,
    size: "normal",
  });

  return (
    <Container sx={{mt:3}}>
      <Typography color="inherit" variant="h5">
        Preencha o formulário abaixo e participe!
      </Typography>

      <Paper variant="outlined" className='center-box' sx={{
        px: 4,
        pt: 2,
        pb: 3,
        mb: 7
      }}>
        <p>Qual o CNPJ da sua empresa?</p>
        <form onSubmit={handleSubmit(onSubmit)}>
          <CNPJField field="cnpj" fullWidth={true}
                     useFormRef={useFormRef} schema={schema} />
        </form>
        <Box sx={{my:3}}>
          <div id={containerId} />
          {captchaErrorMsg && <FormHelperText error={true}>{captchaErrorMsg}</FormHelperText>}
        </Box>
        <Button variant="contained" size="large" endIcon={<ArrowForwardIcon />} onClick={handleSubmit(onSubmit)} >PRÓXIMO</Button>
        <br/>
        <br/>
        <p>
          O PotencializEE – Programa Investimentos Transformadores de Eficiência Energética na Indústria – tem o intuito de promover Eficiência Energética em PMEs (Pequenas e Médias Empresas) industriais. A implementação tem foco na região de São Paulo, considerando a maior concentração de indústrias e os altos índices de consumo de energia, e contará com estratégias a fim de alavancar os potenciais de Eficiência Energética na Indústria em nível nacional.
        </p>
        <p>
          A iniciativa é um Programa de Cooperação Brasil-Alemanha para o Desenvolvimento Sustentável, liderado pelo MME (Ministério de Minas e Energia) e coordenado por meio da GIZ (Deutsche Gesellschaft für Internationale Zusammenarbeit), Agência Alemã de Cooperação Internacional.
        </p>
      </Paper>
    </Container>
  );
};

export default Interesse;
