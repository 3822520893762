import React,{useRef} from 'react';
import { styled } from '@mui/material/styles';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import LaunchIcon from '@mui/icons-material/Launch';
import ClearIcon from '@mui/icons-material/Clear';
import {
  IconButton,
  Button,
  Link,
  FormControl,
  FormHelperText,
} from '@mui/material';
import api from "../services/api";
import { useStoreActions } from 'easy-peasy';
import { driveBase } from '../config';
import {DialogoVisualizador} from './';

const FileInput = styled('input')({
  display: 'none'
});

const FileField = ({
  field,useFormRef,schema,disabled,fileData,
  uploadUrl='/upload',
  ...props
}) => {
  const {register, setValue, formState: {errors}} = useFormRef;
  let startLoading = useStoreActions((actions) => actions.nav.startLoading);
  let stopLoading = useStoreActions((actions) => actions.nav.stopLoading);
  const [file, setFile] = React.useState(null);
  const [openDlgVis, setOpenDlgVis] = React.useState(false);

  const onOpenFileClick = () => {
    setOpenDlgVis(true);
  };

  const onCloseDlgVis = () => {
    setOpenDlgVis(false);
  };

  const clear = () => {
    setFile(null);
    setValue(field,null,{
      shouldValidate: true,
      shouldDirty: true,
      shouldTouch: true
    });
  };

  const onChange = async (event) => {
    if(event.target.files.length>0) {
      const formData = new FormData();
      formData.append("file", event.target.files[0]);
      startLoading();
      try {
        let uploaded = (await api.http.post(uploadUrl,formData,{
          headers: { "Content-Type": "multipart/form-data" },
        })).data;
        setFile(uploaded);
        setValue(field,uploaded.id,{
          shouldValidate: true,
          shouldDirty: true,
          shouldTouch: true
        });
        stopLoading();
      } catch(error) {
        stopLoading({
          message: error.toString(),
          severity: 'error'
        });
      }
    }
  };

  const download = () => {
    window.location.href = driveBase+file.fileRef;
  };

  React.useEffect(async () => {
    if(fileData){
      setFile(fileData);
    }
  },[fileData]);

  const icons = (fileName) => {
    if (file.mimeType=='application/pdf' ||
        file.mimeType=='text/html' ||
        file.mimeType.indexOf('image')==0) {
      return (
        <Link onClick={onOpenFileClick}>
        <IconButton color="primary">
          <LaunchIcon />
        </IconButton>
          {fileName}
        </Link>
      );
    } else {
      return (
        <Link onClick={download}>
        <IconButton color="primary">
          <CloudDownloadIcon />
        </IconButton>
          {fileName}
        </Link>
      );
    }
  };

  return (
    <FormControl>
      <DialogoVisualizador file={file} open={openDlgVis} onClose={onCloseDlgVis}/>
      <label htmlFor={field}>
        <FileInput
          id={field} type="file" onChange={onChange}
          disabled={disabled}
        />
        <Button color="primary" variant="outlined" aria-label="upload picture"
                component="span" disabled={disabled}
                endIcon={<CloudUploadIcon />}>
          {schema.fields[field].spec.label}
        </Button>
      </label>
      <input type="hidden" {...register(field)}/>
      {file &&
       (
         <div>
           {icons(file.fileName)}

           <IconButton onClick={clear} disabled={disabled} >
             <ClearIcon />
           </IconButton>
         </div>
       )
      }
      <FormHelperText error={errors[field]!=null}>
        {errors[field]?.message}
      </FormHelperText>
    </FormControl>
  );
};

export default FileField;
