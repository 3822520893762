import { action } from 'easy-peasy';


const defaultMessages = {
  success: 'Sucesso',
  error: 'Erro'
};

const navStore = {
  loading: 0,
  message: '',
  severity: 'info',
  openMessage: false,
  autoHideDuration: 2000,
  startLoading: action((state,_) => {
    state.loading += 1;
  }),
  stopLoading: action((state,payload) => {
    if(state.loading>0)
      state.loading -= 1;
    if(payload && 'severity' in payload) {
      state.severity = payload.severity;
      if('message' in payload) {
        state.message = payload.message;
        state.openMessage = true;
      }
      else if(payload.severity in defaultMessages)
      {
        state.message = defaultMessages[payload.severity];
        state.openMessage = true;
      }
      if(state.openMessage) {
        if('autoHideDuration' in payload) {
          state.autoHideDuration = payload.autoHideDuration;
        } else {
          state.autoHideDuration = 2000;
        }
      }
    }
  }),
  closeMessage: action((state,_) => {
    state.openMessage = false;
  }),
};

export default navStore;
