import React, { useEffect, useState, useRef } from "react";
import { useStoreActions, useStoreState } from "easy-peasy";
import { styled } from "@mui/material/styles";
import { Grid, FormControl, Divider, Box, Typography } from "@mui/material";
import api from "../services/api";

import RelatorioResultadoDadosGerais from "./StepPreDiagnosticoRelatorios/RelatorioResultadoDadosGerais";
import RelatorioResultadoEnergiaEletrica from "./StepPreDiagnosticoRelatorios/RelatorioResultadoEnergiaEletrica";
import RelatorioResultadoCombustiveis from "./StepPreDiagnosticoRelatorios/RelatorioResultadoCombustiveis";
import RelatorioResultadoResumoFontesEnergeticas from "./StepPreDiagnosticoRelatorios/RelatorioResultadoResumoFontesEnergeticas";
import RelatorioResultadoChartConsumo from "./StepPreDiagnosticoRelatorios/RelatorioResultadoChartConsumo";
import RelatorioResultadoChartEmissao from "./StepPreDiagnosticoRelatorios/RelatorioResultadoChartEmissao";
import RelatorioResultadoChartCustoAnual from "./StepPreDiagnosticoRelatorios/RelatorioResultadoChartCustoAnual";
import RelatorioResultadoEspecificosSegmento from "./StepPreDiagnosticoRelatorios/RelatorioResultadoEspecificosSegmento";
import RelatorioResultadoPreDiagnostico from "./StepPreDiagnosticoRelatorios/RelatorioResultadoPreDiagnostico";
import RelatorioResutladoPreDiagnosticoNaoCapex from "./StepPreDiagnosticoRelatorios/RelatorioResultadoPreDiagnosticoNaoCapex";
import RelatorioResultadoOutrasAcoes from "./StepPreDiagnosticoRelatorios/RelatorioResultadoOutrasAcoes";
import RelatorioResultadoFotos from "./StepPreDiagnosticoRelatorios/RelatorioResultadoFotos";

import "./StepPreDiagnosticoRelatorios/ModalRelResumo.css";
import { CenterFocusStrong } from "@mui/icons-material";

export default function StepPreDiagnosticoRelResultado({
  currProcesso,
  jsonData,
  jsonBanco,
}) {
  // Referência para a div que será convertida em PDF
  const relatorioRef = useRef();

  const [cmpcapaSegmento, setCmpcapaSegmento] = useState();
  const [listaTecnicos, setListaTecnicos] = React.useState(null);
  const [listaScrumMaster, setListaScrumMaster] = React.useState(null);
  const [dadosReport, setDadosReport] = React.useState();
  const [auditor, setAuditor] = useState(null);
  useEffect(async () => {
    const getPreDiagnosticos = async () => {
      try {
        setAuditor(
          (await api.http.get(`/visitas_tecnicas/${jsonBanco.processoId}`)).data
        );
        setDadosReport(
          (
            await api.http.get(
              "/prediagnosticos/report/" + jsonBanco.processoId + "/finished"
            )
          ).data
        );
        //   //recuperar o nome do Especialista Lider
        setListaScrumMaster(
          (
            await api.http.get("/users", {
              params: {
                roleId: "scrumMaster",
                habilitado: true,
                ordenado_alfa: true,
              },
            })
          ).data
        );
        //   //recuperar o nome do auditor
        setListaTecnicos(
          (
            await api.http.get("/users", {
              params: {
                roleId: "consultorEE",
                habilitado: true,
                ordenado_alfa: true,
              },
            })
          ).data
        );

      } catch (error) {
        console.error(error);
      }
    };
    await getPreDiagnosticos();
  }, []);

  useEffect(() => {
    if (listaTecnicos) {
      preencheDados();
    }
  }, [listaTecnicos]); // Observa mudanças em jsonBanco para executar a lógica adicional

  const preencheDados = () => {
    setCmpcapaSegmento(
      getValueFromOptions(
        "general-data_1_3",
        jsonBanco.prediagnostico["general-data_1_3"]
      )
    );
  };

  // //método para retornar o valor para o relatorio de um select
  const getValueFromOptions = (questionName, answerValue) => {
    let valueFromOptions = null;
    jsonData.steps.forEach((step) => {
      if (step.groups) {
        step.groups?.forEach((group) => {
          if (group.questions) {
            group.questions.forEach((question) => {
              if (question.name === questionName) {
                if (question.validation.options) {
                  question.validation.options.forEach((option) => {
                    if (parseInt(option.key) === parseInt(answerValue)) {
                      valueFromOptions = option.value;
                    }
                  });
                }
              }
            });
          }
        });
      }
    });
    return valueFromOptions;
  };

  // //método para retornar o nome do tecnico responsável
  const getScrumMasterNomeById = (id) => {
    const scrummaster = listaScrumMaster.find((t) => t.id === id);
    return scrummaster ? scrummaster.nome : "Especialista Lider não encontrado";
  };

  // //método para retornar o nome do tecnico
  const getTecnicoNomeById = (id) => {
    const tecnico = listaTecnicos.find((t) => t.id === id);
    return tecnico ? tecnico.nome : "Técnico não encontrado";
  };

  const formatDateToBrazilian = (dateString) => {
    const date = new Date(dateString);
    return new Intl.DateTimeFormat("pt-BR", {
      day: "2-digit",
      month: "2-digit",
      year: "numeric",
    }).format(date);
  };



  //==========================================================
  //inicia o visual do relatorio
  return (
    <FormControl
      id="formRelatorioResumo"
      sx={{
        width: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      {listaTecnicos ? (
        <>
          <Grid container spacing={0} className="grid-container">
            <Grid item xs={4} sx={{ textAlign: 'left' }}>
              <Box
                component="img"
                src="/logo-potencializee.png"
                sx={{ maxWidth: '100%' }}
              />
            </Grid>
            <Grid item xs={4}></Grid>
            <Grid item xs={4} sx={{ textAlign: 'right' }}>
              <Box
                component="img"
                src="/logo-senai.png"
                sx={{ maxWidth: '100%', width: '430px', marginTop: '20px' }}
              />
            </Grid>
          </Grid>

          <Box sx={{
            width: '100%', marginTop: '100px', color: "#62BB46"
          }} className="title-section-center">
            <Typography variant="h4" gutterBottom sx={{ color: "#62BB46" }}>
              Relatório do Pré-diagnóstico Energético - {currProcesso.razaoSocial}
            </Typography>
          </Box>
          <Box sx={{ color: "#62BB46", marginTop: "35%" }}>
            <Typography variant="h2" gutterBottom>
              Relatório do Pré-diagnóstico Energético
            </Typography>
          </Box>
          <Box sx={{ marginTop: "5rem" }} className="title-section-center">
            <Typography variant="h4" gutterBottom>
              {currProcesso.razaoSocial}
            </Typography>
          </Box>
          {/* <Box
              component="img"
              src="/logo-potencializee.png"
              sx={{marginTop : '40%', display: 'block' }}
            /> */}
          <Grid
            container
            spacing={0}
            sx={{ pageBreakBefore: 'always' }}
            className="grid-container print-grid-container"
          >
            <Grid item xs={4} className="item-response">
              <h4>ESPECIALISTA RESPONSÁVEL: </h4>
            </Grid>
            <Grid item xs={8} className="item-response">
              <h4>{getTecnicoNomeById(auditor?.tecnicoId)}</h4>
            </Grid>
            <Grid item xs={4} className="item-response">
              <h4>SCRUM-MASTER: </h4>
            </Grid>
            <Grid item xs={8} className="item-response">
              <h4>{getScrumMasterNomeById(auditor?.scrumMasterId)}</h4>
            </Grid>
            <Grid item xs={4} className="item-response">
              <h4>DATA: </h4>
            </Grid>
            <Grid item xs={8} className="item-response">
              <h4>{formatDateToBrazilian(dadosReport.reportGenerate)}</h4>
            </Grid>
          </Grid>
          <RelatorioResultadoDadosGerais
            lstQuestionario={jsonData}
            lstRespostas={jsonBanco}
            cmpcapaSegmento={cmpcapaSegmento}
          />
          <RelatorioResultadoEnergiaEletrica
            lstQuestionario={jsonData}
            lstRespostas={jsonBanco}
          />
          <RelatorioResultadoCombustiveis
            lstQuestionario={jsonData}
            lstRespostas={jsonBanco}
          />
          <RelatorioResultadoResumoFontesEnergeticas
            lstQuestionario={jsonData}
            lstRespostas={jsonBanco}
          />
          <RelatorioResultadoChartConsumo
            lstQuestionario={jsonData}
            lstRespostas={jsonBanco}
          />
          <RelatorioResultadoChartCustoAnual
            lstQuestionario={jsonData}
            lstRespostas={jsonBanco}
          />
          <RelatorioResultadoChartEmissao
            lstQuestionario={jsonData}
            lstRespostas={jsonBanco}
          />
          <RelatorioResultadoEspecificosSegmento
            lstQuestionario={jsonData}
            lstRespostas={jsonBanco}
          />
          <RelatorioResultadoPreDiagnostico
            lstQuestionario={jsonData}
            lstRespostas={jsonBanco}
            cmpcapaSegmento={cmpcapaSegmento}
          />
          <RelatorioResutladoPreDiagnosticoNaoCapex
            lstQuestionario={jsonData}
            lstRespostas={jsonBanco}
            cmpcapaSegmento={cmpcapaSegmento}
          />
          <RelatorioResultadoOutrasAcoes
            lstQuestionario={jsonData}
            lstRespostas={jsonBanco}
            cmpcapaSegmento={cmpcapaSegmento}
          />
          <RelatorioResultadoFotos
            lstQuestionario={jsonData}
            lstRespostas={jsonBanco}
            cmpcapaSegmento={cmpcapaSegmento}
            dataEmissao={formatDateToBrazilian(dadosReport.reportGenerate)}
            auditor={getTecnicoNomeById(auditor?.tecnicoId)}
          />
        </>
      ) : (
        <p>...Carregando</p>
      )}
    </FormControl>
  );
}
