import React from "react";
import styles from "./FunilWidget.module.css";
import InfoTooltip from "./InfoTooltip";

const FunilWidget = ({
  title,
  abosluteValue,
  variationValue,
  color,
  width,
  hideTriangle = false,
  tooltipText,
}) => {
  return (
    <div className={styles.container}>
      <div
        className={styles.subContainer}
        style={{ backgroundColor: color ?? "#000", width: width ?? "80%" }}
      >
        <div className={styles.title}>{title}</div>
        <div className={styles.subtitle}>{abosluteValue}</div>
        <div
          className={
            styles.value + " " + (variationValue < 100.0 ? styles.below : "")
          }
        >
          {"( " +
            variationValue.toLocaleString("pt-BR", {
              maximumFractionDigits: 2,
              minimumFractionDigits: 2,
            }) +
            "% )"}
        </div>
        {tooltipText && (
        <InfoTooltip text={tooltipText}></InfoTooltip>
      )}
      </div>
      {!hideTriangle && (
        <div
          className={styles.triangle}
          style={{ backgroundColor: color ?? "#000" }}
        ></div>
      )}
        {!hideTriangle && (
        <div
          className={styles.triangle}
          style={{ backgroundColor: color ?? "#000" }}
        ></div>
      )}
    </div>
  );
};

export default FunilWidget;
